import React from "react";
import Props from "prop-types";
import Close from "./close.png";


class Modal extends React.Component {


	render() {
		return (
			<div className="modal fade show" style={this.props.show ? {display: "block", background: 'rgba(0,0,0,.4)'} : {}}>
				<div className="modal-dialog" role="document" >
					<div className="modal-content">
						<div>
							<h5 className="p-portlet-title" id="exampleModalLabel">
								{this.props.title}
							</h5>
							{
								this.props.des ?
								<p className="p-portlet-des">
									{
										this.props.des
									}
								</p> : null
							}
							<img alt="close" style={{width: 16, height: 16, marginTop: 10, cursor: 'pointer'}}
								 src={Close} className="modal-close" onClick={this.props.close}/>
						</div>
						<div className="modal-body">
							{this.props.children}
						</div>
						<div className="modal-footer">
							{!this.props.hideButton ? <div style={this.props.style.save}
														   onClick={this.props.stateLoading ? null : this.props.onSave}
														   className="save">
								{this.props.buttonName}
							</div> : null}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

Modal.propTypes = {
	onSave: Props.func.isRequired,
	title: Props.string.isRequired,
	buttonName: Props.string,
	show: Props.bool,
	close: Props.func.isRequired,
	style: Props.object,
	hideButton: Props.bool,
}


Modal.defaultProps = {
	buttonName: "Save Changes",
	hideButton: false,
	style: {
		close: {},
		save: {}
	}
}

export {
	Modal
};
