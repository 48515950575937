import React from "react";
import { Footer } from "../../Common/Footer";
import { Helmet } from "react-helmet";
import Model from "./Model";
import Blog from "./Blogs";
import BlogView from "./Blogs/Article/ArticleView";
import { Route, Switch } from "react-router-dom";
import './Blogs/Article/ArticleView/style.css'


import "./style.css";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
    this.model = new Model();
  }

  componentWillUnmount() {
    this.model.destroy();
  }

  componentDidMount() {}

  render() {
    return (
      <div id="product-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Travel Expense Management Solutions - Antrepriz</title>
          <link rel="canonical" href="https://www.antrepriz.com/product" />
          <meta
            name="keywords"
            content="expense management software,expense management solutions,travel expense management,travel and expense management software,travel and expense management,travel and expense software,travel expense management software,travel & expense management,travel and expense management system,travel and expense management solution,travel & expense software,travel and expense management solutions,travel expense report software,travel expense management system,travel expense management solutions,travel expenses software,travel expense card,travel management system,employee travel management software,travel expense management solution,travel management solution"
          />
          <meta
            name="description"
            content="Business travel and expense management software,Travel and Expense management solutions, employee and expense tracking, dynamic and powerful policy engine, fully cutomizable expense reports, employee self flight, Hotel, Bus and cab booking tool, Admin travel booking tool, end to end work flow management, faster approvals, cheaper flights, quicker bookings, 100% policy compliance business travel, on-demand travel insurance, international travel insurance, 24 hour reimbursement cycle, employee flexi benefits, automated payments, direct account transfer, advance cash settlement, account payable automation, vendor payments. Best business travel, expense and payment solution world. Ensure employee experience with automation, visibility, control and cost saving. Save upto 40% on corporate travel."
          />
        </Helmet>
        <div>
          <Switch>
            <Route component={BlogView} path="/blog/page/:id/:name" exact />
            <Route component={Blog} path="/blog" />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
