import {Images} from '../Images/Image';


const aboutData = [
    {
        cname:'value5',
        val:'Customer Centric',
        hid:'We put your problems first'
    },
    {
        cname:'value1',
        val:'Leaders own',
        hid:'by solving them just as our own'
    },
    {
        cname:'value2',
        val:'Bias for action',
        hid:'as quickly & with a ton of faith'
    },
    {
        cname:'value3',
        val:'Integrity',
        hid:'by always stand for what\'s right'
    },

];

const employeeData = [
    {
        img:Images.prashant,
        name:'Prashant Kushwah',
        linkedin:'https://www.linkedin.com/in/prashantskushwah/',
        desig:'CEO',
        college:'',
    },
    {
        img:Images.sonam,
        name:'Sonam Khubchandani',
        linkedin:'https://www.linkedin.com/in/sonamkhubchandani/',
        desig:'VP Sales',
        college:''
    },
    {
        img:Images.navin,
        name:'Navin Joshi',
        linkedin:'https://www.linkedin.com/in/navin-joshi-baa1183b/',
        desig:'Finance Head',
        college:''
    },
    {
        img:Images.ankur,
        name:'Ankur Mathur',
        linkedin:'https://www.linkedin.com/in/ankur-mathur-506213134/',
        desig:'CE',
        college:''
    },
    {
        img:Images.karan,
        name:'Karan Kumar',
        linkedin:'https://www.linkedin.com/in/karan-kumar-0bbaa0102/',
        desig:'Tech Lead',
        college:''
    },
    {
        img:Images.neeraj,
        name:'Neeraj Rai',
        linkedin:'https://www.linkedin.com/in/neeraj-rai-66b37443/',
        desig:'Support Lead',
        college:''
    },
    {
        img:Images.vibhuti,
        name:'Vibhuti Narayan',
        linkedin:'https://www.linkedin.com/in/vibhuti-narayan-03ba768b/',
        desig:'Tech Lead',
        college:''
    },
    {
        img:Images.sneha,
        name:"Sneha Singh",
        linkedin:"https://www.linkedin.com/in/sneha-singh-b30335140/",
        desig:"Software Tester",
        college:''
    },
    {
         img:Images.uttam,
         name:"Uttam Jaiswal",
         linkedin:"https://www.linkedin.com/in/uttam-jaiswal-9ab30195/",
         desig:"Support Executive",
         college:''
    },
]

export { aboutData, employeeData };
