import {get} from '../../../../../Model/Network/Config/Axios';


class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}
	getBlogs =(res,cb)=>{
		get(`blogs?limit=${res.limit}&page=${res.page}`,cb)
	}
	getCategories = (cb)=>{
		get(`categories`,cb)
	}

	getBlogsWithCategory=(res,cb)=>{
		get(`blogs?limit=${res.limit}&page=${res.page}&category=${res.category}`,cb)

	}


}

export default Model
