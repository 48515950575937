import ola from './ola.webp';
import {Images} from '../Images/Image';


const integrationFactor = [
    {
        imgSrc:Images.uber,
        name:'Uber',
        desc:'Uber MIS plugin provides you live tracking of your employees expense and ease of filling.'
    },
    {
        imgSrc:Images.aws,
        name:'Amazon web services',
        desc:'Sync your financial data with your own cloud architecture'
    },
    {
        imgSrc:Images.stripe,
        name:'Stripe',
        desc:'Integrate your payment service with eka with the help of stripe'
    },
    {
        imgSrc:ola,
        name:'Ola',
        desc:'OLA corporate plugin provides you live tracking of your employees expense and ease of filling'
    },
    {
        imgSrc:Images.excel,
        name:'Excel',
        desc:'CRUD(Create,update,delete,read) all operations by just uploading a formatted csv to our system'
    },
    {
        imgSrc:Images.dos,
        name:'Do',
        desc:'DO voucher spaces provides you real time upload of your company vouchers to your own digital ocean space'
    },
    {
        imgSrc:Images.zapier,
        name:'Zapier',
        desc:'Multiple data points  with your favourite service using zapier services'
    }
]

export default integrationFactor;
