import React from 'react'

class Confirmation extends React.Component {

	render() {
		return (
			<div style={{marginTop:30,display:'flex',flexDirection:'row'}}>
				<span style={{
					color:"white",
					fontSize:14,
				}}>{this.props.user?`~ ${this.props.user}$ `:"~ root# "}</span>
				 <div style={{flex:1,paddingLeft:8}}>
					 <p style={{color:'white',fontSize:14}}>You agree that information provided by you is correct.You will be contacted by one of our sales representative soon.</p>
					 <p style={{color:'white',fontSize:14,marginTop:15}}>[Y]es, I agree &nbsp;&nbsp;[N]o, thanks</p>
					 <input  onKeyDown={e=>{
						 if (e.key === 'Enter') {
							 let name = this._input.value;
							 if(name){
								 this.props.item.action(name)
							 }
						 }
					 }} ref={ref=>this._input=ref}  autoFocus={true} className="blink" type={"text"} style={{
						 background:'transparent',
						 border:'none',
						 color:'white',
						 fontSize:14,
						 marginTop:8,
						 fontWeight:400,
						 paddingRight:5,
						 display:'inlineBlock'
					 }}/>
				 </div>
			</div>
		)
	}

}

export default Confirmation
