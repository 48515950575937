import React from 'react';
import './index.css';
import {EkaLogo} from '../../../../Images/Common';
import {Link} from 'react-router-dom';
import menu from './menu.png';
import cross from './multiply.png';

 class Header extends React.Component {

	state = {
		shown :false,
		modal:false
	}

	componentDidMount(){
		window.addEventListener('scroll', e=>{
			if(window.pageYOffset>100){
				this.setState({
					shown:true
				})
			}else{
				this.setState({
					shown:false
				})
			}
		});
	}



	render() {
		return (
			<div>
			{/* Mobile header view start */}
		  <div id = "navigation">
			<div id = "header-mobile" className = {this.state.shown?"header-mobile fixed":"header-mobile"}>
				<div style = {{width:'100%'}} className = "space-between flex-v-center">
					<Link to = "/" style = {{marginLeft:0}}>
						<img src = {EkaLogo} alt = "" style = {{height:40,width:150}} />
					</Link>
					<img onClick = {()=>{
						this.setState({
							...this.state,
							modal:true
						})
					}} src = {menu} alt = "" style = {{height:25,width:25}} />
				</div>

			</div>
			<div className = {this.state.modal? "show-modal" : "hide-modal"}>
				<div className = "horizontal" style = {{justifyContent:'flex-end'}}>
					<img onClick = {()=>{
						this.setState({
							...this.state,
							modal:false
						})
					}} src = {cross} alt = "" style = {{height:20,width:20}} />
				</div>
			  <div>
				<Link style = {{fontWeight:600,textTransform:'uppercase',color:"#454545"}} to = "/product">Product</Link>
				<Link style = {{fontWeight:600,textTransform:'uppercase',marginBottom:10,color:"#454545"}} to = "/integration">Integrations</Link>
				<Link style = {{fontWeight:600,textTransform:'uppercase',marginBottom:10,color:"#454545"}} to = "/blog">Blogs</Link>
				<Link to = "/demo" className="btn" style={{
					borderRadius:4,
					padding:'9px 10px',
					width:160,
					textAlign:'center'
				}}>Schedule Demo</Link>
				</div>

			</div>
			</div>

			{/*Mobile header view End */}

			<div id = "header" className={this.state.shown?"header fixed":"header"}>
				<div>
					<Link to = "/" style = {{marginLeft:0}}>
						<img src={EkaLogo} alt="eka logo" style={{width: 130, height: 35}}/>
					</Link>
				</div>
				<div className="header-text">
					<Link to = "/team" style = {{fontWeight:600,color:"#454545",fontSize:15,marginRight:12}}>Team</Link>
					<Link to = "/product" style = {{fontWeight:600,color:"#454545",fontSize:15,marginRight:12}}>Product</Link>
					<Link to = "/integration" style = {{fontWeight:600,color:"#454545",fontSize:15,marginRight:12}}>Integrations</Link>
					<Link to = "/blog" style = {{fontWeight:600,color:"#454545",fontSize:15,marginRight:12}}>Blog</Link>
					{
						this.props.onDemo?
							<p onClick={this.props.onDemo} className="btn" style={{
								borderRadius:4,
								padding:'9px 18px',

							}}>Schedule Demo</p>:
							<Link to = "/demo"  className="btn" style={{
								borderRadius:4,
								padding:'9px 18px',

							}}>Schedule Demo</Link>
					}
				</div>
			</div>

			</div>
		);
	}
}

export {Header}
