import React from 'react'
import Props from 'prop-types';

class System extends React.Component {

	render() {
		return (
			<div>
				<div className="typewriter">
					<p style={{
						color:'white',
						...this.props.css,
						letterSpacing:.1
					}}>{this.props.text}</p>
				</div>
			</div>
		)
	}

}

System.propTypes={
	text:Props.string.isRequired
}

export default System
