import React from 'react'
import Attachments from "../Attachments";



class Invoice extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			changeVendor: false
		}
	}

	renderMiddleArea = () => {
		let { data } = this.props;
		let { taxInformation, invoice } = data;
		let view = (<div></div>)
		if (data.type === "invoicing") {
			return (
				<div style={{}}>
					<div style={{
						padding: '8px 15px',
						background: "#f4f4f4",
						display: 'flex'
					}}>
						<p style={{
							fontSize: 13,
							fontWeight: 600,
							color: "var(--light-color)",
							flex: 1
						}}>
							Description
						</p>
						<p style={{
							fontSize: 13,
							fontWeight: 600,
							color: "var(--light-color)",
							flex: 1
						}}>
							Base Amount
						</p>
						<p style={{
							fontWeight: 600,
							fontSize: 13,
							color: "var(--light-color)",
							flex: 1
						}}>
							Tax
						</p>
						<p style={{
							fontWeight: 600,
							fontSize: 13,
							color: "var(--light-color)",
							flex: 1
						}}>
							Amount
						</p>
					</div>
					<div style={{
						padding: '12px 15px',
						display: 'flex'
					}}>
						<p style={{
							fontSize: 15,
							flex: 1
						}}>
							{invoice.service}
						</p>
						<p style={{
							fontSize: 15,
							flex: 1
						}}>
							USD {taxInformation.base}
						</p>
						<p style={{
							fontSize: 15,
							flex: 1
						}}>
							USD {taxInformation.tax}
						</p>
						<p style={{
							fontSize: 15,
							flex: 1
						}}>
							USD {taxInformation.amount}
						</p>
					</div>
				</div>
			)
		}
		return (
			<div style={{ padding: 25 }}>
				<p style={{
					fontSize: 11,
					color: "#fff",
					background: "var(--text-color)",
					padding: '6px 15px',
					marginRight: 100,
					marginBottom: 12
				}}>
					Description:-
				</p>
				{view}
			</div>
		)
	}

	render() {
		let { data } = this.props;
		let { vendor, invoice, address, deliverTo, taxInformation } = data;
		return (
			<div style={{
				paddingTop: 25
			}}>
				<div style={{
					border: '1px solid #dedede',

				}}>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						padding: 25,
						background: "#f4f4f4"
					}}>
						<div style={{
							flex: 1
						}}>
							<p style={{ color: "#333", fontSize: 17, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
								<p>
									{vendor.name}
								</p>
								<div onClick={(e) => {
									e.stopPropagation();
									this.setState({ changeVendor: true })
								}} className="penciledit"
									style={{ marginLeft: '10px', cursor: 'pointer', position: 'relative', zIndex: 3 }} />
							</p>
							<p style={{ color: "#777", fontSize: 13 }}>
								{vendor.email}
							</p>
							<p style={{ color: "#777", fontSize: 13 }}>
								{vendor.gstin}
							</p>
							<p style={{ color: "#777", fontSize: 13 }}>
								{vendor.mobile}
							</p>
						</div>
						<div style={{
							flex: 1
						}}>
							<p style={{ fontSize: 23, color: "#333", fontWeight: 800, textAlign: 'right' }}>
								Invoice
							</p>
							<p style={{ color: "#777", fontSize: 13, marginTop: 12, textAlign: 'right' }}>
								Invoice Id: {invoice.invoiceId}
							</p>
							<p style={{ color: "#777", fontSize: 13, textAlign: 'right' }}>
								Date : {invoice.date}
							</p>
							{
								invoice.due ?
									<p style={{ color: "#777", fontSize: 13, textAlign: 'right' }}>
										Due Date : {invoice.due}
									</p> : undefined
							}
						</div>
					</div>

					<div style={{ display: "flex", padding: 25 }}>
						<div style={{
							flex: 2
						}}>
							<p style={{
								fontSize: 15,
								color: "var(--text-color)",
								fontWeight: 700,
								marginRight: 100,
							}}>
								Billing Address:-
							</p>
							<div style={{
								marginRight: 100,
							}}>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{address.title}
								</p>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{address.address}
								</p>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{address.city}
								</p>
							</div>
						</div>
						<div style={{
							flex: 2
						}}>
							<p style={{
								fontSize: 15,
								color: "var(--text-color)",
								fontWeight: 700,
								marginRight: 100,
							}}>
								Deliver To:-
							</p>
							<div style={{
								marginRight: 100,
							}}>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{deliverTo.title}
								</p>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{deliverTo.address}
								</p>
								<p style={{ color: "var(--ex-light-color)", fontSize: 12 }}>
									{deliverTo.city}
								</p>
							</div>
						</div>
						<div className="flex-1" />
					</div>
					{this.renderMiddleArea()}
					<div style={{ padding: 25, fontSize: 13 }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
							<div style={{ width: 450, borderTop: '1px solid #888', paddingTop: 18, paddingBottom: 18 }}>
								<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
									<p style={{ marginRight: 12, width: 150, fontSize: 14 }}>Base Amount:</p>
									<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
										USD {taxInformation.base}

									</div>
								</div>
								<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
									<p style={{ marginRight: 12, width: 150, fontSize: 14 }}>Tax Amount:</p>
									<p style={{ fontSize: 14 }}>
										USD {taxInformation.tax}
									</p>
								</div>
								<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
									<p style={{ marginRight: 12, width: 150, fontSize: 14, fontWeight: 600 }}>Total Amount:</p>
									<p style={{ fontSize: 14, fontWeight: 600 }}>
										USD {taxInformation.amount}
									</p>
								</div>
								
							</div>
						</div>
					</div>
				</div>
				<p style={{
					marginTop: 30,
					fontSize: 15,
					fontWeight: 600
				}}>
					Attachments
				</p>
				<Attachments postAttachment={this.props.postAttachment} {...this.props}
					attachments={data.invoice.files} />

				
			</div>
		)
	}

}

export default Invoice
