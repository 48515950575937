import React from 'react'
import Props from 'prop-types'

class Rating extends React.Component {

	state = {
		value: undefined
	}

	getValue = () => {
		return this.state.value;
	}
	componentDidMount() {
		if (this.props.ref) {
			this.props.ref(this);
		}
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	render() {
		return (
			<div>
				<p style={{
					fontSize: 15,
					color: "#676767",
				}}>
					{this.props.label} :-
				</p>
				<div style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: 20,
					marginTop: 18
				}}>
					<img alt="poor" onClick={event => {
						this.setState({
							value: 'poor'
						})
					}} style={{
						width: 70, height: 70, cursor: 'pointer',
						marginRight: 22
					}} src={this.state.value !== "poor" ? require("./assets/sad-face-in-rounded-square.png") : require("./assets/sad.png")} />
					<img alt="poor" onClick={event => {
						this.setState({
							value: 'good'
						})
					}} style={{
						width: 70, height: 70, cursor: 'pointer',
						marginRight: 22
					}} src={this.state.value === "good" ? require("./assets/smile.png") : require("./assets/smiling-emoticon-square-face.png")} />
					<img alt="excellent" onClick={event => {
						this.setState({
							value: 'excellent'
						})
					}} style={{
						width: 70, height: 70, cursor: 'pointer',
					}} src={this.state.value === "excellent" ? require("./assets/happy.png") : require("./assets/happy (1).png")} />
				</div>
			</div>
		)
	}

}

Rating.propTypes = {
	label: Props.string.isRequired,
}

export {
	Rating
}
