import React from 'react'
import Pdf from "./pdf.png";
import Zoom from "react-medium-image-zoom";


class Attachments extends React.Component {

	state = {
		uploadModal :false
	}

	render() {

		if(this.props.attachments.length === 0) {
			return <p style ={{fontSize:12}}>No attachment in this invoice</p>
		}
		return (
			<div>
				<div style={{
					display: 'flex',
					flexWrap: 'wrap',
					marginTop: 12
				}}>
					
					{
						this.props.attachments.map((item, index) => {
							return (
								<div key={index} style={{ marginRight: 12 }}>
									{
										item.includes("pdf") ?
											<a rel="noopener noreferrer" href={item} target="_blank" style={{
												cursor: 'pointer'
											}}><img
												src={Pdf}
												alt="pdf icon" style={{
												width: 120,
												height: 120,
												marginRight: 12,
												border: '1px solid var(--border-color)',
												cursor: 'pointer'
											}} /></a>
											: item.includes("csv") || item.includes("xlsx") ?
											<a rel="noopener noreferrer" href={item} target="_blank" style={{
												cursor: 'pointer'
											}}><img
												src={require('./excel.png')}
												alt="pdf icon" style={{
												width: 120,
												height: 120,
												marginRight: 12,
												border: '1px solid var(--border-color)',
												cursor: 'pointer'
											}} /></a> :
											<Zoom image={{
												src: item,
												alt: 'Golden Gate Bridge',
												className: 'img',
												style: { height: 120, width: 120 }
											}}>
												<img
													alt="that wanaka tree"
													src={item}
													style={{
														height: 120,
														width: 120
													}}
												/>
											</Zoom>

									}
								</div>
							)
						})
					}
				
				</div>
			</div>
		)
	}

}

export default Attachments
