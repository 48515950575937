import React from 'react';



class LineItem extends React.Component {


    mapLineItem = (item, index) => {
        return (
            <p className="privacy-common-single-lineitem">{`${index + 1}. ${item}`}</p>
        )
    }

    render() {
        return (
            <div style={{
                marginLeft: 30,
                marginBottom: 20
            }}>
                {this.props.text.map(this.mapLineItem)}
            </div>
        )
    }
}

export default LineItem;