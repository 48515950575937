import React from 'react';


class SelectInput extends React.Component {

    mapItem = (item, index) => {
        return (
            <div style={{display: 'flex', marginTop: 5}} key={index}>
                <p style={{
                    color: "White",
                    fontSize: 14
                }}>{item.key}</p>
                <p style={{
                    paddingLeft: 15,
                    color: 'rgb(80, 251, 124)',
                    fontSize: 14
                }}>{item.size}</p>
            </div>
        )
    }

    render() {
        let items = this.props.item.option;
        return (
            <div style={{paddingLeft:55,paddingTop:2}}>
                {
                    items.map(this.mapItem)
                }
                <div style={{display: 'flex'}}>
                    <p style={{
                        marginTop: 5,
                        color: 'rgb(80, 251, 124)',
                        fontSize: 14
                    }}>Please Select the option ? </p>
                    <input type="text"
                           autoFocus={true}
                           onKeyDown={e => {
                               if (e.key === 'Enter') {
                                   let name = this._input.value;
                                   let count = 0;
                                   let item = ''
                                   items.forEach(val => {
                                       if (val.key.toLowerCase() === name.toLowerCase()) {
                                           count++;
                                           item = val
                                       }
                                   })
                                   if (count > 0) {
                                       this.props.item.action(item.size);
                                   } else {
                                       alert("Please select valid options")
                                   }
                               }
                           }}
                           ref={ref => this._input = ref}
                           className="blink"
                           style={{
                               background: 'transparent',
                               border: 'none',
                               color: 'white',
                               fontSize: 14,
                               paddingLeft: 10,
                               fontWeight: 400,
                               display: 'inlineBlock'
                           }}/>
                </div>
            </div>
        );
    }
}

export default SelectInput;
