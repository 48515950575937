let api;
let developement = window.location.href.includes('localhosts');

if (developement) {
	api = "https://heimdall.expanso.in/website/"
} else {
	api = "https://heimdall.eka.io/website/";
}

export {
	api
}



