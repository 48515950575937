import React from 'react';
import './index.css';
import { EkaLogo } from '../../../Images/Common/index'
import { InputForm } from '../Common/Input';
import Data from './data';
import { showToast } from '../Toast/ToastCallback';
import { Images } from '../Images/Image';
import axios from 'axios';


class Demos extends React.Component {


    state = {
        name: '',
        email: '',
        companyName: '',
        position: '',
        employees: '',
        mobile: '',
        industry: ''
    }



    componentDidMount() {

    }


    submit = () => {




        let name = this.state.name;
        let email = this.state.email;
        let companyName = this.state.companyName;
        let position = this.state.position;
        //let employees = this.state.employees;
        let mobile = this.state.mobile;
        let industry = this.state.industry;
        let id = localStorage.getItem('_hjid');
        let hotjarId = id.split('-');
        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter your name'
            })
            return;
        }
        if (!email) {
            showToast({
                type: 'error',
                message: 'Please enter your email'
            })
            return;
        }
        if (!companyName) {
            showToast({
                type: 'error',
                message: 'Please enter your company Name'
            })
            return;
        }
        if (!position) {
            showToast({
                type: 'error',
                message: "Please enter your position"
            })
            return;
        }

        if (!mobile) {
            showToast({
                type: 'error',
                message: 'Please enter a valid mobile number'
            })
            return;
        }
        if (!industry) {
            showToast({
                type: 'error',
                message: 'Please enter a valid industry details'
            })
            return;
        }

        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const e = regexp.test(this.state.email);
        if (!e) {
            showToast({
                type: 'error',
                message: 'Please enter a valid email'
            })
            return;
        }

        mobile = this.state.mobile;
        if (mobile.length !== 10 || mobile.charAt(0) === '0' || mobile.charAt(0) === '1' || mobile.charAt(0) === '2' || mobile.charAt(0) === '3' || mobile.charAt(0) === '4' || mobile.charAt(0) === '5') {
            showToast({
                type: 'error',
                message: 'Please enter 10 ten valid mobile number'
            })
            return;
        }

        // let data = {
        //     name,email,companyName,position,employees,mobile,industry
        // }

        axios({
            method: 'post',
            url: `https://heimdall.eka.io/triggers/contact`,
            data: {
                to: 'lakshay@antrepriz.com',
                name: `${this.state.name}`,
                email: `${this.state.email}`,
                company: this.state.companyName,
                designation: `${this.state.position} hotjarId: ${hotjarId}`,
                size: this.state.position,
                industry: this.state.industry,
                phone: this.state.mobile
            }
        })
            .then(res => {
                this.setState({
                    name: '',
                    email: '',
                    company: '',
                    designation: '',
                    size: '',
                    industry: '',
                    phone: ''
                })
                showToast({
                    type: 'success',
                    message: 'Form submit successfully'
                })
                return;
            })
    }

    render() {
        return (
            <div id="demos">
                <div className="demos-outer">
                    <div className="demos-left">
                        <div>
                            <img onClick={() => this.props.history.push('/')} src={EkaLogo} alt="Antrepriz"
                                style={{
                                    width: 130,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }} />
                        </div>
                        <p className="demos-left-header">Meet the world's leading  <br></br>Digital corporate spending & consumption platform</p>
                        <p className="demos-left-desc">
                            AI driven employee spending and travel with unparallel experience, control, speed and savings upto 25%
                        </p>
                        <div className="demos-left-form">
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={{ flex: 1, marginRight: 12 }}>
                                    <InputForm value={this.state.name} type="text" title="Name"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            name: e.target.value
                                        })}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputForm value={this.state.email} type="text" title="Email"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            email: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <div style={{ flex: 1, marginRight: 12 }}>
                                    <InputForm value={this.state.companyName} type="text" title="Company Name"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            companyName: e.target.value
                                        })}

                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <InputForm value={this.state.position} type="text" title="Position"
                                        onChange={e => this.setState({
                                            ...this.state,
                                            position: e.target.value
                                        })}
                                    />
                                </div>
                            </div>


                            <div style={{ flex: 1 }}>
                                <InputForm value={this.state.mobile} type="number" title="Mobile Number"
                                    onChange={e => this.setState({
                                        ...this.state,
                                        mobile: e.target.value
                                    })}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <InputForm value={this.state.industry} type="text" title="Industry"
                                    onChange={e => this.setState({
                                        ...this.state,
                                        industry: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                        <div onClick={this.submit} style={{
                            width: '100%',
                            textAlign: 'center'
                        }} className="btn btn-portlet">Submit</div>
                    </div>
                    <div className="demos-right">
                        <div>
                            <div className="demos-right-question">
                                <p>
                                    Eka is giving us complete visibility & has brought down our reimbursement timelines by 60%.
                                </p>
                                <p style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                    <strong>Shekhar Chandra,</strong>, <span style={{ fontWeight: 10 }}>Shuttl</span>
                                </p>
                                <div className="demos-right-pointer"></div>

                            </div>
                            <div style={{
                                marginTop: 35,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    height: 55,
                                    width: 55,
                                    borderRadius: '50%',
                                    background: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img src={Images.shuttl} alt="Antrepriz" style={{
                                        width: 50,
                                        height: 30,
                                        objectFit: 'contain'
                                    }} />
                                </div>
                            </div>
                            <div>
                                <p style={{
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    color: '#fff',
                                    marginTop: 30,
                                    fontSize: 12,
                                    letterSpacing: 1
                                }}>TRUSTED BY OVER 30 COMPANIES WORLDWIDE</p>
                            </div>

                            <div style={{ marginTop: 40 }} className="flex-wrap">
                                {Data.map((val, index) => {
                                    return (
                                        <div key={index} className="demos-right-image">
                                            <img alt={`${val.alt}`} src={val.imageUrl} style={val.heightRestriction ? {
                                                height: 60,
                                                width: 60
                                            } : null} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Demos;
