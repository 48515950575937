import React from 'react';
import {features} from './data';
import './index.css';
import {SingleComparisionDesktop, SingleComparisionMobile} from '../../Common/Comparision';
import {Header} from "../../Common/Header/index";
import {Footer} from "../../Common/Footer";


class Features extends React.Component {

	mapItem = (item, index) => {
		return (
			<SingleComparisionDesktop key={index} item={item}/>
		)
	}

	mapMobile = (item, index) => {
		return <SingleComparisionMobile key={index} item={item}/>
	}

	render() {
		return (
			<div>
				<Header/>
				<div style={{
					borderBottom:'dashed 2px #6367FA',
					paddingTop:120
				}} className="ptb-70 dotted ptb-40">
					<h1 style={{
						fontSize: 50,
						marginBottom: 60
					}} className="flex-h-center">Fyle Vs Eka</h1>
					<h1 className="flex-h-center mt-25 mb-25">Compare Features</h1>
				</div>
				<div style={{background:"var(--background-color)"}} className="ptb-70 ptb-40">
					<div id="feature" className="container-size">
						<div className="feature-desktop">{features.map(this.mapItem)}</div>
						<div className="feature-mobile">{features.map(this.mapMobile)}</div>
					</div>
				</div>
				<Footer/>
			</div>
		);
	}
}

export default Features;
