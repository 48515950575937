//LandingPage Image

const LandingPageImage = "https://ik.imagekit.io/eka/tr:pr-true,w-900/landing/landing_rGFdbVYIi.png";


//Companies Images

const shuttl = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/shuttl_a0sML3huR8.png";
const ofcspc = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/OFCSPC_RwU9MDDVbu.png";
const oneculture = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/oneCulture_tXC7caQWEF.png";
const licious = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/licious_1IJEExrFIP.png";
const dtdc = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/dtdc1_VM5SYUb4dn.png";
const awign = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/awign_XHD3B8b8H.png";
const bizongo = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/bizongo_logo_qjjCJQy1vi.png";
const blackbuck = "https://ik.imagekit.io/eka/tr:pr-true,w-200/clients/blackbuck-logo_qoLzmV2hX.png";


//Home ---> UseCases


const capture = "https://ik.imagekit.io/eka/tr:pr-true,w-200/cases/capture_L75ZnX8Ybr.png";
const email = "https://ik.imagekit.io/eka/tr:pr-true,w-200/cases/email_GObTYh1fqe.png";
const time = "https://ik.imagekit.io/eka/tr:pr-true,w-200/cases/time_DhUbcvwKu.png";
const playActive  = "https://ik.imagekit.io/eka/tr:pr-true,w-200/cases/play_kMNhAI_Pqk.png";
const playWhite = "https://ik.imagekit.io/eka/tr:pr-true,w-200/cases/playWhite_WHT412V9s6.png";


//Home ---> SmartReceipt

const smartReceipt1 = "https://ik.imagekit.io/eka/tr:pr-true,w-1000/smart/screen1_dv1j3-BEYO.png";
const smartReceipt2 = "https://ik.imagekit.io/eka/tr:pr-true,w-1000/smart/screen2_iPGp8n_9tg.png";
const smartReceipt3 = "https://ik.imagekit.io/eka/tr:pr-true,w-1000/smart/screen3_TV7EPV9hGh.png";
const smartReceipt4 = "https://ik.imagekit.io/eka/tr:pr-true,w-1000/smart/screen4_DKdtku4n-.png";


//HOme ----> Savings 


const Sone = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/1_u-_EWOJ1B.png";
const Stwo = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/2_Shs_ItwLla.png";
const Sthree = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/3_K7532UEQr.png";
const Sfour = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/4_wEA_o1gvMw.png";
const Sfive = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/5_VcPC3GpeCm.png";
const Ssix = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/6_Dz7yEACgm.png";
const Sseven = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/7_P99AA3RL4.png";
const Seight = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/8_BVBFoKq_p.png";

//Home ---> Integration

const zapier  = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/zapier_QwfNDsnUk.png";
const stripe = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/stripe_KD_r-uwrqP.png";
const dos = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/do_8Bd2T4qxdD.png";
const aws = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/aws_reuoMrvNR1.jpeg";
const ola = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/ola_DNyXhTNmd.jpg";
const uber = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/uber_Cu5Mzk76jv.png";
const excel = "https://ik.imagekit.io/eka/tr:pr-true,w-200/integrations/excel__aM7HtxLh.png";


const Saving4 = "https://ik.imagekit.io/eka/tr:pr-true,w-800/savings/saving4_fJoNb5exT.png";

//Profile

const vibhuti = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/vibhuti_-k_qX6fY4.jpeg";
const sonam ="https://ik.imagekit.io/eka/tr:pr-true,w-400/members/sonam_wtd1qxyETT.jpeg";
const jayu ="https://ik.imagekit.io/eka/tr:pr-true,w-400/members/sai_bwr9Llf9pG.jpeg";
const sumit ="https://ik.imagekit.io/eka/tr:pr-true,w-400/members/sumit_OcIgItSD67.jpeg";
const rohit ="https://ik.imagekit.io/eka/tr:pr-true,w-400/members/rohit_YjauaRiHRX.jpeg";
const prashant = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/prashant_DMJkhAyhZ9.jpeg";
const navin = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/navin_-S597E2qdX.jpeg";
const lakshay = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/lakshay_kMGAOax3Q.png";
const amit = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/amit_jo01HXpVLx.jpeg";
const neeraj = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/neeraj_9j3NzWsRFI.jpeg";
const kalai = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/kalai_Hnpob-uQZY.jpeg";
const chayan = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/chayan_zEciB7IdV2.jpeg";
const ghanist = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/ghanist_h7XPlnavXW.jpeg";
const ankur = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/ankur_jE7vWs_GOP.jpeg";
const karan = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/karan_Tgb4UW0RgB.jpeg"
const sneha = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/sneha_sdLOJHM3WN.jpeg"
const uttam = "https://ik.imagekit.io/iz4dldzmyf/uttam_sGWSwWJJe.jpg"
const shubham = "https://ik.imagekit.io/iz4dldzmyf/IMG_20200425_152009_vCuI1bNN1.jpg"

//Search

const search = "https://ik.imagekit.io/eka/tr:pr-true,w-400/members/search_Unjy9lZx2c.png"


const Images = {
    LandingPageImage,
    shuttl,
    ofcspc,
    oneculture,
    licious,
    dtdc,
    awign,
    bizongo,
    blackbuck,
    capture,
    email,
    time,
    playActive,
    playWhite,
    smartReceipt1,smartReceipt2,smartReceipt3,smartReceipt4,
    Sone,Stwo,Sthree,Sfour,Sfive,Ssix,Sseven,Seight,
    zapier,stripe,dos,aws,ola,uber,excel,Saving4,
    sonam,jayu,sumit,rohit,prashant,navin,lakshay,amit,neeraj,
    kalai,chayan,ghanist,ankur,karan,vibhuti,search,sneha,uttam,shubham


}

export {
    Images
}