import React from 'react';



class Para extends React.Component {


    render() {
        return (
            <div className="privacy-common-para">
                {this.props.text}
            </div>
        )
    }
}

export default Para;