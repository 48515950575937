

const aboutData = [
    {
        cname:'value5',
        val:'Customer Centric',
        hid:'We put your problems first'
    },
    {
        cname:'value1',
        val:'Leaders own',
        hid:'by solving them just as our own'
    },
    {
        cname:'value2',
        val:'Bias for action',
        hid:'as quickly & with a ton of faith'
    },
    {
        cname:'value3',
        val:'Integrity',
        hid:'by always stand for what\'s right'
    },

];

const employeeData = [
    {
        img:require('./duggu.jpeg'),
        name:"Duggu",
        linkedin:"",
        desig:"Happiness Officer 1",
        college:''
    },
    {
        img:require('./gabbu.jpeg'),
        name:"Gabbu",
        linkedin:"",
        desig:"Happiness Officer 2",
        college:''
    },
    {
        img:require('./jinni.jpeg'),
        name:"Jinni",
        linkedin:"",
        desig:"Happiness Officer 3",
        college:''
    },

]

export { aboutData, employeeData };
