import React from 'react'
import './typing.css'
import System from "./System";
import Question from "./Question";
import Input from "./Input";
import Answer from "./Answer";
import SelectInput from './SelectInput';
import Confirmation from "./Confirmation";
import axios from "axios";
import { showToast } from '../Toast/ToastCallback';

class index extends React.Component {

	state = {
		css: {
			width: 550,
			height: 375,
			right: 20,
			bottom: 20,
			borderRadius: 8
		},
		blocks: [
			{
				type: "welcome",
				message: "Welcome to EKA!",
				css: {
					fontSize: 21,
					color: "#48BBFF",
					fontWeight: 600
				}
			}
		],
		data: {

		},
		name: undefined,
		company: undefined,
		email: undefined,
		size: undefined,

	}

	_handleCompanyName = company => {
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: company
			},
			{
				type: "question",
				message: "What is your position in your company?",
				css: {
					fontSize: 14,
					color: "#50FB7C"
				},
				top: 18
			},
			{
				type: 'input',
				action: this._handlePosition
			}
		])
		this.setState({
			...this.state,
			blocks,
			company
		})
	}

	_handlePosition = position => {
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: position
			},
			{
				type: "question",
				message: "What is your email address?",
				css: {
					fontSize: 14,
					color: "#50FB7C"
				},
				top: 18
			},
			{
				type: 'input',
				action: this._handleEmail
			}
		])
		this.setState({
			...this.state,
			blocks,
			position
		})
	}

	handleConfirmation = action => {

		if (action.toUpperCase() === "Y") {
			let id = localStorage.getItem('_hjid');
			let hotjarId = id.split('-');
			axios({
				method: 'post',
				url: `https://heimdall.eka.io/triggers/contact`,
				data: {
					to: 'lakshay@antrepriz.com',
					name: `${this.state.name}`,
					email: `${this.state.email}`,
					company: this.state.company,
					designation: `${this.state.position} hotjar Id ${hotjarId}`,
					size: this.state.size,
					industry: "No Input",
					phone: this.state.mobile
				}
			}).then(res => {
				let blocks = this.state.blocks;
				blocks = blocks.slice(0, blocks.length - 1);
				blocks.push(...[
					{
						type: "question",
						message: "Do you confirm provided details?",
						css: {
							fontSize: 14,
							color: "#50FB7C"
						},
						top: 18
					},
					{
						type: 'answer',
						text: action
					},
					{
						type: 'answer',
						text: "Your form has been successfully submitted!",
						top: 18
					}
				]);
				this.setState({
					...this.state,
					blocks
				})
			})
		} else {
			let blocks = this.state.blocks;
			blocks = blocks.slice(0, blocks.length - 1);
			blocks.push(...[
				{
					type: "question",
					message: "Do you confirm provided details?",
					css: {
						fontSize: 14,
						color: "#50FB7C"
					},
					top: 18
				},
				{
					type: 'answer',
					text: action
				},
				{
					type: 'confirmation',
					action: this.handleConfirmation
				}
			]);
			this.setState({
				...this.state,
				blocks
			})
		}
	}

	_handleMobile = mobile => {
		if (mobile.length !== 10 || mobile.charAt(0) === '0' || mobile.charAt(0) === '1' || mobile.charAt(0) === '2' || mobile.charAt(0) === '3' || mobile.charAt(0) === '4' || mobile.charAt(0) === '5') {
			showToast({
				type: 'error',
				message: 'Please enter 10 ten valid mobile number'
			})
			return;
		}
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: mobile
			},
			{
				type: 'confirmation',
				action: this.handleConfirmation
			}
		]);
		this.setState({
			...this.state,
			blocks,
			mobile
		})
	}

	_handleSize = size => {
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: size
			},
			{
				type: "question",
				inputType: 'number',
				message: "What is your mobile number?",
				css: {
					fontSize: 14,
					color: "#50FB7C"
				},
				top: 18
			},
			{
				type: 'input',
				types: 'number',
				action: this._handleMobile
			}
		])
		this.setState({
			...this.state,
			blocks,
			size
		})
	}

	_handleEmail = (email) => {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const auth = regexp.test(email);
		if (!auth || email.length < 13) {
			showToast({
				type: 'error',
				message: 'Please enter a vald email'
			})
			return;
		}
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: email
			},

			{
				type: "question",
				message: "What is the size of your company?",
				css: {
					fontSize: 14,
					color: "#50FB7C"
				},
				top: 18
			},
			{
				type: 'select',
				option: [
					{
						key: 'A',
						size: '1-100'
					},
					{
						key: 'B',
						size: '100-500'
					},
					{
						key: 'C',
						size: '500-1000'
					},
					{
						key: 'D',
						size: '1000-10000'
					},
					{
						key: 'E',
						size: 'Above 10000'
					}
				],
				action: this._handleSize
			},

		])
		this.setState({
			...this.state,
			blocks,
			email
		})
	}

	_handleNameEntered = name => {
		let blocks = this.state.blocks;
		blocks = blocks.slice(0, blocks.length - 1);
		blocks.push(...[
			{
				type: 'answer',
				text: name
			},

			{
				type: "question",
				message: "What is the name of your company?",
				css: {
					fontSize: 14,
					color: "#50FB7C"
				},
				top: 18
			},
			{
				type: 'input',
				action: this._handleCompanyName
			},

		])
		this.setState({
			...this.state,
			blocks,
			name
		})
	}



	componentDidMount() {
		let next = () => {
			let blocks = this.state.blocks;
			blocks.push(...[
				{
					type: "question",
					message: "What is your full name?",
					css: {
						fontSize: 14,
						color: "#50FB7C"
					},
					top: 18
				},
				{
					type: 'input',
					action: this._handleNameEntered
				},

			])
			this.setState({
				...this.state,
				blocks
			})
		}
		setTimeout(() => {
			let blocks = this.state.blocks;
			blocks.push({
				type: "welcome",
				message: "Please fill in the few details below to schedule demo:-",
				css: {
					fontSize: 14,
					color: "#48BBFF"
				}
			})
			this.setState({
				...this.state,
				blocks
			})
			setTimeout(next, 3500)
		}, 2500)
	}

	renderHeader() {
		return (
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				padding: 10,
				width: '100%',
				background: 'rgba(1,1,1,.2)'
			}}>
				<div className="hoverable" onClick={this.props.onClose} style={{
					backgroundColor: "#FF5F58",
					width: 12,
					height: 12,
					borderRadius: 7
				}}>
					<img alt="close" src={require('./cancel.png')} style={{
						width: 6,
						height: 6,
						margin: 3
					}} />
				</div>
				<div className="hoverable" onClick={() => {
					if (this.state.css.height === "100vh") {
						this.setState({
							...this.state,
							css: {
								width: 550,
								height: 375,
								right: 20,
								bottom: 20,
								borderRadius: 8
							}
						})
					}
					else {
						this.setState({
							...this.state,
							css: {
								width: 550,
								height: 100,
								right: 20,
								bottom: 20,
								borderRadius: 8
							}
						})
					}
				}} style={{
					backgroundColor: "#FFBD2F",
					width: 12,
					height: 12,
					borderRadius: 7,
					marginLeft: 6
				}}>
					<img alt="minimize" src={require('./substract.png')} style={{
						width: 6,
						height: 6,
						margin: 3
					}} />
				</div>
				<div onClick={() => {
					if (this.state.css.height === 375) {
						this.setState({
							...this.state,
							css: {
								width: '100%',
								height: '100vh',
								right: 0,
								bottom: 0,
								top: 0,
								left: 0
							}
						})
					} else {
						this.setState({
							...this.state,
							css: {
								width: 550,
								height: 375,
								right: 20,
								bottom: 20,
								borderRadius: 8
							}
						})
					}
				}} className="hoverable" style={{
					backgroundColor: "#16C132",
					width: 12,
					height: 12,
					borderRadius: 7,
					marginLeft: 6
				}}>
					<img alt="expand" src={require('./maximize.png')} style={{
						width: 6,
						height: 6,
						margin: 3
					}} />
				</div>
			</div>
		)
	}

	block = (item, index) => {
		if (item.type === "welcome") {
			return (
				<System css={item.css} key={index} text={item.message} />
			)
		}
		if (item.type === "question") {
			return (
				<Question top={item.top} user={this.state.user} key={index} css={item.css} text={item.message} />
			)
		}
		if (item.type === "input") {
			return (
				<Input top={item.top} user={this.state.user} item={item} key={index} />
			)
		}
		if (item.type === "answer") {
			return (
				<Answer top={item.top} user={this.state.user} item={item} key={index} />
			)
		}

		if (item.type === "select") {
			return (
				<SelectInput top={item.top} key={index} item={item} />
			)
		}

		if (item.type === "confirmation") {
			return (
				<Confirmation top={item.top} key={index} item={item} />
			)
		}
		return (
			<div key={index}>
				<p>{}</p>
			</div>
		)
	}

	render() {
		let self = this;
		return (
			<div
				style={{
					display: "flex",
					position: 'fixed',
					zIndex: 2222,
					overflowY: 'auto',
					backgroundColor: "#1E1F29",
					flexDirection: 'column',
					boxShadow: '2px 2px 1px rgba(1,1,1,.1)',
					...this.state.css
				}}
			>
				{self.renderHeader()}
				<div style={{ flex: 1, overflowY: 'auto', padding: 10, paddingLeft: 15, paddingRight: 15 }}>
					{
						this.state.blocks.map(self.block)
					}
				</div>
			</div>
		)
	}

}

export default index
