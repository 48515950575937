import {get} from '../../../../../../../Model/Network/Config/Axios'

class Model {

	constructor() {
		this.Disposables = [];
	}

	destroy = () => {
		this.Disposables.forEach(item => {
			if (item.isCancellable())
				item.cancel()
		})
	}

	getBlog =(id,cb)=>{
		get(`blog/${id}`,cb)
	}

	getCategories = (cb)=>{
		get(`categories`,cb)
	}

	getBlogs =(cb)=>{
		get(`blogs?limit=20`,cb)
	}

}

export default Model
