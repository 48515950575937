import React from 'react';
import ReactDOM from 'react-dom';
import Index from './Pages';
import './Pages/Website/Common/App.css';
import './Pages/Website/Common/animate.css';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<Index />, document.getElementById('root'));

serviceWorker.unregister();
