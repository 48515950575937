import React from 'react';
import {Header} from '../../Common/Header';
import { Footer } from '../../Common/Footer';
import Alldetails from './data';
import Title from '../Common/Title';
import Para from '../Common/Para';
import LineItems from '../Common/LineItems';
import './index.css';


class Index extends React.Component {




    mapLineItem = (item, index) => {

        if (item.type === 'title') {
            return (
                <Title text={item.text} key={index} />
            )
        } else if (item.type === 'para') {
            return (
                <Para text={item.text} key={index} />
            )
        } else if (item.type === 'lineitem') {
            return (
                <LineItems text={item.text} key={index} />
            )
        }

    }


    mapItem = (item, index) => {
        return (
            <div key={index} style={{
                marginBottom: 40
            }}>
                {item.map(this.mapLineItem)}
            </div>
        )
    }

    render() {
        return (
            <div id="privacy-policy">
                <Header />
                <div >
                    <div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
                        <div className="container-size">
                            <div style={{
                                textAlign: 'center',
                                height: 200,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>
                                <h1 className="privacy-title">Terms & Conditions</h1>
                                <p className="privacy-desc">Eka</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ptb-70 ptb-40">
                    <div className="container-size">
                        <p style={{
                            fontSize: 20,
                            marginBottom: 40,
                            fontWeight: 500,
                            letterSpacing: 0.5
                        }}>
                            The Terms and Conditions (T&C) contained herein along with the Privacy Policy and Terms of Use, collectively forms an Agreement regulating our relationship with regard to the use of Roadcast Tech. Solutions. Pvt. Ltd. (RTSPL) website by you. Please read this Agreement carefully. This Agreement is explicitly effective upon entering and using any data in this site or any other linked / affiliated sites of RTSPL or its partners. You are advised to regularly check for any amendments or updates to the terms and conditions from time to time. Any clause of terms and conditions if deemed invalid, void or for any reason unenforceable, shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the terms and conditions.
                        </p>
                        {Alldetails.map(this.mapItem)}
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}


export default Index;
