import React from 'react'
import Form from "./Fields";

class Step extends React.Component {



	footer = () => {

		return (
			<div style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: 'inherit',
				padding: '0px 100px'
			}}>
				<div style={{
					fontWeight: '600',
					fontSize: 17,
					cursor: 'pointer',
					color: 'red'
				}}>
					{
						this.props.step > 0 ?
							<p onClick={() => {
								this.props.onReset()
							}}>Reset Form</p> : null
					}
				</div>

				<div style={{
					fontWeight: '600',
					fontSize: 17,
					cursor: 'pointer',
					color: 'var(--primary-color)'
				}}>
					<p onClick={() => {
						this.form.getForm((data, questionList) => {
							let { steps } = this.props.data;
							let step = this.props.step;
							let item = steps[step];

							this.props.onNext({ [item.id]: data }, data, questionList)
						})
					}}>{this.props.step + 1 === this.props.data.steps.length ? "Finish" : "Next"}</p>
				</div>
			</div>
		)

	}

	validate = (item) => {

		let form = this.props.form;

		if (item.requiredFields && item.requiredFields.length > 0) {
			let requiredFields = item.requiredFields;
			for (let i = 0; i < requiredFields.length; i++) {
				let splits = requiredFields[i];
				if (splits.includes(":")) {
					splits = splits.split(":");
					let key = splits[0];
					let value = splits[1];
					if (!form[key]) {
						return false;
					}
					if (form[key].value.toLowerCase() !== value.toLowerCase()) {
						return false;
					}
				} else {

				}
			}
		}
		return true;
	}

	render() {
		let { steps } = this.props.data;
		let step = this.props.step;
		if (!steps.length > step) {
			return (<div />)
		}
		let item = steps[step]
		if (!this.validate(item)) {
			this.props.onSkip()
			return (<div />)
		}

		return (
			<div style={{ background: 'var(--background-color)', minHeight: '90vh' }} className="ptb-70 ptb-40">
				<div className="container-size">
					<div className="flex-wrap">
						<div style={{
							padding: 20,
							background: 'white',
							border: '1px solid #dedede',
							width: '100%',
							boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
						}}>
							<Form
								handleRef={ref => {
									this.form = ref;
									this.form.fetchAdditionalForm(this.props.token, item.id)
								}}
								title={item.title}
								des={item.des}
							/>
						</div>
					</div>
				</div>
				<div style={{
					width: '100%',
					height: 80,
					position: 'fixed',
					bottom: 0,
					right: 0,
					left: 0,
					backgroundColor: '#fff',
					zIndex: 15,
					borderTop: '1px solid #dedede',
					boxShadow: 'rgba(0, 0, 0, 0.1) 0px -2px 5px -2px'
				}}>
					{this.footer()}
				</div>
			</div>
		)
	}

}

export default Step
