import React from 'react'
import axios from 'axios';

class File extends React.Component {

	state = {
		value: undefined,
		url: ''
	};

	componentDidMount() {
		if (this.props.ref) {
			this.props.ref(this);
		}
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	getValue() {
		return this.state.url;
	}


	upload = () => {
		let file = this._file.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			this.setState({
				...this.state,
				stateLoading: true
			})
			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			})
				.then(res => {
					this.setState({
						url: res.data.url
					})
				})

		}
	}

	setValue = () => {
		this.setState({
			url: ''
		})
	}

	render() {
		return (
			<div style={{
				paddingTop: 12,
				paddingBottom: 12
			}}>
				<p style={{
					fontSize: 15,
					marginBottom: 12,
					color: "#676767",
				}}>
					{this.props.label} :-
				</p>
				<div style={{}}>
					<div style={{
						marginBottom: 8,
						display: 'flex',
						alignItems: 'center',
						border: '1px solid #e6ebf3',
						borderRadius: 4,
						cursor: 'pointer'
					}}>
						<input type="file" onChange={() => this.upload()} ref={ref => this._file = ref} style={{
							height: '100%',
							width: '100%',
							padding: '10px 20px',
						}} />
					</div>
				</div>
			</div>
		)
	}

}


export {
	File
}
