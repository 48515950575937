import React from 'react';
import Props from 'prop-types';

class HalfModal extends React.Component {

    render() {
        return (
            <div style={{
                position: 'fixed',
                zIndex: 110,
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                backgroundColor: 'rgba(1,1,1,0.1)'
            }}>

                <div style={{
                    position: 'fixed',
                    zIndex: 111,
                    boxShadow: "-10px 0 20px 0 rgba(123,147,180,.2)",
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 350,
                    backgroundColor: "white"
                }}>
                    <div style={{
                        position: 'relative'
                    }}>
                        <div style={{
                            top: 0,
                            left: 350,
                            height: 60,
                            right: 0,
                            position: 'fixed',
                            zIndex: 112,
                            background: 'white',
                            display: 'flex',
                            borderBottom: '1px solid #dedede',
                        }}>
                            <img alt="Close Button" onClick={() => {
                                this.props.close()
                            }} src={require('./close.png')} style={{
                                margin: 22,
                                cursor: 'pointer',
                                width: 16,
                                height: 16,
                                marginRight: 10
                            }} />

                            <div style={{
                                padding: '10px 20px',

                            }}>
                                <p style={{ fontWeight: 600, fontSize: 16, marginTop: 4 }}>{this.props.title}</p>
                                <p style={{ fontSize: 12, color: 'var(--light-color)' }}>{this.props.desc}</p>
                            </div>
                        </div>
                        <div style={{ padding: '22px', marginTop: 60, height: '100vh', overflowY: 'auto', paddingBottom: 200, position: 'relative' }}>
                            {this.props.children}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


HalfModal.propTypes = {
    close: Props.func
}


export { HalfModal }