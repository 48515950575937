import React from "react";
import "./style.css";
import Article from "./lists/index";
import { Images } from "../../../Images/Image";
import {Header} from "../../../Common/Header/index";
import "./Article/style.css";
import stringquery from "stringquery";


const PAGECOUNT = 10;

class View extends React.Component {
  constructor(props) {
    super(props);
    this.presentor = props.presentor;
    this.presentor.setView(this);
  }
  state = {
    data: [],
    Categories: [],
    search: "",
    page: 0,
    allblogs: [],
    categoriesFilter: false,
    categoryId: null,
    fixedData: [],
    showOlder: true,
    isFound: false
  };

  componentDidMount() {
    this.presentor.getBlogs();
    this.presentor.getCategories();
    this.presentor.getAllBlogs();
  }

  successfullyGetValue(res = []) {
    let check = res.filter(item => {
      return item.published;
    });
    let showOlder = check.length === 0 ? true : false;
    this.setState({
      data: res.filter(item => {
        return item.published;
      }),
      fixedData: res.filter(item => {
        return item.published;
      }),
      isFound: showOlder
    });
  }
  successfullyGetValueForRecent(res = []) {
    this.setState({
      allblogs: res.filter(item => {
        return item.published;
      })
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const params = stringquery(this.props.history.location.search);
    const prev = stringquery(prevProps.history.location.search);
    if (
      this.state.categoryId !== prev.categoryId &&
      params &&
      params.categoryId
    ) {
      this.setState({
        ...this.state,
        categoriesFilter: true,
        categoryId: params.categoryId
      });
      this.presentor.getBlogsWithCategory(params.categoryId);
    }
    if (
      this.props.match.path === "/blog" &&
      this.state.categoriesFilter &&
      !params.categoryId
    ) {
      this.setState({
        ...this.state,
        categoriesFilter: false,
        categoryId: null
      });
      this.presentor.getBlogs();
      this.presentor.getCategories();
      this.presentor.getAllBlogs();
    }
  }

  getBlogsWithCategorySuccessfully(res = []) {
    let check = res.filter(item => {
      return item.published;
    });
    let showOlder = check.length === 0 ? true : false;
    this.setState({
      ...this.state,
      data: res.filter(item => {
        return item.published;
      }),
      isFound: showOlder
    });
  }

  getPage() {
    return this.state.page;
  }


  getCategoriesSuccessfully(res) {
    let newResult = res.filter(cur => {
      if (
        cur.title === "Corporate Solutions" ||
        cur.title === "test" ||
        cur.title === "done"
      ) {
        return false;
      }
      return true;
    });
    this.setState({
      ...this.state,
      Categories: newResult
    });
  }
  filterCheck(each, filter) {
    switch (filter) {
      case "recentPost":
        let name = each.title;
        name = name
          .trim()
          .split(" ")
          .join("-");
        this.props.history.push(`/blog/page/${each.blogId}/${name}`);
        break;
      case "CATEGORIES":
        this.props.history.push(`/blog?categoryId=${each.title}`);
        break;
      default:
    }
  }



  renderArticle() {



    let data = this.state.data;
    let pagecount = PAGECOUNT;
    let start = this.state.page * pagecount;
    let end = this.state.page * pagecount + pagecount;
    data = data.slice(start, end)


    if (this.state.isFound) {
      return (
        <div style={{ textAlign: "center", fontWeight: 600, fontSize: 22 }}>
          No Blog Found
        </div>
      );
    }

    let _view = data.map((item, index) => {
      let customStyle = {
        marginTop: index ? 30 : 0
      };
      return (
        <Article
          item={item}
          {...this.props}
          style={customStyle}
          key={item.blogId}
        />
      );
    });
    return _view;
  }




  handleSearch(searchKey) {
    let includeArray = this.state.fixedData.filter(item =>
      item.title.toLowerCase().includes(searchKey.toLowerCase())
    );
    let isFound = includeArray.length === 0 ? true : false;
    this.setState({
      ...this.state,
      data: includeArray,
      search: searchKey,
      isFound
    });
  }


  pagination = () => {
    let pagecount = this.props.pagecount ? this.props.pagecount : PAGECOUNT;
    let start = this.state.page * pagecount + 1;
    let end = this.state.page * pagecount + pagecount;
    let length = this.state.data.length
    if (end > length)
      end = length;
    if (start > length)
      start = length;
    return (
      <div className="fintrip-table-footer">
        <img style={{ cursor: 'pointer' }} onClick={() => {
          let page = this.state.page;
          if ((start + pagecount) <= (length)) {
            this.setState({
              page: page + 1
            })
          }
        }} alt="left arrow" className="right-arrow" src={require('./right-arrow (2).png')} />
        <img style={{ cursor: 'pointer' }} onClick={() => {
          let page = this.state.page;
          if (page > 0) {
            this.setState({
              page: page - 1
            })
          }
        }} alt="right arrow" className="left-arrow" src={require('./left-arrow (1).png')} />
        <p>
          {start} - {end} of {length} results
				</p>
      </div>
    )
  }

  render() {
    //let CATEGORIES = this.state.Categories;

    let renderArticleCotainer = "";
    if (this.state.categoryId) {
      renderArticleCotainer = {
        justifyContent: "flex-start"
      };
    }

    return (
      <div
        id="product-page"
        style={{ backgroundColor: "var(--background-color)" }}
      >
        <Header />
        <div
          style={{ borderBottom: "2px dashed var(--primary-color)", backgroundColor: '#fff' }}
          className="ptb-70 ptb-40 dotted"
        >
          <div className="container-size">
            <div>
              <div>
                <h1 className="integration-title">Eka Blogs</h1>
                <p className="integration-desc">
                  Explore widest range of blogs written by eka.
                </p>
                <div className="integration-input flex-v-center">
                  <img
                    src={Images.search}
                    alt=""
                    style={{
                      height: 25,
                      width: 25,
                      marginLeft: 10
                    }}
                  />
                  <input
                    onChange={e => {
                      this.handleSearch(e.target.value);
                    }}
                    value={this.state.search}
                    placeholder="Enter search criteria for blog"
                    type="text"
                    style={{
                      height: "80%",
                      width: "90%",
                      marginLeft: "auto",
                      fontSize: 16,
                      border: "none",
                      fontWeight: 500
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "var(--background-color)",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {this.state.categoryId ? (
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <p className="page-title">
                {" "}
                Browsed By Category:{" "}
                {this.state.categoryId.replace(/%20/g, " ")}
              </p>
            </div>
          ) : null}
        </div>

        {/* Show Blogs  */}

        <section
          className="renderArticleCotainer"
          style={{ ...renderArticleCotainer }}
        >
          <div className="inner-container-wrap">{this.renderArticle()}</div>
        </section>

        <div className="renderArticleCotainer">
          {
            this.state.data.length > 0 ?
              this.pagination()
              : null
          }
        </div>

        <div className="ptb-40"></div>
      </div>
    );
  }
}

export default View;
