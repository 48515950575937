import React from 'react'
import Lottie from 'react-lottie';
import { get, post } from "../../../Model/Network/Config/Axios";
import { Input } from "../Survey/Fields/Input";
import { Rating } from "../Survey/Fields/Rating";
import { Select } from "../Survey/Fields/Select";
import { File as FileUpload } from "../Survey/Fields/FileUpload";
import { Calendar } from '../Survey/Fields/Calendar';
import { TextArea } from '../Survey/Fields/TextArea';
import { DropDown } from '../Survey/Fields/DropDown';
import { handleError, showToast } from "../../Website/Toast/ToastCallback";


class Index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: undefined,
			forms: [],
			title: "",
			description: "",
		}
	}

	componentDidMount() {
		this.getToken();
	}

	formSubmit() {
		let name = this.name.getValue();
		if (!name) {
			showToast({
				type: 'error',
				message: "Please fill the details in name field "
			})
			return;
		}
		let tradeName = this.tradeName.getValue();
		if (!tradeName) {
			showToast({
				type: 'error',
				message: "Please fill the details in trade name field "
			})
			return;
		}
		let mobile = this.mobile.getValue();
		if (!mobile) {
			showToast({
				type: 'error',
				message: "Please fill the details in mobile field "
			})
			return;
		}

		let address = this.address.getValue();
		if (!address) {
			showToast({
				type: 'error',
				message: "Please fill the details in address field "
			})
			return;
		}

		let city = this.city.getValue();
		if (!city) {
			showToast({
				type: 'error',
				message: "Please fill the details in city field "
			})
			return;
		}


		let pincode = this.pincode.getValue();
		if (!pincode) {
			showToast({
				type: 'error',
				message: "Please fill the details in pincode field "
			})
			return;
		}
		let gstin = this.gstin.getValue();
		let msme = this.msme.getValue();
		if (!gstin) {
			showToast({
				type: 'error',
				message: "Please fill the details in gstin field "
			})
			return;
		}
		let pan = this.pan.getValue();

		let account = this.account.getValue();
		let ifsc = this.ifsc.getValue();
		let bank = this.bank.getValue();

		var anyRequired = undefined
		let forms = {};
		this.state.forms.forEach((item) => {
			let value = this[item.id].getValue();
			if (!value && item.required) {
				anyRequired = item.name;
			} else {
				forms[item.id] = value;
			}
		})
		if (anyRequired) {
			showToast({
				type: 'error',
				message: "Please fill the " + anyRequired
			})
			return;
		}

		let data = {
			basic: { name, mobile, tradeName }, address: { address, city, pincode }, tax: { pan, gstin, msme }, bank: { account, ifsc, bank }, forms
		}
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		data.token = params.get('token');
		post('vendor.post', data, (e, r) => {
			if (r) {
				this.setState({
					success: true
				})
			} else {
				handleError(e)
			}
		})
	}


	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('vendor.fetch', (e, r) => {
				if (r) {
					this.setState({
						...r,
						loading: false
					})
				} else {
					this.setState({
						error: e.response.data.error,
						loading: false
					})
				}
			}, { token })
		}
	}


	render() {
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						Vendor form submitted successfully!
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
						height={250}
						width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading survey
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		return (
			<div id="product-page">
				<div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{ marginTop: 20 }} className="integration-title">{this.state.title}</h1>
							<p className="integration-desc">{this.state.description}</p>
						</div>
					</div>
				</div>
				<div style={{ background: 'var(--background-color)', minHeight: '90vh' }} className="ptb-70 ptb-40">
					<div className="container-size">
						<div className="flex-wrap">
							<div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
								<div style={{ marginBottom: 30 }}>
									<h2 style={{
										fontSize: 25,
										color: "#454545",
										fontWeight: 700
									}}>Basic Details</h2>
									<p style={{
										marginBottom: 20,
										fontSize: 13,
										color: "#898989",
										marginTop: 4
									}}>Please fill the details below:-</p>
									<Input type="text" ref={ref => this.name = ref} label="Name" placeholder="" />
									<Input type="text" ref={ref => this.tradeName = ref} label="Trade Name" placeholder="" />
									<Input type="text" ref={ref => this.mobile = ref} label="Mobile Number" placeholder="" />
								</div>
								<div style={{ marginBottom: 30 }}>
									<h2 style={{
										fontSize: 25,
										color: "#454545",
										fontWeight: 700
									}}>Address Information</h2>
									<p style={{
										marginBottom: 20,
										fontSize: 13,
										color: "#898989",
										marginTop: 4
									}}>Please fill the details below:-</p>
									<Input type="text" ref={ref => this.address = ref} label="Address" placeholder="" />
									<Input type="text" ref={ref => this.city = ref} label="City Name" placeholder="" />
									<Input type="text" ref={ref => this.pincode = ref} label="Pin Code" placeholder="" />
								</div>
								<div style={{ marginBottom: 30 }}>
									<h2 style={{
										fontSize: 25,
										color: "#454545",
										fontWeight: 700
									}}>Taxation Information</h2>
									<p style={{
										marginBottom: 20,
										fontSize: 13,
										color: "#898989",
										marginTop: 4
									}}>Please fill the details below:-</p>
									<Input type="text" ref={ref => this.gstin = ref} label="Gstin" placeholder="" />
									<Input type="text" ref={ref => this.pan = ref} label="Pan" placeholder="" />
									<Input type="text" ref={ref => this.msme = ref} label="Msme" placeholder="" />
								</div>
								<div style={{ marginBottom: 30 }}>
									<h2 style={{
										fontSize: 25,
										color: "#454545",
										fontWeight: 700
									}}>Bank Details(Optional)</h2>
									<p style={{
										marginBottom: 20,
										fontSize: 13,
										color: "#898989",
										marginTop: 4
									}}>Please fill the details below:-</p>
									<Input type="text" ref={ref => this.bank = ref} label="Bank Name" placeholder="" />
									<Input type="text" ref={ref => this.ifsc = ref} label="Ifsc Code" placeholder="" />
									<Input type="text" ref={ref => this.account = ref} label="Account Number" placeholder="" />
								</div>
								<div style={{
									display: this.state.forms.length > 0 ? 'block' : 'none'
								}}>
									<h2 style={{
										fontSize: 25,
										color: "#454545",
										fontWeight: 700
									}}>Custom Fields</h2>
									<p style={{
										marginBottom: 20,
										fontSize: 13,
										color: "#898989",
										marginTop: 4
									}}>Please fill the details below:-</p>
									{
										this.state.forms.map(item => {
											if (item.type === "TEXT") {
												return (
													<Input type="text" key={item.id} ref={ref => this[item.id] = ref} label={item.name} placeholder={item.placeholder} />
												)
											} else if (item.type === "EMAIL") {
												return (
													<Input type="email" key={item.id} ref={ref => this[item.id] = ref} label={item.name} placeholder={item.placeholder} />
												)
											} else if (item.type === "NUMBER") {
												return (
													<Input type="number" key={item.id} ref={ref => this[item.id] = ref} label={item.name} placeholder={item.placeholder} />
												)
											} else if (item.type === "RATING") {
												return (
													<Rating key={item.id} ref={ref => this[item.id] = ref} label={item.name} />
												)
											}
											else if (item.type === "SELECT" && item.values.length < 5) {
												return (
													<Select key={item.id} items={item.values} ref={ref => this[item.id] = ref} label={item.name} />
												)
											}
											else if (item.type === "SELECT") {
												let value = item.values.map((item) => {
													item.label = item.key;
													return item
												})
												return (
													<DropDown key={item.id} items={value} ref={ref => this[item.id] = ref} label={item.name} />
												)
											}
											else if (item.type === "FILE") {
												return (
													<FileUpload label={item.name} ref={ref => this[item.id] = ref} />
												)
											}
											else if (item.type === "DATE") {
												return (
													<Calendar
														className="departure_date"
														title="Return Date"
														label={item.name}
														ref={ref => this[item.id] = ref}

													/>
												)
											}
											else if (item.type === "TEXTAREA") {
												return (
													<TextArea label={item.name} ref={ref => this[item.id] = ref} />

												)
											}
											return null

										})
									}
								</div>
								<p style={{
									marginTop: 20,
									color: "white",
									padding: 15,
									cursor: 'pointer',
									fontWeight: 700,
									textAlign: 'center',
									background: '#6367FA'
								}} onClick={() => {
									this.formSubmit()
								}}>
									Submit Form
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default Index
