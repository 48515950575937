import React from 'react'

class Input extends React.Component {

	render() {
		return (
			<div style={{
				display:'flex',
				marginTop:this.props.top
			}}>
				<span style={{
					color:"white",
					fontSize:14,
					marginTop:2
				}}>{this.props.user?`~ ${this.props.user}$ `:"~ root# "}</span>
				<input  onKeyDown={e=>{
					if (e.key === 'Enter') {
						let name = this._input.value;
						if(name){
							this.props.item.action(name)
						}
					}
				}} ref={ref=>this._input=ref}  autoFocus={true} className="blink" type={this.props.item.types?this.props.item.types:"text"} style={{
					background:'transparent',
					border:'none',
					color:'white',
					fontSize:14,
					paddingLeft:5,
					fontWeight:400,
					paddingRight:5,
					display:'inlineBlock'
				}}/>

			</div>
		)
	}

}

export default Input
