import React from 'react'


class TaxInformation extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			change: false
		}
	}

	render() {
		let { data } = this.props;
		let { taxInformation } = data;


		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
				<div style={{ width: 450, borderTop: '2px solid #888', paddingTop: 18, paddingBottom: 18 }}>
					<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
						<p style={{ marginRight: 12, width: 150, fontSize: 15 }}>CGST:</p>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
							INR {taxInformation.cgst}
							<div onClick={(e) => {
								e.stopPropagation();
								this.setState({
									change: {
										key: 'cgst',
										value: taxInformation.cgst
									}
								})
							}} className="penciledit"
								style={{ marginLeft: '10px', cursor: 'pointer', height: 14, position: 'relative', zIndex: 3 }} />
						</div>
					</div>
					<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
						<p style={{ marginRight: 12, width: 150, fontSize: 15 }}>SGST:</p>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
							INR {taxInformation.sgst}
							<div onClick={(e) => {
								e.stopPropagation();
								this.setState({
									change: {
										key: 'sgst',
										value: taxInformation.sgst
									}
								})
							}} className="penciledit"
								style={{ marginLeft: '10px', cursor: 'pointer', height: 14, position: 'relative', zIndex: 3 }} />

						</div>
					</div>
					<div style={{ fontSize: 17, padding: 8, borderBottom: '1px solid #efefef', display: 'flex' }}>
						<p style={{ marginRight: 12, width: 150, fontSize: 15 }}>IGST:</p>
						<div style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}>
							INR {taxInformation.igst}
							<div onClick={(e) => {
								e.stopPropagation();
								this.setState({
									change: {
										key: 'igst',
										value: taxInformation.igst
									}
								})
							}} className="penciledit"
								style={{ marginLeft: '10px', cursor: 'pointer', height: 14, width: 10, position: 'relative', zIndex: 3 }} />

						</div>
					</div>
				</div>

			
			</div>
		)
	}

}

export default TaxInformation
