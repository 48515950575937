import React from 'react';


class SingleComparisionDesktop extends React.Component {


    render() {
        let item = this.props.item;

        let firstclass = item.first_available === "yes" ? "yes" : "no";
        let second_available = item.second_available === "yes"? "yes" : "no";
        let combineClass = firstclass + "-" + second_available;

        if(item.type === "Title") {
            return(
                <div  className = "feature-item  flex-v-center">
                    <p className = "feature-item-one color-text-light font-700">{item.name}</p>
                    <p  className = "feature-item-two color-text-light font-700">{item.desc}</p>
                    <p className = "feature-item-three font-700">Concur</p>
                    <p className = "feature-item-four font-700">Eka</p>
                </div>
            )
        }

        return (
            <div className = {`feature-item flex-v-center ${combineClass}`}>
                 <p className = "feature-item-one feature-item-single font-700">{item.name}</p>
                 <div className = "feature-item-two color-text-light">
                    <p style = {{width:'70%'}}>{item.desc}</p>
                 </div>
                 <div  className = {`feature-item-three`}>
                    <div className = {`${item.first_available === "yes" ? "tick" : "cross"}`}/>
                 </div>
                 <div className = {`feature-item-four `}>
                    <div className = {`${item.second_available === "yes" ? "tick" : "cross"}`}></div>
                 </div>
            </div>
        )
    }
}

class SingleComparisionMobile extends React.Component {
    render() {
       let item = this.props.item;
       let firstclass = item.first_available === "yes" ? "yes" : "no";
       let second_available = item.second_available === "yes"? "yes" : "no";
       let combineClass = firstclass + "-" + second_available;

       if(item.type === "Title") {
        return(
            <div>
                <h2 className = "mb-20 ml-15">Feautres</h2>
            </div>
        )
    } 

       
        return (
            <div className = {`col-xs-12 feature-item-mobile vertical ${combineClass}`}>
                <div className = "vertical feature-mobile-single">
                    <p className = "font-600">{item.name}</p>
                    <p className = "mt-10 fs-14">{item.desc}</p>
                </div>
                <div className = "horizontal mt-15 flex-v-center">
                    <div className = "horizontal">
                        <p className = "font-700">{item.mobile_first}</p>
                        <div className= {`ml-10 ${item.first_available === "yes" ? "single-tick" : "single-cross"}`}/>
                    </div>
                    <div className = "horizontal ml-10">
                        <p className = "font-700">{item.mobile_second}</p>
                        <div  className= {`${item.second_available === "yes" ? "single-tick" : "single-cross"} ml-10`} />
                    </div>
                </div>
            </div>
        );
    }
}

export { SingleComparisionDesktop,SingleComparisionMobile}