class Presentor {

	constructor(Model) {
		this.Model = Model;
	}
	setView(View) {
		this.View = View;
	}

	getBlogs = () => {
		this.Model.getBlogs({
			page: this.View.getPage(),
			limit: 100
		}, (err, res) => {
			if (res) {
				this.View.successfullyGetValue(res.blogs)
			}
		})

	}
	getBlogsWithCategory = (id) => {
		this.Model.getBlogsWithCategory({
			page: this.View.getPage(),
			limit: 10,
			category: id
		}, (err, res) => {
			if (res) {
				this.View.getBlogs(res.blogs)
			}
		})
	}
	getCategories = () => {
		this.Model.getCategories((err, res) => {
			if (res) {
				this.View.getCategoriesSuccessfully(res.blogCategories)
			}
		})
	}

	getBlogsWithCategory = (id) => {
		this.Model.getBlogsWithCategory({
			page: this.View.getPage(),
			limit: 10,
			category: id
		}, (err, res) => {
			if (res) {
				this.View.getBlogsWithCategorySuccessfully(res.blogs)
			}
		})
	}

	getAllBlogs = () => {
		this.Model.getBlogs({
			page: 1,
			limit: 20
		}, (err, res) => {
			if (res) {
				this.View.successfullyGetValueForRecent(res.blogs)
			}
		})

	}
}


export default Presentor
