import React from 'react'
import Lottie from 'react-lottie';
import {get} from "../../../Model/Network/Config/Axios";


class Index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: undefined,
			forms: [],
			title: "",
			description: "",
		}
	}

	componentDidMount() {
		this.getToken();
	}

	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('collection.fetch', (e, r) => {
				if (r) {
					this.setState({
						...r,
						loading: false
					})
				}else {
					this.setState({
						error:e.response.data.error,
						loading: false
					})
				}
			}, {token})
		}
	}


	render() {
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
							height={250}
							width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
							height={250}
							width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading survey
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		return (
			<div id="product-page">
				<div style={{borderBottom: '2px dashed var(--primary-color)'}} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{marginTop:20}} className="integration-title">{this.state.title}</h1>
							<p className="integration-desc">{this.state.description}</p>
						</div>
					</div>
				</div>
				<div style={{background: 'var(--background-color)',minHeight:'90vh'}} className="ptb-70 ptb-40">
					<div className="container-size"  style={{
						minHeight:'50vh',
						display:'flex',
						alignItems:'center',
						justifyContent:'center'
					}}>
						<div style={{
							padding:30,
							width:650,
							background:'white',
							border:'1px solid #dedede',
							boxShadow:'2px 2px 1px rgba(1,1,1,.05)',
							display:'flex',
							alignItems:'center',
							flexDirection:'column',
							justifyContent:'center'
						}}>
							<h1 style={{marginTop:20,fontSize:23}} className="integration-title">{this.state.title}</h1>
							<p style={{fontSize:15}} className="integration-desc">{this.state.description}</p>
							<p onClick={event => {

							}} style={{
								background:"#6367FA",
								height:50,
								textAlign:'center',
								padding:15,
								color:"#ffffff",
								cursor:'pointer',
								marginTop:30,
								width:'100%'
							}}>
								Pay INR {this.state.amount}
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default Index
