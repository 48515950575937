import React from 'react';
import '../index.css';

class BasicDetails extends React.Component {

	ownerDetails = () => {
		let { owner } = this.props.data;
		return (
			<React.Fragment>
				{
					owner.values.map(item => (
						<div className="border-box" key={item.key} style={{ display: 'flex' }}>
							<p style={{
								fontWeight: 600,
								width: 150
							}}>{item.key}</p>
							<p>{item.value}</p>
						</div>
					))
				}
			</React.Fragment>
		)
	}

	vendorDetails = () => {
		let { vendor } = this.props.data;
		return (
			<div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Vendor Name </p>
					<p style={{}}>{vendor.name}</p>
				</div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Vendor Email </p>
					<p style={{}}>{vendor.email}</p>
				</div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Vendor Mobile </p>
					<p style={{}}>{vendor.mobile}</p>
				</div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Vendor Bank Name </p>
					<p style={{}}>{vendor.bank ?vendor.bank.name:'NA'}</p>
				</div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Account Number </p>
					<p style={{}}>{vendor.bank ?vendor.bank.account:'NA'}</p>
				</div>
				<div className="border-box" style={{ display: 'flex' }}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Ifsc Code </p>
					<p style={{}}>{vendor.bank ?vendor.bank.ifsc:'NA'}</p>
				</div>

			</div>
		)
	}

	address = () => {
		let { address } = this.props.data;
		return (
			<>
				<div className="border-box" style={{
					display: 'flex'
				}}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Address </p>
					<p style={{
						fontWeight: 500,
					}}>{address.address}</p>
				</div>
				<div className="border-box" style={{
					display: 'flex'
				}}>
					<p style={{
						fontWeight: 600,
						width: 150
					}}>Gstin </p>
					<p style={{
						fontWeight: 500,
					}}>{address.gstin}</p>
				</div>
			</>
		)
	}



	render() {
		return (
			<div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					fontSize:11,
					padding:8,
					background:"#f2f4f8",
					border:'1px solid #dedede',
					color: 'var(--light-color)'
				}}>
					<div>Vendor Details</div>
				</div>
				{this.vendorDetails()}

				<div style={{
					marginTop: 30,
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					fontSize:11,
					padding:8,
					background:"#f2f4f8",
					border:'1px solid #dedede',
					color: 'var(--light-color)'
				}}>
					Address Information
				</div>
				{
					this.address()
				}

				<div style={{
					marginTop: 30,
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					fontSize:11,
					background:"#f2f4f8",
					padding:8,
					border:'1px solid #dedede',
					color: 'var(--light-color)'
				}}>
					Owner Details
				</div>
				{this.ownerDetails()}
			</div>
		)
	}

}

export default BasicDetails
