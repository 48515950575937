import React from 'react';
import Lottie from 'react-lottie';
import { LineHeader } from '../../Common/LineHeader';
import { get, post } from "../../../Model/Network/Config/Axios";
import Timeline from './InvoiceDetails/Timeline';
import BasicDetails from './InvoiceDetails/BasicDetails';
import CustomFields from './InvoiceDetails/CustomFields';
import Attachments from './InvoiceDetails/Attachments';
import Format from './InvoiceDetails/Format'
import { Modal } from '../../Website/Common/Modal/Modal';
import { handleError, showToast } from '../../Website/Toast/ToastCallback';
import { Input } from '../Survey/Fields/Input';
import { Rating } from '../Survey/Fields/Rating';
import { Select } from '../Survey/Fields/Select';
import { DropDown } from '../Survey/Fields/DropDown';
import { Calendar } from '../Survey/Fields/Calendar';
import { TextArea } from '../Survey/Fields/TextArea';
import {File as FileUpload} from '../Survey/Fields/FileUpload';

class Index extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			success:false,
			loading:true,
			title:'hi',
			description:'hi',
			tab:'format',
			data:{},
			forms:this.props.forms ? this.props.forms:[]
		}
	}


	componentDidMount() {
		this.getToken()
	}

	getToken = () => {
		
		let token = this.props.token;
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('task.invoice', (e, r) => {
				if (r) {
					let errorCode = r.error;
					delete r.error;
					this.setState({
						data:r,
						errorCode,
						loading: false
					})
				} else {
					this.setState({
						error: e.response.data.error,
						loading: false
					})
				}
			}, { token })
		}
	}


	formSubmit() {
		var anyRequired = undefined
		let forms = {};
		this.state.forms.forEach((item) => {
			let value = this[item.id].getValue();
			if (!value && item.required) {
				anyRequired = item.name;
			} else {
				forms[item.id] = value;
			}
		})
		if (anyRequired) {
			showToast({
				type: 'error',
				message: "Please fill the " + anyRequired
			})
			return;
		}

		let data = {
			forms
		}
		
		data.token = this.props.token
		post('task.post', data, (e, r) => {
			if (r) {
				this.setState({
					success: true
				})
			} else {
				handleError(e)
			}
		})
	}


	mapItem = (item, index) => {
		return (
			<div key={index} style={{
				backgroundColor: 'rgb(244, 244, 244)',
				padding: '5px 12px',
				marginRight: 20,
				borderRadius: 32,
				minWidth: 150,
				paddingLeft: 15,
				display: 'flex',
				alignItems: 'center',
				fontSize: 11
			}}>
				<p style={{
					color: 'var(--text-light-color)'
				}}>{item.key} :</p>
				<p style={{
					marginLeft: 5,
					fontWeight: 700
				}}>{item.value ? item.value : "NA"}</p>
			</div>
		)
	}


	render() {
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
						height={250}
						width={250}
					/>
				</div>
			)
		}
		let data = this.state.data
		return(
			<div>
				<div>
				 <div className="container-size" style ={{
						backgroundColor:'#fff',
						padding:20
				 }}>
				 <p style={{
					fontSize: 25,
					fontWeight: 800,
					color: "var(--text-color)"
				}}>
					{
						data.invoice.counterId
					}&nbsp;/&nbsp;
					{
						data.invoice.invoiceId
					}
				</p>
				<p style={{
					color: "#898989",
					fontSize: 11,
					marginBottom: 10
				}}>
					Invoice submitted of INR {data.invoice.amount} by {data.vendor.name}.
				</p>

				 <div style={{
					display: 'flex',
					flexWrap: 'wrap',
					marginTop: 10,
					marginBottom: 10
					}}>
					{
						data.chips.map(this.mapItem)
					}
					</div>
					<LineHeader items={[
					{
						name: 'Invoice Format',
						active: this.state.tab === "format",
						click: () => {
							this.setState({
								...this.state,
								tab: "format"
							})
						}
					},
					{
						name: 'Basic Details',
						active: this.state.tab === "basic",
						click: () => {
							this.setState({
								...this.state,
								tab: "basic"
							})
						}
					},
					{
						name: 'Custom Fields',
						active: this.state.tab === "fields",
						click: () => {
							this.setState({
								...this.state,
								tab: "fields"
							})
						}
					},
					
					{
						name: 'Audit Trail',
						active: this.state.tab === "audit",
						click: () => {
							this.setState({
								...this.state,
								tab: "audit"
							})
						}
					},
					{
						name: 'Attachments',
						active: this.state.tab === "attachments",
						click: () => {
							this.setState({
								...this.state,
								tab: "attachments"
							})
						}
					}
					]} />

				  <div style ={{marginTop:50}}>
				  	{
						this.state.tab === "audit" ?
							<Timeline data={data}  {...this.props} timeline={data.timelines} /> : undefined
					}
					{
						this.state.tab === "basic" ?
							<BasicDetails  {...this.props} data={data} /> : undefined
					}
					
					{
						this.state.tab === "format" ?
							<Format data={data} /> : undefined
					}
					{
						this.state.tab === "fields" ?
							<CustomFields invoice={data.invoice}
								tags={data.tags}  {...this.props} /> : undefined
					}
					{
						this.state.tab === "attachments" ?
							<Attachments  {...this.props}
								attachments={data.invoice.files} /> : undefined
					}

				  </div>

				  <div>
				  <div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								marginTop: 20,
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
								<h2 style={{
									fontSize: 25,
									color: "#454545",
									fontWeight: 700
								}}>Custom Fields</h2>
								<p style={{
									marginBottom: 20,
									fontSize: 13,
									color: "#898989",
									marginTop: 4
								}}>Please fill the details below:-</p>
								{
									this.state.forms.map(item => {
										if (item.type === "TEXT") {
											return (
												<Input type="text" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "EMAIL") {
											return (
												<Input type="email" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "NUMBER") {
											return (
												<Input type="number" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "RATING") {
											return (
												<Rating key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} />
											)
										} else if (item.type === "SELECT" && item.values.length < 5) {
											return (
												<Select key={item.id} items={item.values}
													ref={ref => this[item.id] = ref} label={item.name} />
											)
										} else if (item.type === "SELECT") {
											let value = item.values.map((item) => {
												item.label = item.key;
												return item
											})
											return (
												<DropDown key={item.id} items={value} ref={ref => this[item.id] = ref}
													label={item.name} />
											)
										} else if (item.type === "FILE") {
											return (
												<FileUpload label={item.name} ref={ref => this[item.id] = ref} />
											)
										} else if (item.type === "DATE") {
											return (
												<Calendar
													className="departure_date"
													title="Return Date"
													label={item.name}
													ref={ref => this[item.id] = ref}

												/>
											)
										} else if (item.type === "TEXTAREA") {
											return (
												<TextArea placeholder={item.name} label={item.name}
													ref={ref => this[item.id] = ref} />

											)
										}
										return null

									})
							}
						</div>
				  </div>
				  <p style={{
							marginTop: 20,
							color: "white",
									padding: 15,
									flex: 1,
									cursor: 'pointer',
									fontWeight: 700,
									textAlign: 'center',
									background: '#6367FA'
								}} onClick={() => {
									this.formSubmit()
								}}>
									Submit Task
								</p>
								<div style={{
									display: 'flex',
									flexDirection: 'row'
								}}>
									<p style={{
										marginTop: 12,
										color: "white",
										padding: 15,
										flex: 1,
										cursor: 'pointer',
										fontWeight: 700,
										textAlign: 'center',
										background: '#fa736b'
									}} onClick={() => {
										this.setState({
											...this.state,
											cancel: true
										})
									}}>
										Decline Task
									</p>
									<p style={{
										marginTop: 12,
										color: "white",
										padding: 15,
										marginLeft: 12,
										flex: 1,
										cursor: 'pointer',
										fontWeight: 700,
										textAlign: 'center',
										background: '#3c3c3c'
									}} onClick={() => {
										this.setState({
											...this.state,
											revert: true
										})
									}}>
										Revert Back
									</p>
								</div>
				  


				</div>
			</div>

			<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName="Cancel Task"
					des="Please provide the remarks to cancel this task"
					show={this.state.cancel} onSave={() => {
						let remarks = this.__remarks.getValue();
						if (!remarks) {
							showToast({
								type: 'error',
								message: "Please enter a valid remarks"
							})
							return;
						}
						let data = {
							remarks
						}
						
						data.token = this.props.token;
						post('task.cancel', data, (e, r) => {
							if (r) {
								this.setState({
									success: "Task cancelled successfully!!"
								})
							} else {
								handleError(e)
							}
						})
					}} title="Cancel Task" close={() => {
						this.setState({
							...this.state,
							cancel: undefined
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<Input label="Cancel Remarks" placeholder="Enter remarks" ref={ref => this.__remarks = ref} />
					</div>
				</Modal>
				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName="Revert Task"
					des="Please provide the remarks to revert this task"
					show={this.state.revert} onSave={() => {
						let remarks = this.remarks.getValue();
						if (!remarks) {
							showToast({
								type: 'error',
								message: "Please enter a valid remarks"
							})
							return;
						}
						let data = {
							remarks
						}
						
						data.token = this.props.token;
						post('task.back', data, (e, r) => {
							if (r) {
								this.setState({
									success: "Task cancelled successfully!!"
								})
							} else {
								handleError(e)
							}
						})
					}} title="Revert Task" close={() => {
						this.setState({
							...this.state,
							revert: undefined
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<Input label="Cancel Remarks" placeholder="Enter remarks" ref={ref => this.remarks = ref} />
					</div>
				</Modal>
				
			</div>
		)
	}
}

export default Index