import React from 'react'

class Answer extends React.Component {

	render() {
		return (
			<div style={{
				display: 'flex',
				marginTop:this.props.top
			}}>
				<span style={{
					color: "white",
					fontSize: 14,
					marginTop: 2
				}}>{this.props.user ? `~ ${this.props.user}$ ` : "~ root# "}</span>
				<p style={{
					color: 'white',
					...this.props.css,
					display: 'inline-block',
					paddingLeft: 5,
					fontSize:14
				}}>{this.props.item.text}</p>
			</div>
		)
	}

}


export default Answer
