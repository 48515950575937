import React from 'react'
import Props from 'prop-types'

class Select extends React.Component {

	state = {
		value: undefined
	};
	componentDidMount() {
		if (this.props.ref) {
			this.props.ref(this);
		}
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}
	getValue = () => {
		return this.state.value
	}

	render() {
		return (
			<div>
				<p style={{
					fontSize: 15,
					color: "#676767",
				}}>
					{this.props.label} :-
				</p>
				<div className="select-container" style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: 20,
					marginTop: 18
				}}>
					{
						this.props.items.map(item => {
							return (
								<div onClick={event => {
									this.setState({
										value: item.key
									})
								}} style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 10,
									cursor: 'pointer'
								}} key={item.key}>
									<div style={item.value === this.state.value ? {
										background: "#6367FA",
										width: 12,
										height: 12,
										marginTop: 3,
										borderRadius: 6
									} : {
											border: '2px solid #eeeeee',
											width: 12,
											height: 12,
											marginTop: 3,
											borderRadius: 6
										}} />
									<p style={{
										fontSize: 15,
										marginLeft: 8,
										color: "#676767",
									}}>
										{item.value}
									</p>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}

}

Select.propTypes = {
	label: Props.string.isRequired,
	items: Props.array.isRequired
}

export {
	Select
}
