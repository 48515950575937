import React from 'react';
import { footerData, socialData } from './data';
import Eka from '../../../../Images/Common/eka.png';
import './index.css';
import Arrow from './arrow.png';
import { Link } from 'react-router-dom';
import { Images } from '../../Images/Image';

const Footer = (props) => {


	const mapItem = (item, index) => {
		if (item.key === "title") {
			return (
				<li key={index} className="font-700 mb-15 fs-19">
					{item.value}
				</li>
			)
		}
		return (
			<Link key={index} to={item.path} style={{ marginLeft: 0 }}>
				<li key={index} className="mb-15 fs-14 text-hover color-text-light font-500">
					{item.value}
				</li>
			</Link>
		)
	}


	const Item = (item, index) => {
		return (
			<div key={index} className="footer-line" style={{ width: 190 }}>
				<ul className="vertical" style={{ listStyle: 'none', margin: 0, padding: 0 }}>
					{item.map(mapItem)}
				</ul>
			</div>
		)
	}

	const mapSocial = (item, index) => {
		return (
			<a rel="noopener noreferrer" target="_blank" href={item.url} key={index}
				className={`${item.cname} ml-20 footer-logo-item`}>{""}</a>
		)
	}

	return (
		<div className="pull-70" style={{
			backgroundColor: 'var(--background-color)',
			position: 'relative',

		}}>
			<div className="push--125">
				<div className="footer-request" style={{
					position: 'relative'
				}}>
					<p className="fs-33 font-700 color-white">
						Request Early Access
					</p>
					<p className="fs-19 color-white" style={{ opacity: '.5', marginTop: 22 }}>
						Start Automating
					</p>
					<div className="footer-pattern1" />
					{
						props.onDemo ?
							<div onClick={props.onDemo} style={{ cursor: 'pointer', marginTop: 33, marginLeft: 0 }}
								className="horizontal">
								<p className="fs-19 color-white font-600" style={{}}>
									Request a demo
								</p>
								<div className="arrow-circle ml-15 flex-h-center flex-v-center">
									<img src={Arrow} alt="" style={{ height: 15, width: 15 }} />
								</div>
							</div> :
							<Link to="/demo" style={{ cursor: 'pointer', marginTop: 33, marginLeft: 0 }} className="horizontal">
								<p className="fs-19 color-white font-600" style={{}}>
									Request a demo
								</p>
								<div className="arrow-circle ml-15 flex-h-center flex-v-center">
									<img src={Arrow} alt="" style={{ height: 15, width: 15 }} />
								</div>
							</Link>
					}
					<div className="footer-pattern" />
					<div className='footer-image-layout'>
						<img src={Images.Saving4} alt="" className="footer-image" />
					</div>
				</div>
			</div>
			<footer className="footer" style={{ background: 'white', paddingBottom: 15, paddingTop: 200 }}>
				<div className="container-size ">
					<div className="horizontal horizontal-wrap">
						<div className="">
							<Link to="/">
								<img className="eka-logo" src={Eka} alt="Travel and Expense Solution" />
							</Link>
						</div>
						<div className="footer-content">
							{footerData.map(Item)}
							<div style={{ flex: 1 }} />
						</div>
					</div>
					<div className="footer-logo">
						<p style={{ color: 'var(--text-color-2)', fontSize: 13 }}>Copyright ©2020 SKPL ANTREPRIZ SOLUTIONS PRIVATE LIMITED. All rights
							reserved</p>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{socialData.map(mapSocial)}
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export { Footer }
