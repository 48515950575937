import React from 'react';
import { get, post } from '../../../Model/Network/Config/Axios';
import Lottie from 'react-lottie';
import Step from "./Step";
import { HalfModal } from '../../Website/Common/Modal/HalfModal';
import { KeyValue } from '../../Website/Common/KeyValue/KeyValue';


class Index extends React.Component {


	constructor(props) {

		super(props);
		this.state = {
			loading: true,
			error: undefined,
			form: {},
			step: 0,
			data: undefined,
			allData: {},
			questionList: [

			],
			modal: false
		}
	}


	componentDidMount() {
		this.init();
		console.log('hi')
	}

	getTokens = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		return params.get('token');
	}

	init = () => {
		let token = this.getTokens();
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('survey.fetch', (e, r) => {
				if (r) {
					this.setState({
						data: {
							...r,
							steps: r.app ? r.app.steps : []
						},
						loading: false
					})
				} else {
					this.setState({
						error: e.response.data.error,
						loading: false
					})
				}
			}, { token })
		}
	}

	submit = (data) => {
		let forms = {}
		Object.keys(data).forEach(value => {
			forms[data[value].id] = data[value].value
		})


		post('survey.post', { forms, token: this.getTokens() }, (e, r) => {
			if (r) {
				this.setState({
					...this.state,
					success: true,
					modal: false
				})

			} else {
				this.setState({
					error: e.response.data.error,
					loading: false,
					modal: false
				})
			}
		})
	}

	render() {
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true, autoplay: true, animationData: require('./Fields/assets/loading'),
					}} height={350} width={350} />
				</div>
			)
		}
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('./Fields/assets/success'),
					}}
						height={350}
						width={350}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						Survey submitted successfully!
					</p>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('./Fields/assets/error'),
					}}
						height={350}
						width={350}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading survey
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		console.log('hi karan');
		return (
			<div id="product-page">
				<div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{ marginTop: 20 }} className="integration-title">{this.state.data && this.state.data.name ? this.state.data.name : ''}</h1>
							<p className="integration-desc">Vendor Performance Evaluation Form</p>
						</div>
					</div>
				</div>
				<Step key={this.state.step} form={this.state.form} onBack={() => {
					this.setState({
						...this.state,
						step: this.state.step - 1
					})
				}}
					onReset={() => {
						this.init();
						this.setState({
							...this.state,
							form: {},
							step: 0,
							data: undefined,
							allData: {},
							loading: true
						})
					}}

					step={this.state.step} onSkip={() => {
						if (this.state.step + 2 > this.state.data.steps.length) {
							this.submit(this.state.form)
						} else {
							this.setState({
								...this.state,
								step: this.state.step + 1
							})
						}
					}} onNext={(alldata, data, question) => {
						let form = this.state.form;
						let questionList = this.state.questionList;
						questionList.push(...question)
						form = {
							...form, ...data
						}
						let allData = {
							...this.state.allData,
							...alldata,
						}

						if (this.state.step + 1 === this.state.data.steps.length) {
							this.setState({
								modal: form
							})
							//this.submit(form)
						} else {
							this.setState({
								...this.state,
								form,
								allData,
								step: this.state.step + 1,
								questionList
							})
						}
					}} token={this.getTokens()} data={this.state.data} />


				{
					this.state.modal ?
						<HalfModal
							title="Form Details"
							desc="Please check all the form details and submit"
							close={() => {
								this.setState({
									modal: false
								})
							}}
						>
							{this.state.questionList.map((item, index) => {
								return (
									<div style={{
										marginBottom: 10
									}}>
										<KeyValue key={index} value1={`Question ${index + 1} : ${item.name}`} value2={`Answer : ${item.value}`} />


									</div>
								)
							})}
							<div style={{
								position: 'fixed',
								borderTop: '1px solid #eeee',
								bottom: 0,
								left: 350,
								right: 0,
								padding: 10,
								zIndex: 10,
								background: '#fff'
							}}>
								<div onClick={() => {
									this.submit(this.state.modal);
								}} className="btn btn-portlet" style={{
									width: '100%',
									textAlign: 'center'
								}}>
									Submit
								</div>
							</div>
						</HalfModal> : null
				}

			</div>
		)
	}
}

export default Index;
