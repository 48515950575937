import React from 'react';
import './index.css';


class InputForm extends React.Component {

    state = {
        name:''
    }

    updator = val => {
		this.mRef.value = val;
		this.setState({
			...this.state,
			className: ""
		})
	}

	getValue = () => {
		return this.mRef.value;
	}

    
    render() {
        return (
            <div className = "inputform">
                <h2 style = {{color:'var(--text-color-3)'}} className = "font-900 input-title">{this.props.title} : </h2>
                <div className = "input-layout">
                        <input  type = {this.props.type} value = {this.props.value} onChange = {(e)=>{
                                this.props.onChange(e);
                          }} />
                </div>
            </div>
        );
    }
}


export { InputForm };

