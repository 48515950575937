import React from 'react';
import ConcurComparision from './Comparision-with-concur';
import FyleComparision from './Comparision-with-fyle';
import FinlyComparision from './Comparision-with-fnly';
import HappayComparision from './Comparision-with-happay';
import {Route, Switch} from 'react-router-dom';

class Index extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path = "/comparison" component = {ConcurComparision} exact />
                    <Route path = "/comparision/happay" component ={HappayComparision} exact />
                    <Route path = "/comparision/fyle" component = {FyleComparision} exact />
                    <Route path = "/comparision/finly" component = {FinlyComparision} exact />
                </Switch>
            </div>
        );
    }
}

export default Index;
