import React from 'react'
import Lottie from 'react-lottie';
import {get, post} from "../../../Model/Network/Config/Axios";
import renderHTML from 'react-render-html';


class index extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			files: [],
			additional:[],
			uploadmodal: false,
			stateLoading:false,
			buttonLoading:false,
			terms:undefined
		}
	}

	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('pr/fetch.offer', (e, r) => {
				if (r) {
					this.setState({
						...r,
						loading: false,
						...r.data
					})
				} else {
					if (e.response)
						this.setState({
							error: e.response.data.error,
							loading: false
						})
				}
			}, {token})
		}
	}

	componentDidMount() {
		this.getToken();
	}

	render() {
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
							height={250}
							width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
							height={250}
							width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
							height={250}
							width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading task
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		let additional = 0;
		this.state.additional.forEach(value => {
			additional = additional + value.tax  + value.base;
		})
		return (
			<div id="product-page">
				<div style={{borderBottom: '2px dashed var(--primary-color)'}} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{marginTop: 20}} className="integration-title">Accept Quotation</h1>
							<p className="integration-desc">Fill the below details to accept this quotation</p>
						</div>
					</div>
				</div>
				<div style={{background: 'var(--background-color)', minHeight: '80vh'}} className="ptb-70 ptb-40">
					<div className="container-size">
						<div className="flex-wrap">
							<div style={{
								background: 'white',
								border: '1px solid #dedede',
								boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
								padding: 40,
								width: '100%'
							}}>
								<p style={{
									color: "#454545",
									fontSize: 17,
									fontWeight: 600,
									marginBottom: 12,
									marginTop: 20
								}}>
									Line Items:
								</p>
								<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
									<div style={{flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
										Details
									</div>
									<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
										Max Qtty
									</div>
									<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
										Qtty
									</div>
									<div style={{flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede'}}>
										Unit Price
									</div>
									<div style={{flex: 1, fontSize: 13, padding: 12}}>
										Amount
									</div>
								</div>
								{
									this.state.items.map(item => {
										return (
											<div key={item.productId} style={{
												display: 'flex',
												background: "#f6f6f6",
												border: '1px solid #dedede'
											}}>
												<div style={{flex: 3, padding: 12, borderRight: '1px solid #dedede'}}>
													<p style={{
														fontSize: 15,
														color: "#454545"
													}}>
														{item.name}
													</p>
													<p style={{
														fontSize: 10,
														color: "#787878"
													}}>
														HSN : {item.hsn}
													</p>
												</div>
												<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
													{item.max}
												</div>
												<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
													{item.qtty}
												</div>
												<div style={{flex: 1, padding: 12, borderRight: '1px solid #dedede'}}>
													{item.price}
												</div>
												<div style={{flex: 1, padding: 12}}>
													{item.baseAmount}
												</div>
											</div>
										)
									})
								}
								<div style={{maxWidth: 400, marginLeft: "calc(100% - 400px)"}}>
									{
										this.state.additional.length>0 ?

									
									<p style={{
										color: "#454545",
										fontSize: 17,
										fontWeight: 600,
										marginTop: 33,
										marginBottom: 12
									}}>
										Additional Charges:
									</p>
									: null
								}
									{
										this.state.additional.map(item=>{
											return(
												<div key={item.name} style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
													<div style={{
														flex: 3,
														fontSize: 13,
														padding: 12,
														borderRight: '1px solid #dedede'
													}}>
														{item.name}
													</div>
													<div style={{
														flex: 1,
														fontSize: 13,
														padding: 12,
														borderRight: '1px solid #dedede'
													}}>
														{item.base}
													</div>
													<div style={{
														flex: 1,
														fontSize: 13,
														padding: 12,
														borderRight: '1px solid #dedede'
													}}>
														{item.tax}
													</div>
												</div>
											)
										})
									}
								</div>
								<div style={{maxWidth: 400, marginLeft: "calc(100% - 400px)"}}>
									<p style={{
										color: "#454545",
										fontSize: 17,
										fontWeight: 600,
										marginTop: 33,
										marginBottom: 12
									}}>
										Total Charges:
									</p>
									<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Base Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{this.state.base}
										</div>
									</div>
									<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Tax Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{this.state.tax}
										</div>
									</div>
									<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Additional Charges
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{additional}
										</div>
									</div>
									<div style={{display: 'flex', background: "#efefef", border: '1px solid #dedede'}}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Total
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{this.state.total}
										</div>
									</div>
								</div>
								<div style={{
									marginTop:70,
									marginBottom:-20,
								}}>
									<p>Read the above offer and take action:-</p>
									<div style={{
										display: 'flex',
										flexDirection: 'row'
									}}>
										<p style={{
											marginTop: 12,
											color: "white",
											padding: 15,
											flex: 1,
											cursor: 'pointer',
											fontWeight: 700,
											textAlign: 'center',
											background: '#6367FA'
										}} onClick={() => {
											const search = this.props.location.search; // could be '?foo=bar'
											const params = new URLSearchParams(search);
											const token = params.get('token');
											post('pr/offer/accept', {token}, (e, r) => {
												if (r) {
													this.setState({
														...r,
														success:"Action completed successfully"
													})
												}
											})
										}}>
											Accept Offer
										</p>
										<p style={{
											marginTop: 12,
											color: "white",
											padding: 15,
											marginLeft: 12,
											flex: 1,
											cursor: 'pointer',
											fontWeight: 700,
											textAlign: 'center',
											background: '#fa736b'
										}} onClick={() => {
											const search = this.props.location.search; // could be '?foo=bar'
											const params = new URLSearchParams(search);
											const token = params.get('token');
											post('pr/offer/decline', {token}, (e, r) => {
												if (r) {
													this.setState({
														...r,
														success:"Action completed successfully"
													})
												}
											})
										}}>
											Decline Offer
										</p>
									</div>
								</div>
							</div>
						</div>
						{
							this.state.terms?
								<div>
									<div style={{
										background: 'white',
										marginTop:20,
										border: '1px solid #dedede',
										boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
										padding: 40,
										width: '100%'
									}}>
										<p style={{
											color: "#454545",
											fontSize: 17,
											fontWeight: 600,
											marginBottom: 12,
											marginTop: 20
										}}>
											Terms & Conditions:
										</p>
										{
											renderHTML(this.state.terms)
										}
									</div>
								</div>:undefined
						}
					</div>
				</div>
			</div>
		)
	}

}

export default index
