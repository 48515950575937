import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';


class MapWrapper extends React.Component {



    state = {
        center: {
            lat: 28.4595,
            lng: 77.0266
        }
    }


    render() {
        return (
            <div style={{ width: '100%' }}>
                <Map google={this.props.google}
                    style={{
                        height: 200
                    }}
                    initialCenter={{
                        lat: 18.5434,
                        lng: 73.8390
                    }}
                >
                    <Marker onClick={() => { }}
                        name={'Current location'} />

                </Map>
            </div>


        );
    }
}



export default GoogleApiWrapper({
    apiKey: 'AIzaSyA3x8hJ6bjDti_PElUcmVjoy4KUuUuhiZo'
})(MapWrapper)
