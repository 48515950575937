import React from 'react'
import Lottie from 'react-lottie';
import Moment from 'moment';
import papa from 'papaparse';
import Pdf from './pdf.png'
import Excel from './excel.png';
import { get, post } from "../../../Model/Network/Config/Axios";
import { Modal } from "../../Website/Common/Modal/Modal";
import axios from "axios";
import { TextArea } from "../Survey/Fields/TextArea";
import renderHTML from 'react-render-html';
import { showToast } from "../../Website/Toast/ToastCallback";
import { Input } from "../Survey/Fields/Input";
import PastQuotation from './PastQuotation';
import { DownloadCsv } from '../../../Utils/Downloader';
import Trophy from './trophy.png'

class index extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			files: [],
			additional: [],
			uploadmodal: false,
			stateLoading: false,
			buttonLoading: false,
			terms: undefined,
			pastSubmission:[],
			inited : false,
			modal:false,
			upload:false,
			auction:{
				success:false
			},
			current : undefined
		}
	}

	auction = () => {
		if(this.state.auction && this.state.auction.success){
			return (
				<div style={{
					background:"#574fdf",
					padding:"12px 20px",
					textAlign:'center',
					marginTop:-4,
					color:'white',
					fontSize:13,
					fontWeight:600
				}}>
					This particular bid ends at {Moment(this.state.auction.time).format("DD MMM YYYY HH:MM a")}
				</div>
			)
		}
	}

	submit = () => {
		if (this.state.buttonLoading)
			return
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		let items = this.state.items;
		let id = this._id.getValue();

		let base = 0;
		let gst = 0;
		let total = 0;
		let lines = []
		let satisfied = true;
		items.forEach(i => {
			base = base + i.amount;
			let tax = i.taxPct / 100 * i.amount;
			gst = gst + tax;
			if (i.minQtty && i.qtty < i.minQtty) {
				satisfied = false;
			}
			lines.push(
				{
					...i,
					tax,
					baseAmount: i.amount,
					amount: i.amount + tax
				}
			)
		})
		if (!satisfied) {
			showToast({
				type: 'error',
				message: "Min qtty rule not satisfied"
			})
			return
		}
		total = base + gst;
		this.state.additional.forEach(value => {
			total = value.tax + total + value.base;
		})
		let data = {
			id,
			items: lines,
			base, gst, total,
			additional: this.state.additional,
			attachments: this.state.files,
			notes: this.note.getValue()
		}
		this.setState({
			...this.state,
			buttonLoading: true
		}, () => {
			post('pr/import', { token, data }, (e, r) => {
				if (r) {
					if(this.state.auction.success){
						showToast({
							type:'success',
							message:"Quotation posted successfully"
						})
						this.trigger()
						this.setState({
							loading: false,
							buttonLoading: false
						})
					}else{
						this.setState({
							success: true
						})
					}
				} else {
					if (e.response)
						alert(e.response.data.error)
					this.setState({
						loading: false,
						buttonLoading: false
					})
				}
			})
		})

	}


	trigger = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		get('pr/fetch', (e, r) => {
			if (r) {
				if(r.auction.success){
					setTimeout(this.reTrigger,5000)
				}
				let pastSubmission =[];
				let mItems = {};
				if(r.current) {
					let current = r.current;
					if(current){
						current.items.forEach(item=>{
							mItems[item.id] = {
								qtty : item.qtty,
								price:item.price,
								rank:item.rank
							}
						})
					}
					pastSubmission.push({...r.current})
				}
				if(r.past.length>0) {
					pastSubmission.push(...r.past);
				}
				this.setState({
					...r,
					loading: false,
					pastSubmission,
					items: r.items.map(item => {
						return (
							{
								...item,
								qtty: mItems[item.id]?mItems[item.id].qtty:0,
								max: item.qtty,
								amount: mItems[item.id]?mItems[item.id].price*mItems[item.id].qtty:0,
								price: mItems[item.id]?mItems[item.id].price:0
							}
						)
					})
				})
			} else {
				if (e.response)
					this.setState({
						error: e.response.data.error,
						loading: false
					})
			}
		}, { token })
	}

	reTrigger = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		get('pr/fetch', (e, r) => {
			if (r) {
				if(r.auction.success){
					setTimeout(this.reTrigger,5000)
				}
				let pastSubmission =[];
				if(r.current) {
					pastSubmission.push({...r.current})
				}
				if(r.past.length>0) {
					pastSubmission.push(...r.past);
				}
				let mItems = {}
				r.items.forEach(item=>{
					mItems[item.id] = item;
				})
				this.setState({
					...r,
					loading: false,
					pastSubmission,
					items: this.state.items.map(item => {
						return (
							{
								...item,
								maxPrice:mItems[item.id].maxPrice,
								minQtty:mItems[item.id].minQtty
							}
						)
					})
				})
			} else {
				if (e.response)
					this.setState({
						error: e.response.data.error,
						loading: false
					})
			}
		}, { token })
	}

	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			this.trigger()
		}
	}


	right = () => {
		return(
			<div>
				<p style ={{fontWeight:800,fontSize:20}}>Past Submission</p>
				<p style={{fontSize:13,marginTop:2,marginBottom:30}}>All the past submission visible here</p>
				{
					this.state.pastSubmission.length===0?
						<p style={{
							border:'1px solid #dedede',
							fontSize:13,
							color:"#555",
							padding:'9px 18px'
						}}>
							No past quotation found
						</p>:undefined
				}
				{
					this.state.pastSubmission.map((item,index)=>{
						return(
							<div style ={{
								border:'1px dashed #dedede',
								height:50,
								borderRadius:1,
								marginBottom:10,
								display:'flex',
								flex:1,
								alignItems:'center',
								padding:10
							}} key={index}>

								<div style ={{
									flex:1,
									fontSize:12
								}}>
									{item.quotationId?item.quotationId:'No quotation Id'}
									<p style ={{fontSize:8}}>{Moment(item.submittedAt).format('DD-MM-YYYY')}</p>
								</div>
								<div style={{
									flex:1,
									fontSize:11,
									textAlign:'center',
									fontWeight:700
								}}>
									INR {item.total}
								</div>

								<div style={{
									flex:1,
									textAlign:'right',
									textTransform:'uppercase',
									color:'var(--primary-color)',
									fontSize:12,
									fontWeight:700,
									cursor:'pointer',
									letterSpacing:0.5
								}} onClick={()=>{
									this.setState({
										...this.state,
										modal:item
									})
								}}>
									View
								</div>


							</div>
						)
					})
				}
			</div>
		)
	}

	componentDidMount() {
		this.getToken();
	}

	upload = () => {
		let file = this._file.files[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			this.setState({
				...this.state,
				stateLoading: true
			})
			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			})
				.then(res => {
					this.setState({
						...this.state,
						url: res.data.url,
						stateLoading: false
					})
				}).catch(err => {
					alert("Unable to upload file")
					this.setState({
						...this.state,
						stateLoading: false
					})
				})

		}
	}

	upload1 = () => {
		let file = this._file.files[0];

		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			this.setState({
				...this.state,
				stateLoading: true
			})
			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			})
				.then(res => {

					papa.parse(res.data.url,{
						download:true,
						delimiter: ",",	// auto-detect
						newline: "",
						header: true,
						transformHeader: undefined,
						dynamicTyping: false,
						preview: 0,
						encoding: "",
						worker: false,
						comments: false,
						complete:(result,file)=>{
							let items = [];

							result.data.forEach(val=>{
								items =this.state.items.map(i=>{
									if(i.id === +val.id && i.max>=+val.qtty) {
										return {
											...i,
											qtty: +val.qtty,
											price:+val.price,
											amount:+val.qtty*+val.price
										}
									} else {
										return {
											...i,
											qtty:i.max,
											price:+val.price,
											amount:i.max*+val.price
										}
									}
								})
							})

							this.setState({
								...this.state,
								items,
								upload:false
							})

						},
						delimitersToGuess:[',', '\t', '|']
					})

				}).catch(err => {
					alert("Unable to upload file")
					this.setState({
						...this.state,
						stateLoading: false
					})
				})

		}

	}

	downloadCsv = () => {
		let baseData = [[
			"id",
			"qtty",
			"price"
		]]

		this.state.items.forEach(val=>{
			baseData.push(
				[
					val.id,
					'',
					''
				]
			)
		})


		DownloadCsv('file.csv',baseData);


	}

	render() {
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 30
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
						height={250}
						width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading task
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		let items = this.state.items;
		let base = 0;
		let gst = 0;
		items.forEach(i => {
			base = base + i.amount;
			let tax = i.taxPct / 100 * i.amount;
			gst = gst + tax;
		})
		let total = base + gst;
		let additional = 0;
		this.state.additional.forEach(value => {
			additional = additional + value.tax + value.base;
			total = value.tax + total + value.base;
		})
		let current = this.state.current;
		let mItems = {};
		if(current){
			current.items.forEach(item=>{
				mItems[item.id] = {
					qtty : item.qtty,
					price:item.price,
					rank:item.rank
				}
			})
		}
		return (
			<div id="product-page">
				<div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{ marginTop: 20 }} className="integration-title">{
								this.state.auction.success?"Live Auction":"Submit Quotation"
							}</h1>
							<p className="integration-desc">Fill the below details to submit this quotation</p>
						</div>
					</div>
				</div>
				{
					this.auction()
				}
				<div style={{ background: 'var(--background-color)', minHeight: '80vh' }} className="ptb-40">
					<div className="container-size" style ={{display:"flex"}}>
						<div className="flex-wrap" style ={{flex:3}}>
							<div style={{
								background: 'white',
								border: '1px solid #dedede',
								boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
								padding: 40,
								width: '100%'
							}}>

								<div style ={{
									display:'flex',
									justifyContent:'space-between'
								}}>
									<p style={{
										color: "#454545",
										fontSize: 17,
										fontWeight: 600,
										marginBottom: 12,
										marginTop: 20
									}}>
										Basic Details:
									</p>
									<div style ={{display:'flex',alignItems:'center'}}>
										<div style ={{
											fontSize:14,
											fontWeight:'600',
											cursor:'pointer',
											backgroundColor:'var(--primary-color)',
											padding:"5px 10px",
											color:"#fff",
											borderRadius:2
										}} onClick={()=>{
											this.setState({
												...this.state,
												upload:true
											})
										}} >
											Upload Csv
										</div>
										<div style ={{
											fontSize:14,
											marginLeft:10,
											color:'var(--primary-color)',
											cursor:'pointer'
										}} onClick={this.downloadCsv}>
											Download Csv
										</div>
									</div>
								</div>

								<Input
									type="text"
									ref={ref => this._id = ref}
									placeholder={"Enter the reference id(optional)"}
									label="Reference Id" />
								<p style={{
									color: "#454545",
									fontSize: 17,
									fontWeight: 600,
									marginBottom: 12,
									marginTop: 20
								}}>
									Line Items:
								</p>
								<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
									{
										this.state.auction.success?
											<div style={{
											 	 width:60,
												borderRight: '1px solid #dedede'
											}}>

											</div>:undefined
									}
									<div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Details
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Max Qtty
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Min Qtty
									</div>
									<div style={{ flex: 1.5, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Qtty
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Max Price
									</div>
									<div style={{ flex: 1.5, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Unit Price
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12 }}>
										Amount
									</div>
								</div>
								{
									this.state.items.map(item => {
										return (
											<div key={item.productId} style={{
												display: 'flex',
												background: "#f6f6f6",
												border: '1px solid #dedede'
											}}>
												{
													this.state.auction.success?
														mItems[item.id]?<div style={{
															width:60,
															display:'flex',
															alignItems:'center',
															justifyContent:'center',
															borderRight: '1px solid #dedede'
														}}>
															<img alt="trophy" src={Trophy} className="trophy" style={{
																width:18,
																height:18
															}}/>
															<p style={{
																fontWeight:600,
																fontSize:23,
																marginLeft:6
															}}>
																{mItems[item.id].rank}
															</p>
														</div>:<div style={{
															width:60,
															display:'flex',
															alignItems:'center',
															justifyContent:'center',
															borderRight: '1px solid #dedede'
														}}/>:undefined
												}
												<div style={{ display:'flex', flex: 3, padding: 12, borderRight: '1px solid #dedede' }}>
													<div>
														<img src={item.image} alt="product" style={{
															width:40,
															height:40,
															objectFit:'cover',
															marginRight:12,
															border:'1px solid #dedede',
															padding:2,
															marginTop:4
														}}/>
													</div>
													<div>
														<p style={{
															fontSize: 17,
															color: "#454545",
															fontWeight:700
														}}>
															{item.name}
														</p>
														<p style={{
															fontSize: 11,
															color: "#787878"
														}}>
															Address : {item.address}
														</p>
														<p style={{
															fontSize: 11,
															color: "#787878"
														}}>
															HSN : {item.hsn}
														</p>
													</div>
												</div>
												<div style={{ flex: 1,display:'flex',alignItems:'center',justifyContent:'center', padding: 12, borderRight: '1px solid #dedede' }}>
													{item.max} {item.unit}
												</div>
												<div style={{ flex: 1,display:'flex',alignItems:'center',justifyContent:'center', padding: 12, borderRight: '1px solid #dedede' }}>
													{item.minQtty ? `${item.minQtty} ${item.unit}` : "-"}
												</div>
												<div style={{ overflow:'hidden', display:'flex',alignItems:'center',justifyContent:'center',flex: 1.5, padding: 12, borderRight: '1px solid #dedede' }}>
													<div style={{
														display:'flex'
													}}>
														<input type="number" onChange={e => {
															let value = e.target.value;
															if (value > item.max)
																value = item.max;
															value = parseInt(value, 10);
															let items = this.state.items.map(x => {
																if (item.id === x.id) {
																	return {
																		...x,
																		qtty: value,
																		amount: value * item.price
																	}
																} else {
																	return x;
																}
															})
															this.setState({
																...this.state,
																items
															})
														}} value={item.qtty?item.qtty:``} style={{
															border: '1px solid #dedede',
															fontSize: 13,
															padding:'6px 9px',
															fontWeight: 600,
															color: "#454545",
															background: "#f6f6f6",
															width: 75
														}} />
														<p style={{
															fontSize:13,
															fontWeight:700,
															padding:'12px 6px'
														}}>
															{item.unit}
														</p>
													</div>
												</div>
												<div style={{ flex: 1, padding: 12,display:'flex',alignItems:'center',justifyContent:'center', borderRight: '1px solid #dedede' }}>
													{item.maxPrice ? item.maxPrice : "-"}
												</div>
												<div style={{ overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center', flex: 1.5, padding: 12, borderRight: '1px solid #dedede' }}>
													<div style={{display:'flex'}}>

														<p style={{
															fontSize:13,
															fontWeight:700,
															padding:'12px 4px',
															paddingLeft:0,
															marginRight:8
														}}>
															INR
														</p>
														<input type="number" onChange={e => {
															let value = e.target.value;
															value = parseFloat(value);
															let max = item.maxPrice;
															if (!max) {
																max = 0;
															}
															if (max > 0 && value > max)
																value = max;
															let items = this.state.items.map(x => {
																if (item.id === x.id) {
																	return {
																		...x,
																		price: value,
																		amount: value * item.qtty
																	}
																} else {
																	return x;
																}
															})
															this.setState({
																...this.state,
																items
															})
														}} value={item.price?item.price:``} style={{
															border: '1px solid #dedede',
															fontSize: 13,
															padding:'6px 9px',
															fontWeight: 600,
															color: "#454545",
															background: "#f6f6f6",
															width: 75
														}} />
													</div>
												</div>
												<div style={{ flex: 1,display:'flex',alignItems:'center',justifyContent:'center', padding: 12 }}>
													{item.amount?item.amount:0}
												</div>
											</div>
										)
									})
								}

								<div style={{ maxWidth: 400, marginLeft: "calc(100% - 400px)" }}>
									<p style={{
										color: "#454545",
										fontSize: 17,
										fontWeight: 600,
										marginTop: 33,
										marginBottom: 12
									}}>
										Total Charges:
									</p>
									<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Base Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{base?base:0}
										</div>
									</div>
									<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Tax Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{gst?gst:0}
										</div>
									</div>

								</div>
								<p style={{
									color: "#454545",
									fontSize: 17,
									fontWeight: 600,
									marginTop: 33,
									marginBottom: 12
								}}>
									Attachments:
								</p>
								<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
									<div style={{ display: 'flex', flexWrap: 'wrap' }}>
										{
											this.state.files.map((item, index) => {
												return (
													<div key={index}>
														{
															item ? item.includes("pdf") ?
																<a rel="noopener noreferrer" href={item}
																	target="_blank"><img
																		src={Pdf}
																		alt="pdf icon" style={{
																			width: 120,
																			height: 120,
																			marginRight: 12,
																			border: '1px solid var(--border-color)'
																		}} /></a>
																: item.includes("csv") ?
																	<a rel="noopener noreferrer" href={item}
																		target="_blank"><img
																			src={Excel}
																			alt="pdf icon" style={{
																				width: 120,
																				height: 120,
																				marginRight: 12,
																				border: '1px solid var(--border-color)'
																			}} /></a>
																	: <img
																		src={item}
																		alt="pdf icon" style={{
																			width: 120,
																			height: 120,
																			marginRight: 12,
																			border: '1px solid var(--border-color)'
																		}} /> : null
														}
													</div>
												)
											})
										}
									</div>
									<div>
										<div onClick={() => {
											this.setState({
												uploadmodal: true
											})
										}} style={{
											width: 120,
											height: 120,
											borderRadius: 6,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											border: '1px dashed #dedede',
											cursor: 'pointer',
											marginLeft: 4,
										}}>
											<p style={{
												fontSize: 40,
												textAlign: 'center',
												color: 'var(--light-color)'
											}}>+</p>
											<p style={{
												fontSize: 11,
												textAlign: 'center',
												color: "var(--light-color)"
											}}>
												Add Attachment
											</p>
										</div>
									</div>
								</div>
								<div style={{ marginTop: 18 }}>
									<TextArea placeholder="Notes" label="Enter Notes(If Any)"
										ref={ref => this.note = ref} />
								</div>
								<p style={{
									marginTop: 20,
									color: "white",
									padding: 15,
									flex: 1,
									cursor: 'pointer',
									fontWeight: 700,
									textAlign: 'center',
									background: '#6367FA'
								}} onClick={this.submit}>
									{
										this.state.buttonLoading ? "Loading...." : current?"Update Quotation":"Submit Quotation"
									}
								</p>
							</div>

						</div>

						<div style ={{flex:1,marginLeft:40}}>
						   <div style ={{
							   	background: 'white',
								border: '1px solid #dedede',
								boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
								padding: 20,
								width: '100%',
								height:'100%'
						   }}>
							   {this.right()}
						   </div>
						</div>


						{
							this.state.modal ?
							<PastQuotation
								item={this.state.modal}
								close={()=>{
									this.setState({
										...this.state,
										modal:false
									})
								}}
							  />
							: null
						}




					</div>

					{
							this.state.notes ?
								<div>
									<div style={{
										background: 'white',
										marginTop: 20,
										border: '1px solid #dedede',
										boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
										padding: 40,
										width: '100%'
									}}>
										<p style={{
											color: "#454545",
											fontSize: 17,
											fontWeight: 600,
											marginBottom: 12,
											marginTop: 20
										}}>
											Notes:
										</p>
										{
											this.state.notes
										}
									</div>
								</div> : undefined
						}
						{
							this.state.terms ?
								<div className="ptb-70 ptb-40 container-size">
									<div style={{
										background: 'white',
										marginTop: 20,
										border: '1px solid #dedede',
										boxShadow: '1px 1px 1px rgba(0,0,0,.1)',
										padding: 40,
										width: '100%'
									}}>
										<p style={{
											color: "#454545",
											fontSize: 17,
											fontWeight: 600,
											marginBottom: 12,
											marginTop: 20
										}}>
											Terms & Conditions:
										</p>
										{
											renderHTML(this.state.terms)
										}
									</div>
								</div> : undefined
						}
				</div>

				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName={this.state.stateLoading ? "Loading..." : "Upload File"}
					des="Please select the file to upload"
					show={this.state.uploadmodal} onSave={() => {
						if (this.state.url) {
							let url = this.state.url;
							let files = this.state.files;
							files.push(url)
							this.setState({
								...this.state,
								url: undefined,
								uploadmodal: undefined,
								files
							})
						}
					}} title="Cancel Task" close={() => {
						this.setState({
							...this.state,
							uploadmodal: undefined,
							url: undefined
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<input type="file" onChange={() => this.upload()} ref={ref => this._file = ref} style={{
							height: '100%',
							width: '100%',
							padding: '10px 20px',
						}} />
					</div>
				</Modal>

				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName={this.state.stateLoading ? "Loading..." : "Upload File"}
					des="Please select the file to upload"
					show={this.state.upload} onSave={() => {

					}} title="Upload Csv" close={() => {
						this.setState({
							...this.state,
							upload:false
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<input type="file" onChange={() => this.upload1()} ref={ref => this._file = ref} style={{
							height: '100%',
							width: '100%',
							padding: '10px 20px',
						}} />
					</div>
				</Modal>
			</div>
		)
	}

}

export default index
