import React from 'react'
import Props from 'prop-types'
import Select from 'react-select'

class DropDown extends React.Component {

	state = {
		value: undefined
	};

	componentDidMount() {
		if (this.props.ref) {
			this.props.ref(this);
		}
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	getValue = () => {
		if (this.state.value)
			return this.state.value.value
		else
			return undefined
	}

	render() {
		return (
			<div>
				<p style={{
					fontSize: 15,
					color: "#676767",
				}}>
					{this.props.label} :-
				</p>
				<div className="select-container" style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: 20,
					marginTop: 18
				}}>
					<Select options={this.props.items} onChange={(value) => {
						this.setState({ value })
					}} />
				</div>
			</div>
		)
	}

}

DropDown.propTypes = {
	label: Props.string.isRequired,
	items: Props.array.isRequired
}

export {
	DropDown
}
