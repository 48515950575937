import React from 'react'
import Lottie from 'react-lottie';
import { get, post } from "../../../Model/Network/Config/Axios";
import { Input } from "../Survey/Fields/Input";
import { Rating } from "../Survey/Fields/Rating";
import { Select } from "../Survey/Fields/Select";
import { File as FileUpload } from "../Survey/Fields/FileUpload";
import { Calendar } from '../Survey/Fields/Calendar';
import { TextArea } from '../Survey/Fields/TextArea';
import { DropDown } from '../Survey/Fields/DropDown';
import { handleError, showToast } from "../../Website/Toast/ToastCallback";
import { Modal } from "../../Website/Common/Modal/Modal";



class Index extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: undefined,
			forms: [],
			views: [],
			errorCode:undefined,
			title: "",
			description: "",
			cancel: undefined
		}
	}

	componentDidMount() {
		this.getToken();
	}

	formSubmit() {
		var anyRequired = undefined
		let forms = {};
		this.state.forms.forEach((item) => {
			let value = this[item.id].getValue();
			if (!value && item.required) {
				anyRequired = item.name;
			} else {
				forms[item.id] = value;
			}
		})
		if (anyRequired) {
			showToast({
				type: 'error',
				message: "Please fill the " + anyRequired
			})
			return;
		}

		let data = {
			forms
		}
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		data.token = params.get('token');
		post('task.post', data, (e, r) => {
			if (r) {
				this.setState({
					success: true
				})
			} else {
				handleError(e)
			}
		})
	}


	getToken = () => {
		const search = this.props.location.search; // could be '?foo=bar'
		const params = new URLSearchParams(search);
		const token = params.get('token');
		if (!token)
			this.setState({
				...this.state,
				loading: false,
				error: "Invalid url"
			})
		else {
			get('task.fetch', (e, r) => {
				if (r) {
					let errorCode = r.error;
					delete r.error;
					this.setState({
						...r,
						errorCode,
						loading: false,
					})
				} else {
					this.setState({
						error: e.response.data.error,
						loading: false
					})
				}
			}, { token })
		}
	}


	render() {
		if (this.state.success) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/success'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Success
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.success}
					</p>
				</div>
			)
		}
		if (this.state.loading) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/loading'),
					}}
						height={250}
						width={250}
					/>
				</div>
			)
		}
		if (this.state.error) {
			return (
				<div style={{
					display: 'flex',
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column'
				}}>
					<Lottie options={{
						loop: true,
						autoplay: true,
						animationData: require('../Survey/Fields/assets/error'),
					}}
						height={250}
						width={250}
					/>
					<p style={{
						fontWeight: 800,
						fontSize: 31
					}}>
						Error while loading task
					</p>
					<p style={{
						fontSize: 21,
						marginTop: 12,
						color: "#676767"
					}}>
						{this.state.error}
					</p>
				</div>
			)
		}
		return (
			<div id="product-page">
				<div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<h1 style={{ marginTop: 20 }} className="integration-title">{this.state.title}</h1>
							<p className="integration-desc">{this.state.description}</p>
						</div>
					</div>
				</div>
				<div style={{ background: 'var(--background-color)' }} className="ptb-70 ptb-40">
					<div className="container-size">
						<div className="flex-wrap">
							<div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
								{
									this.state.views.map(item => {
										if (item.values.length > 0)
											return (
												<div key={item.title}>
													<h2 style={{
														fontSize: 25,
														color: "#454545",
														fontWeight: 700
													}}>{item.title}</h2>
													<p style={{
														marginBottom: 20,
														fontSize: 13,
														color: "#898989",
														marginTop: 4
													}}>{item.description}:-</p>
													{
														item.values.map((item, index) => {
															return (
																<div style={{
																	marginBottom: 30
																}} key={index}>
																	<p style={{
																		fontSize: 11,
																		marginBottom: 5,
																		color: "#787878"
																	}}>
																		{item.key} :-
																	</p>
																	<p style={{
																		fontSize: 17,
																		color: '#343434',
																		fontWeight: 600,
																		borderBottom: '1px solid #dedede',
																		paddingBottom: 4,
																	}}>
																		{
																			item.value ?
																				(item.value.includes('pdf') || item.value.includes('jpeg')) ?
																					<a style={{
																						fontWeight: 600,
																						paddingLeft: 0,
																						marginLeft: 0
																					}} href={item.value} target="_blank" rel="noopener noreferrer">View File</a> : item.value :
																				"Not Defined"
																		}
																	</p>

																</div>
															)
														})
													}
												</div>
											)
										return null;
									})
								}
							</div>
						</div>
						<div className="flex-wrap">
							<div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								marginTop: 20,
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
								<h2 style={{
									fontSize: 25,
									color: "#454545",
									fontWeight: 700
								}}>Automation Details</h2>
								<p style={{
									marginBottom: 20,
									fontSize: 13,
									color: "#898989",
									marginTop: 4
								}}>Please review the automation details:-</p>

								<p style={{
									color:"red",fontSize:13,
									marginTop:15,
									marginBottom:15
								}}>
									Automation failed with {this.state.errorCode}
								</p>
								<p style={{
									marginTop: 12,
									color: "white",
									padding: 15,
									flex: 1,
									cursor: 'pointer',
									fontWeight: 700,
									textAlign: 'center',
									background: '#6367FA'
								}} onClick={() => {
									const search = this.props.location.search; // could be '?foo=bar'
									const params = new URLSearchParams(search);
									let token = params.get('token');
									post('task.retry', {
										token
									}, (e, r) => {
										if (r) {
											this.setState({
												success: true
											})
										} else {
											handleError(e)
										}
									})
								}}>
									Retry Integration
								</p>
							</div>
						</div>
						<div className="flex-wrap">
							<div style={{
								padding: 20,
								background: 'white',
								border: '1px solid #dedede',
								width: '100%',
								marginTop: 20,
								boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
							}}>
								<h2 style={{
									fontSize: 25,
									color: "#454545",
									fontWeight: 700
								}}>Custom Fields</h2>
								<p style={{
									marginBottom: 20,
									fontSize: 13,
									color: "#898989",
									marginTop: 4
								}}>Please fill the details below:-</p>
								{
									this.state.forms.map(item => {
										if (item.type === "TEXT") {
											return (
												<Input type="text" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "EMAIL") {
											return (
												<Input type="email" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "NUMBER") {
											return (
												<Input type="number" key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} placeholder={item.placeholder} />
											)
										} else if (item.type === "RATING") {
											return (
												<Rating key={item.id} ref={ref => this[item.id] = ref}
													label={item.name} />
											)
										} else if (item.type === "SELECT" && item.values.length < 5) {
											return (
												<Select key={item.id} items={item.values}
													ref={ref => this[item.id] = ref} label={item.name} />
											)
										} else if (item.type === "SELECT") {
											let value = item.values.map((item) => {
												item.label = item.key;
												return item
											})
											return (
												<DropDown key={item.id} items={value} ref={ref => this[item.id] = ref}
													label={item.name} />
											)
										} else if (item.type === "FILE") {
											return (
												<FileUpload label={item.name} ref={ref => this[item.id] = ref} />
											)
										} else if (item.type === "DATE") {
											return (
												<Calendar
													className="departure_date"
													title="Return Date"
													label={item.name}
													ref={ref => this[item.id] = ref}

												/>
											)
										} else if (item.type === "TEXTAREA") {
											return (
												<TextArea placeholder={item.name} label={item.name}
													ref={ref => this[item.id] = ref} />

											)
										}
										return null

									})
								}
								<p style={{
									marginTop: 20,
									color: "white",
									padding: 15,
									flex: 1,
									cursor: 'pointer',
									fontWeight: 700,
									textAlign: 'center',
									background: '#6367FA'
								}} onClick={() => {
									this.formSubmit()
								}}>
									Submit Form
								</p>
								<div style={{
									display: 'flex',
									flexDirection: 'row'
								}}>
									<p style={{
										marginTop: 12,
										color: "white",
										padding: 15,
										flex: 1,
										cursor: 'pointer',
										fontWeight: 700,
										textAlign: 'center',
										background: '#fa736b'
									}} onClick={() => {
										this.setState({
											...this.state,
											cancel: true
										})
									}}>
										Decline Task
									</p>
									<p style={{
										marginTop: 12,
										color: "white",
										padding: 15,
										marginLeft: 12,
										flex: 1,
										cursor: 'pointer',
										fontWeight: 700,
										textAlign: 'center',
										background: '#3c3c3c'
									}} onClick={() => {
										this.setState({
											...this.state,
											revert: true
										})
									}}>
										Revert Back
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName="Cancel Task"
					des="Please provide the remarks to cancel this task"
					show={this.state.cancel} onSave={() => {
						let remarks = this.__remarks.getValue();
						if (!remarks) {
							showToast({
								type: 'error',
								message: "Please enter a valid remarks"
							})
							return;
						}
						let data = {
							remarks
						}
						const search = this.props.location.search; // could be '?foo=bar'
						const params = new URLSearchParams(search);
						data.token = params.get('token');
						post('task.cancel', data, (e, r) => {
							if (r) {
								this.setState({
									success: "Task cancelled successfully!!"
								})
							} else {
								handleError(e)
							}
						})
					}} title="Cancel Task" close={() => {
						this.setState({
							...this.state,
							cancel: undefined
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<Input label="Cancel Remarks" placeholder="Enter remarks" ref={ref => this.__remarks = ref} />
					</div>
				</Modal>
				<Modal
					style={{
						save: {
							width: '100%',
							height: '45px',
							display: 'flex',
							justifyContent: 'center',
							textTransform: 'uppercase',
							alignItems: 'center'
						}
					}}
					buttonName="Revert Task"
					des="Please provide the remarks to revert this task"
					show={this.state.revert} onSave={() => {
						let remarks = this.remarks.getValue();
						if (!remarks) {
							showToast({
								type: 'error',
								message: "Please enter a valid remarks"
							})
							return;
						}
						let data = {
							remarks
						}
						const search = this.props.location.search; // could be '?foo=bar'
						const params = new URLSearchParams(search);
						data.token = params.get('token');
						post('task.back', data, (e, r) => {
							if (r) {
								this.setState({
									success: "Task cancelled successfully!!"
								})
							} else {
								handleError(e)
							}
						})
					}} title="Revert Task" close={() => {
						this.setState({
							...this.state,
							revert: undefined
						})
					}}>
					<div style={{ marginTop: 22 }}>
						<Input label="Cancel Remarks" placeholder="Enter remarks" ref={ref => this.remarks = ref} />
					</div>
				</Modal>
			</div>
		)
	}

}

export default Index
