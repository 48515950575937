import React from 'react';
import './index.css';
import {employeeData} from './data';
import {Header} from '../Common/Header/index';
import {Footer} from '../Common/Footer';
import linkedin from './linkedin.png';
import {Images} from '../Images/Image';


class Aboutus extends React.Component {


    state = {
        search:'',
        list:employeeData
    }


    mapItem = (item,index) => {
        return(
            <div key = {index} className = "col-lg-3">
                <div className = "aboutus-single">
                    <div style = {{textAlign:'center'}}>
                        <img src = {item.img} alt = {item.name} style =  {{
                            height:100,
                            width:100,
                            borderRadius:'50%'
                        }} />
                    </div>
                    <div style = {{height:'calc( 100% - 120px)'}} className = "aboutus-text vertical">
                        <h3>{item.name}</h3>
                        <p style = {{fontSize:14,fontWeight:600,marginTop:5}}>{item.desig}</p>
                        <div className = "linkedin-profile" style = {{marginTop:'auto'}}>
                            <a  style = {{marginLeft:0}} rel="noopener noreferrer" target = "_blank" href = {item.linkedin}>
                                <img src = {linkedin} alt = {item.name} style = {{height:28,width:28}} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleSearch = () => {
		let name = this.state.search;
		let result = this.state.list.filter(val => val.name.toLowerCase().includes(name.toLowerCase()));
		if (name.length > 0) {
			this.setState({
				list: result
			})
		} else {
			this.setState({
				list: employeeData
			})
		}
	}

    render() {
        return (
        <div>
            <Header />
            <div id = "aboutus">
                <div style = {{borderBottom:'2px dashed var(--primary-color)'}} className = "ptb-70 ptb-40 dotted">
                    <div className = "container-size">
                    <div>
                    <h1 className="aboutus-title">About Us</h1>
                    <div className="aboutus-input flex-v-center">
                        <img src={Images.search} alt="" style={{
                            height: 25,
                            width: 25,
                            marginLeft: 10
                        }}/>
                        <input onChange={(e) => {
                            this.setState({
                                search: e.target.value
                            }, () => this.handleSearch())
                        }} value={this.state.search} placeholder="Search" type="text"
                               style={{
                                   height: '80%',
                                   width: '90%',
                                   marginLeft: 'auto',
                                   fontSize: 16,
                                   border: 'none',
                                   fontWeight: 500
                               }}/>
                    </div>
                </div>
                    </div>
                </div>
                <div style = {{background:'var(--background-color)'}} className = "ptb-70 ptb-40">
                    <div className = "container-size">
                      <div className = "flex-wrap">
                            {this.state.list.map(this.mapItem)}
                      </div>
                      </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
}

export default Aboutus;
