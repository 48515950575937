import React from 'react'
import Props from 'prop-types';
import {Link} from 'react-router-dom'

class LineHeader extends React.Component {

	mapItem = (item, index) => {
		if (item.link)
			return (
				<Link style={{
					padding: '10px 0 12px',
					marginRight: 25,
					fontSize: 14,
					color: 'inherit',
					cursor: 'pointer',
					textTransform: 'uppercase',
					position: 'relative',
					top: 3,
					...item.active ? {
						color: 'var(--primary-color)',
    					paddingBottom: 14,
    					borderBottom: '3px solid var(--primary-color)'
					}:null
				}} to={item.link} key={index} className={item.active ? "active-line" : null}>
					{item.name}
				</Link>
			)
		else
			return (
				<p style={{
					padding: '10px 0 12px',
					marginRight: 25,
					fontSize: 14,
					color: 'inherit',
					cursor: 'pointer',
					textTransform: 'uppercase',
					position: 'relative',
					top: 3,
					...item.active ? {
						color: 'var(--primary-color)',
    					paddingBottom: 14,
    					borderBottom: '3px solid var(--primary-color)'
					}:null
				}} onClick={item.click} key={index} className={item.active ? "active-line" : null}>
					{item.name}
				</p>
			)
	}

	render() {
		return (
			<div style={this.props.style || {borderBottom: '2px solid rgb(241,241,241)', display: 'flex'}}>
				{this.props.items.map(this.mapItem)}
			</div>
		)
	}
}


LineHeader.propsTypes = {
	items: Props.array.isRequired
}

export {LineHeader};
