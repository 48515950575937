import React from 'react'
import Props from 'prop-types'

class Header extends React.Component {

	render() {
		return (
			<h1 className="fs-33 header-common" style={{
				textAlign:'center',
				fontWeight:800,
				color:"var(--text-color-2)",
				marginBottom:50,
				...this.props.style
			}}>
				{this.props.title}
			</h1>
		)
	}

}

Header.propTypes={
	title:Props.string.isRequired
}

export {
	Header
}
