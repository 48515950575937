import React from 'react';


const KeyValue = ({ value1, value2 }) => {

    return (
        <div>
            <p style={{
                fontSize: 15,
                color: "#676767",
            }}>{value1}</p>
            <p style={{
                fontSize: 17,
                fontWeight: 400,
                color: "#454545",
            }}>{value2}</p>
        </div>
    )
}

export { KeyValue }