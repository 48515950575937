import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Home from './Website/Home/Redirect';
import Comparision from './Website/Comparision';
import Integration from './Website/Integration';
import Team from './Website/Team';
import Aboutus from './Website/Aboutus';
import Demo from './Website/Demo';
import Product from './Website/Products';
import ScrollTop from './Website/Common/ScrollTop';
import Contact from './Website/Contact';
import Terminal from './Website/Terminal';
import Blog from './Website/Blog'
import Survey from './Tasks/Survey'
import Collections from './Tasks/Collections'
import Vendor from './Tasks/Vendor'
import Task from './Tasks/Task'
import { ToastContainer } from "./Website/Toast/ToastContainer";
import Demos from './Website/Demos';
import Invoice from './Tasks/Invoice';
import Pr from './Tasks/Pr';
import Offer from './Tasks/Offer';
import Privacy from './Website/Other/PrivacyPolicy';
import Terms from './Website/Other/TermsofUse';
import Automation from './Tasks/Automation';
import { hotjar } from 'react-hotjar'
import Redirect from './Redirect'
import PoRequest from './PoAccepted';



class Index extends React.Component {

    componentDidMount() {
        hotjar.initialize(1889796, 6);
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Digital corporate spending & consumption platform| T&E Management Solutions - Antrepriz</title>
                        <meta name="description" content="Intelligent business travel and expense management solutions and software, employee and expense tracking, dynamic and powerful policy engine, fully cutomizable expense reports, employee self flight, Hotel, Bus and cab booking tool, Admin travel booking tool, end to end work flow management, faster approvals, cheaper flights, quicker bookings, 100% policy compliance business travel, on-demand travel insurance, international travel insurance, 24 hour reimbursement cycle, employee flexi benefits, automated payments, direct account transfer, advance cash settlement, account payable automation, vendor payments. Best business travel, expense and payment solution world. Ensure employee experience with automation, visibility, control and cost saving. Save upto 40% on corporate travel." />
                    </Helmet>
                    <ScrollTop>
                        <Switch>
                            <Route path="/product" component={Product} />
                            <Route path="/partnership" component={Demos} />
                            <Route path="/demo" component={Demo} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/terms" component={Terms} />
                            <Route path="/about" component={Team} />
                            <Route path="/comparison" component={Comparision} />
                            <Route path="/team" component={Team} />
                            <Route path="/~team" component={Aboutus} />
                            <Route path="/integration" component={Integration} />
                            <Route path="/terminal" component={Terminal} />
                            <Route path="/blog" component={Blog} />
                            <Route path="/collections/invoice" component={Invoice} />
                            <Route path="/collections/survey" component={Survey} />
                            <Route path="/collections/vendor" component={Vendor} />
                            <Route path="/collections/payment" component={Collections} />
                            <Route path="/collections/task" component={Task} />
                            <Route path="/collections/pr" component={Pr} />
                            <Route path="/collections/offers/pr" component={Offer} />
                            <Route path="/collections/permission" component={Task} />
                            <Route path="/collections/permission.automation" component={Automation} />
                            <Route path="/collections/po/view" component={PoRequest} />
                            <Route path="/r/:id" component={Redirect} />
                            <Route path="/" component={Home} />
                        </Switch>
                    </ScrollTop>
                </BrowserRouter>
                <ToastContainer />
            </>
        );
    }
}

export default Index;
