import React from 'react'
import Lottie from 'react-lottie';
import { get, post } from "../../../Model/Network/Config/Axios";
import { Input } from "../Survey/Fields/Input";
import { File as FileUpload } from "../Survey/Fields/FileUpload";
import { handleError, showToast } from "../../Website/Toast/ToastCallback";
import { Modal } from '../../Website/Common/Modal/Modal';
import Pdf from './pdf.png';
import {Rating} from "../Survey/Fields/Rating";
import {Select} from "../Survey/Fields/Select";
import {DropDown} from "../Survey/Fields/DropDown";
import {Calendar} from "../Survey/Fields/Calendar";
import {TextArea} from "../Survey/Fields/TextArea";



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: undefined,
            forms: [],
            title: "",
            description: "",
            imageModal: false,
            images: [],
            views: []
        }
    }

    componentDidMount() {
        this.getToken();
    }

    formSubmit() {
        var anyRequired = undefined
        let forms = {};
        this.state.forms.forEach((item) => {
            let value = this[item.id].getValue();
            if (!value && item.required) {
                anyRequired = item.name;
            } else {
                forms[item.id] = value;
            }
        })
        if (anyRequired) {
            showToast({
                type: 'error',
                message: "Please fill the " + anyRequired
            })
            return;
        }

        let amount = this._amount.getValue();
        let base = this._base.getValue();
        let cgst = this._cgst.getValue();
        let igst = this._igst.getValue();
        let sgst = this._sgst.getValue();
        let images = this.state.images;


        if (!amount) {
            showToast({
                type: 'error',
                message: 'Please enter the amount'
            })
            return;
        }


        if (!base) {
            showToast({
                type: 'error',
                message: 'Please enter the base amount'
            })
            return;
        }

        if (!cgst) {
            showToast({
                type: 'error',
                message: 'Please enter the cgst amount'
            })
            return;
        }
        if (!sgst) {
            showToast({
                type: 'error',
                message: 'Please enter the sgst amount'
            })
            return;
        }

        if (!igst) {
            showToast({
                type: 'error',
                message: 'Please enter the igst amount'
            })
            return;
        }


        if (images.length < 1) {
            showToast({
                type: 'error',
                message: 'Please select atleast one attachment or image'
            })
            return;
        }

        let files = images.map(val => (val.url));





        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const token = params.get('token');

        let data = {
            amount: Number(amount),
            files,
            forms,
            base: Number(base),
            cgst: Number(cgst),
            sgst: Number(sgst),
            igst: Number(igst),
            date:this.date.getValue()
        }

        data.token = token;
        post('invoice/post', data, (e, r) => {
            if (r) {
                this.setState({
                    success: true
                })
            } else {
                handleError(e)
            }
        }, { token })
    }


    getToken = () => {
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (!token)
            this.setState({
                ...this.state,
                loading: false,
                error: "Invalid url"
            })
        else {
            get('invoice/fetch', (e, r) => {
                if (r) {
                    this.setState({
                        ...r,
                        loading: false
                    })
                } else {
                    this.setState({
                        error: e.response.data.error,
                        loading: false
                    })
                }
            }, { token })
        }
    }


    mapImages = (item, index) => {
        return (
            <div key={index} style={{
                marginRight: 25,
                marginBottom: 25
            }}>
                <div style={{
                    border: '1px solid #e6ebf3',
                    height: 220,
                    width: 200,
                    padding: 5
                }}>


                    {
                        item.url.includes("pdf") ?
                            <a rel="noopener noreferrer" href={item.path} target="_blank" style={{
                                marginLeft: 0
                            }}><img
                                    src={Pdf}
                                    alt="pdf icon" style={{
                                        width: 190,
                                        height: 185,
                                        borderBottom: '1px solid var(--border-color)',
                                    }} /></a>
                            : <img src={item.url}
                                style={{
                                    width: 190,
                                    height: 185,
                                    borderBottom: '1px solid var(--border-color)'

                                }}
                                alt=""
                            />
                    }

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2
                    }}>

                        <p style={{
                            color: 'var(--red-color)',
                            fontSize: 14,
                            fontWeight: 600,
                            cursor: 'pointer',
                            marginRight: 5,
                            marginLeft: 'auto'
                        }} onClick={() => {
                            let images = this.state.images;
                            images = images.filter(val => val.key !== item.key);

                            this.setState({
                                ...this.state,
                                images,
                            })

                        }}>Remove</p>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        if (this.state.loading) {
            return (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: require('../Survey/Fields/assets/loading'),
                    }}
                        height={250}
                        width={250}
                    />
                </div>
            )
        }
        if (this.state.success) {
            return (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: require('../Survey/Fields/assets/success'),
                    }}
                        height={250}
                        width={250}
                    />
                    <p style={{
                        fontWeight: 800,
                        fontSize: 31
                    }}>
                        Success
        			</p>
                    <p style={{
                        fontSize: 21,
                        marginTop: 12,
                        color: "#676767"
                    }}>
                        Invoice submitted successfully!
        			</p>
                </div>
            )
        }
        if (this.state.error) {
            return (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: require('../Survey/Fields/assets/error'),
                    }}
                        height={250}
                        width={250}
                    />
                    <p style={{
                        fontWeight: 800,
                        fontSize: 31
                    }}>
                        Error while loading survey
        			</p>
                    <p style={{
                        fontSize: 21,
                        marginTop: 12,
                        color: "#676767"
                    }}>
                        {this.state.error}
                    </p>
                </div>
            )
        }

        return (
            <div id="product-page">
                <div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
                    <div className="container-size">
                        <div>
                            <h1 style={{ marginTop: 20 }} className="integration-title">{this.state.title}</h1>
                            <p className="integration-desc">{this.state.notes}</p>
                        </div>
                    </div>
                </div>
                <div style={{ background: 'var(--background-color)', minHeight: '90vh' }} className="ptb-70 ptb-40">
                    <div className="container-size">
                        <div className="flex-wrap">
                            <div style={{
                                padding: 20,
                                background: 'white',
                                border: '1px solid #dedede',
                                width: '100%',
                                boxShadow: '2px 1px 1px rgba(1,1,1,.1)'
                            }}>

                                {
                                    this.state.views.map((item, index) => {
                                        if (item.values.length > 0)
                                            return (
                                                <div key={index}>
                                                    <h2 style={{
                                                        fontSize: 25,
                                                        color: "#454545",
                                                        fontWeight: 700
                                                    }}>{item.title}</h2>
                                                    <p style={{
                                                        marginBottom: 20,
                                                        fontSize: 13,
                                                        color: "#898989",
                                                        marginTop: 4
                                                    }}>{item.notes}:-</p>
                                                    {
                                                        item.values.map(item => {
                                                            return (
                                                                <div style={{
                                                                    marginBottom: 30
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: 11,
                                                                        marginBottom: 5,
                                                                        color: "#787878"
                                                                    }}>
                                                                        {item.key} :-
																	</p>
                                                                    <p style={{
                                                                        fontSize: 17,
                                                                        color: "#343434",
                                                                        fontWeight: 600,
                                                                        borderBottom: '1px solid #dedede',
                                                                        paddingBottom: 4
                                                                    }}>
                                                                        {item.value ? item.value : "Not Defined"}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            );
                                        return null;
                                    })
                                }

                                <h2 style={{
                                    fontSize: 25,
                                    color: "#454545",
                                    fontWeight: 700
                                }}>Invoice Details</h2>
                                <p style={{
                                    marginBottom: 20,
                                    fontSize: 13,
                                    color: "#898989",
                                    marginTop: 4
                                }}>Please fill the basic details below:-</p>
                                <Calendar
                                    className="departure_date"
                                    title="Date"
                                    label="Date"
                                    ref={ref => this.date = ref}
                                />
                                <Input
                                    type="number"
                                    ref={ref => this._base = ref}
                                    placeholder={"Enter the base amount"}
                                    label="Base Amount" />

                                <Input
                                    type="number"
                                    ref={ref => this._sgst = ref}
                                    placeholder={"Enter the sgst amount"}
                                    label="SGst" />

                                <Input
                                    type="number"
                                    ref={ref => this._cgst = ref}
                                    placeholder={"Enter the cgst amount"}
                                    label="CGst" />

                                <Input
                                    type="number"
                                    ref={ref => this._igst = ref}
                                    placeholder={"Enter the igst amount"}
                                    label="IGst" />

                                <Input
                                    type="number"
                                    ref={ref => this._amount = ref}
                                    placeholder={"Enter the total amount"}
                                    label="Amount" />
                                <h2 style={{
                                    fontSize: 25,
                                    color: "#454545",
                                    fontWeight: 700
                                }}>Custom Fields</h2>
                                <p style={{
                                    marginBottom: 20,
                                    fontSize: 13,
                                    color: "#898989",
                                    marginTop: 4
                                }}>Please fill the details below:-</p>
                                {
                                    this.state.forms.map(item => {
                                        if (item.type === "TEXT") {
                                            return (
                                                <Input type="text" key={item.id} ref={ref => this[item.id] = ref}
                                                       label={item.name} placeholder={item.placeholder} />
                                            )
                                        } else if (item.type === "EMAIL") {
                                            return (
                                                <Input type="email" key={item.id} ref={ref => this[item.id] = ref}
                                                       label={item.name} placeholder={item.placeholder} />
                                            )
                                        } else if (item.type === "NUMBER") {
                                            return (
                                                <Input type="number" key={item.id} ref={ref => this[item.id] = ref}
                                                       label={item.name} placeholder={item.placeholder} />
                                            )
                                        } else if (item.type === "RATING") {
                                            return (
                                                <Rating key={item.id} ref={ref => this[item.id] = ref}
                                                        label={item.name} />
                                            )
                                        } else if (item.type === "SELECT" && item.values.length < 5) {
                                            return (
                                                <Select key={item.id} items={item.values}
                                                        ref={ref => this[item.id] = ref} label={item.name} />
                                            )
                                        } else if (item.type === "SELECT") {
                                            let value = item.values.map((item) => {
                                                item.label = item.key;
                                                return item
                                            })
                                            return (
                                                <DropDown key={item.id} items={value} ref={ref => this[item.id] = ref}
                                                          label={item.name} />
                                            )
                                        } else if (item.type === "FILE") {
                                            return (
                                                <FileUpload label={item.name} ref={ref => this[item.id] = ref} />
                                            )
                                        } else if (item.type === "DATE") {
                                            return (
                                                <Calendar
                                                    className="departure_date"
                                                    title="Return Date"
                                                    label={item.name}
                                                    ref={ref => this[item.id] = ref}
                                                />
                                            )
                                        } else if (item.type === "TEXTAREA") {
                                            return (
                                                <TextArea placeholder={item.name} label={item.name}
                                                          ref={ref => this[item.id] = ref} />

                                            )
                                        }
                                        return null

                                    })
                                }
                                <p className="floating-top-label" style={{ fontSize: 13 }}>Add Attachment :-</p>
                                <div style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginTop: 15
                                }}>
                                    {this.state.images.map(this.mapImages)}
                                    <div style={{
                                        marginBottom: 25,
                                        border: '2px dashed rgb(222,222,222)',
                                        height: 215,
                                        width: 210,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            imageModal: true
                                        })
                                    }}>
                                        <div style={{
                                            fontSize: 18,
                                            textAlign: 'center',
                                            cursor: 'pointer'
                                        }}>
                                            <p>+</p>
                                            <p>Add Attachment</p>
                                        </div>
                                    </div>
                                </div>



                                <p style={{
                                    marginTop: 20,
                                    color: "white",
                                    padding: 15,
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    background: '#6367FA'
                                }} onClick={() => {
                                    this.formSubmit()
                                }}>
                                    Submit Invoice
								</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.imageModal}
                    title="Select Image"
                    des="Please select single image to upload"
                    close={() => {
                        this.setState({
                            ...this.state,
                            imageModal: false
                        })
                    }}
                    style={{
                        save: {
                            width: '100%',
                            height: 45,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    }}
                    buttonName="Upload Image"
                    onSave={() => {
                        let images = this.state.images;
                        if (!this._image.getValue()) {
                            showToast({
                                type: 'error',
                                message: "Please select the valid file"
                            })
                            return;
                        }
                        images.push({ key: this.state.images.length + 1, url: this._image.getValue() });
                        this.setState({
                            imageModal: false,
                            images
                        })
                    }}
                >

                    <FileUpload label="Select Image" ref={ref => this._image = ref} />

                </Modal>
            </div>
        )
    }

}

export default Index
