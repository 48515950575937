import React from 'react'
import Props from 'prop-types';

class System extends React.Component {

	render() {
		return (
			<div style={{
				display:'flex',
				marginTop:this.props.top
			}}>
				<span style={{
					color:"white",
					fontSize:14,
					marginTop:2
				}}>{this.props.user?`~ ${this.props.user}$ `:"~ root# "}</span>
					<p style={{
						color:'white',
						...this.props.css,
						display:'inline-block',
						paddingLeft:5
					}}>{this.props.text}</p>
			</div>
		)
	}

}

System.propTypes={
	text:Props.string.isRequired
}

export default System
