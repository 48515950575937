import React from 'react'
import {get} from "../../Model/Network/Config/Axios";

class index extends React.Component {

	componentDidMount() {
		console.log()
		get(`/redirect`,(e,r)=>{
			if(r){
				window.location.href = r.url ;
			}
		},{
			id:this.props.match.params.id
		})
	}

	render() {
		return (
			<div>
				Redirecting...
			</div>
		)
	}

}

export default index
