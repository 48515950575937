import React from 'react';
import html from 'react-render-html';
import YouTube from 'react-youtube';
import './style.css'

class ArticleItem extends React.Component{


    render(){
		let content = this.props.item.content;
        let index = this.props.index;
     
        if(content.type==="text"){
				return (
					<div key={index} className="html_Content_Text">
						{html(content.content)}
					</div>
				)
			}
			else if(content.type==="image"){
				return (
					<div key={index} style={{
						position:'relative',
						marginTop:45,
						display:'flex',
						flexDirection:'column',
						justifyContent:'center'
					}}>
                    <img src={content.file} height='550' width="100%" className="artical_blog_image"  alt=""/>
						<p style={{
							textAlign:'center',
							color:"#676767",
							margin:12,
							fontSize:11
						}}>
							{content.caption}
						</p>
					</div>
				)
			}
			else if(content.type==="video"){
				return (
					<div key={index} style={{
						position:'relative',
						marginTop:45,
						display:'flex',
						flexDirection:'column',
						justifyContent:'center'
					}}>
						<YouTube
							videoId={content.id}
							className="youtube"
						/>
					</div>
				)
			}


    }
}

export default ArticleItem;