import React from "react";
import "./style.css";
import { next } from "../../../../../../Images/Common/index";
import { tag } from "./../Article/images/index";
import { Link } from "react-router-dom";
class Index extends React.Component {
  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  render() {
    return (
      <div className="list-main-container">
        <div className="list-container">
          <div className="imageWrapper">
            <img
              src={this.props.item.image}
              alt="Antrepriz"
              style={{ width: "100%" }}
            />
          </div>

          <div className="container-title">
            <h4 className="main-title">{this.props.item.title}</h4>
            <p className="pTitle">
              {this.truncate(this.props.item.description, 170)}
            </p>
          </div>

          <div className="getBookContainer">
            <div className="get-Inner-Container">
              <Link
                to={`/blog/page/${
                  this.props.item.blogId
                  }/${this.props.item.title
                    .trim()
                    .split(" ")
                    .join("-")}`}
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                  marginLeft: 15
                }}
              >
                Explore Blog
              </Link>
              <img
                src={next}
                alt="Antrepriz"
                height="13"
                width="13"
                className="arrowImg"
              />
            </div>
            <div
              className="blog-category-container"
              style={{
                width: 120
              }}
            >
              <img
                src={tag}
                className="tagLabel"
                alt=""
                style={{
                  display: "inline-block"
                }}
              />
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                <Link
                  to={`/blog?categoryId=${this.props.item.category}`}
                  className="blog-category"
                >
                  {this.props.item.category}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Index;
