import Moment from 'moment';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import UUID from 'uuid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


class Calendar extends React.Component {


    state = {
        focused: false,
        date: Moment()
    }

    componentDidMount() {
        if (this.props.ref) {
            this.props.ref(this);
        }
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    getValue() {
        if (this.state.date) {
            let timeStamp = new Date(this.state.date).getTime();
            return  Moment(timeStamp).format('DD-MM-YYYY');
        }
    }

    setFocus(focus) {
        this.setState({ focused: focus })
    }

    render() {
        return (
            <div style={{ paddingBottom: 20, paddingTop: 12 }}>
                <p style={{
                    fontSize: 15,
                    color: "#676767",
                }}>
                    {this.props.label} :-
				</p>
                <div className={this.props.className} style={{
                    position: 'relative',
                    cursor: 'pointer !important',
                }}>
                    <SingleDatePicker
                        withPortal
                        numberOfMonths={1}
                        displayFormat="DD MMM YYYY"
                        date={this.state.date}
                        onDateChange={(date) => {
                            this.setState({
                                date
                            })
                        }}
                        focused={this.state.focused}
                        onFocusChange={({ focused }) => {
                            this.setState({ focused })
                        }}
                        id={this.props.id ? this.props.id : UUID.v4()}
                        isOutsideRange={() => false}
                    />
                </div>
            </div>
        )
    }
}

export { Calendar };
