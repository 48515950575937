import React from 'react'
//import { NormalTable } from "../../../../Tables/NormalTable/NormalTable";
import Moment from "moment";


class Timeline extends React.Component {

	mapTimeLine = (item, index) => {
		let status = '';
		if (item.type === 'start') {
			status = "Invoice Initiated";
		} else if (item.type === 'end') {
			status = "Cycle Completed"
		} else {
			status = item.status;
		}

		let value = '';
		if (item.actionTime && item.time) {
			let time = Moment(item.actionTime).diff(item.time, 'days');
			let hours = Moment(item.actionTime).diff(item.time, 'hours');
			let minutes = Moment(item.actionTime).diff(item.time, 'minutes');

			if (time > 0) {
				value = `${time} days`
			} else if (hours > 0) {
				value = `${hours} hours`
			} else {
				value = `${minutes} minutes`
			}
		}


		return (
			<div key={index}
				style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 10, height: 60, border: '1px solid #dedede',display:'flex',alignItems:'center' }}
				className="border-bottom fintrip-department-item table-item"
			>
				<div className="linear-layout horizontal"
					style={{ flex: 1, cursor: 'pointer', alignItems: 'center' }}
				>
					<div style={{
						flex: 2
					}}>
						<p style={{
							fontWeight: 600,
							fontSize: 16
						}}>{item.email}</p>
						<p style={{
							fontSize: 11,
							color: "var(--light-color)"
						}}>{item.name}</p>
					</div>
					<div style={{
						flex: 1
					}}>
						<p style={{
							fontWeight: 600,
							fontSize: 14
						}}>{status}</p>
					</div>
					<div style={{
						flex: 1
					}}>
						{
							item.actionTime && item.time ?
								<p style={{
									fontWeight: 600,
									fontSize: 12,
									color: 'var(--light-color)'
								}}>{value}</p>
								: null
						}
					</div>
					<div style={{
						flex: 1,
						textAlign: 'right',
						fontSize: 11
					}}>
						<div>
							<p style={{ fontWeight: 600 }}> Sent on : {Moment(item.time).format('DD/MM/YYYY hh:mm a')}</p>

						</div>
						{item.actionTime ?
							<div>
								<p style={{ marginTop: 2, fontWeight: 600 }}> Approved on : {Moment(item.actionTime).format('DD/MM/YYYY hh:mm a')}</p>
							</div>
							: null
						}

					</div>
				</div>
			</div>
		)
	}


	duplicates = () => {
		let { duplicates } = this.props.data;
		return (
			<>
				{duplicates.map((data, index) => {
					return (
						<div style={{
						}} key={index}>
							<div className="border-box" style={{
								display: 'flex'
							}}>
								<p style={{
									fontWeight: 600,
									width: 150
								}}>{data} </p>

							</div>

						</div>
					)
				})}
			</>

		)
	}

	render() {
		let { duplicates } = this.props.data
		return (
			<div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					fontSize: 11,
					padding: 8,
					background: "#f2f4f8",
					border: '1px solid #dedede',
					color: 'var(--light-color)'
				}}>
					Timeline
				</div>
				{this.props.timeline.map(this.mapTimeLine)}
				{/* <NormalTable
					headers={[]}
					data={this.props.timeline}
					pagination={false}
					mapItem={this.mapTimeLine}
					headerStyle={{
						borderBottom: 'none'
					}}
				/> */}
				{
					duplicates.length > 0 ?
						<div style={{
							marginTop: 30,
							display: 'flex',
							alignItems: 'center',
							fontWeight: 600,
							fontSize: 11,
							padding: 8,
							border: '1px solid #dedede',
							color: 'var(--light-color)',
						}}>
							Duplicates InvoiceId
						</div> : null}
				{
					this.duplicates()
				}
			</div>
		)
	}

}

export default Timeline
