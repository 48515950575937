import React from 'react'
import Props from 'prop-types'

class Input extends React.Component {


	getValue = () => {
		return this.mRef.value
	}

	componentDidMount() {
		if (this.props.ref) {
			this.props.ref(this);
		}

		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	render() {
		return (
			<div>
				<p style={{
					fontSize: 15,
					color: "#676767",
				}}>
					{this.props.label} :-
				</p>
				<input type={this.props.type} ref={ref => this.mRef = ref} placeholder={this.props.placeholder} style={{
					border: 'none',
					borderBottom: '1px solid #dedede',
					width: '100%',
					fontSize: 17,
					fontWeight: 400,
					color: "#454545",
					marginBottom: 25,
					marginTop: 10
				}} />
			</div>
		)
	}

}

Input.propTypes = {
	label: Props.string.isRequired,
	placeholder: Props.string.isRequired
}

export {
	Input
}
