const platform = [
    {
        type:'Title',
        name:'Platform',
        desc:'',
        first_available:'Concur',
        second_available:'Eka'
    },
    {
        type:'nonTitle',
        name:'Web based',
        desc:'',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
        
    },
    {
        type:'nonTitle',
        name:'iPhone App',
        desc:'',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Android App',
        desc:'',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Sweep Expenses',
        mobile_second:'Premium'
    }
]

const features = [
    {
        type:'Title',
        name:'Features',
        desc:'Description',
        first_available:'Sweep Expenses',
        second_available:'Premium',
        status:'Available'
    },
    {   
        type:'nonTitle',
        name:'Access Control',
        desc:'User access control for using dashboard (office/ dept level)',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Account Reconciliation',
        desc:'Employee and company level account recnciliation',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Accounting Integration',
        desc:'Integration with existing accounting softwares',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Activity Dashboard',
        desc:'Complete employee activity dashboard for finance',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Activity Management',
        desc:'Finance can control all spending activities',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Activity Tracking',
        desc:'Finance/ Admin tracking activities in real-time',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Administrative Reporting',
        desc:'Travel booking reporting for admins',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Agent booking portal',
        desc:'Flight, hotel and bus booking portal for travel admins',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Alerts / Escalation',
        desc:'Safety and issues alerts/ escalation',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'API',
        desc:'API for various integrations',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Audit Trail',
        desc:'Complete voucher/ receipt audit trail',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Auditing',
        desc:'Spend auditing using AI with human support',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Automated Expense Input (OCR)',
        desc:'Reading all the relevant deatils from receipts',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Automatic Notifications',
        desc:'Notification for approvals, transfer and updates',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Billing & Invoicing',
        desc:'Client billing and invoicing ',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Business Intelligence',
        desc:'Generating insights for employee spends',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Cash Flow Management',
        desc:'Managing cash advances to employees',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Categorization',
        desc:'Categorization of expenses based on service provider',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Company Owned Property Booking Mgmt',
        desc:'Managing and integrating company guest houses and service apartments with employee app stay inventory',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Compliance Management',
        desc:'Ensuring spend policies are implemented',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Configurable Approval Flow',
        desc:'Route approvals based on custom flows',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Corporate Cards Program',
        desc:"Company's own card program to manage employee spends",
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Credit Card Integration',
        desc:'Integrating with existing company partnered credit cards',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Custom Fields',
        desc:'Create custom fields for different services',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Customizable Reporting',
        desc:'Scheduling custom generated reports at various levels',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Dashboard Creation',
        desc:'Be-spoke dashboard development basis KPI requirement',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Data Export',
        desc:'Create and export custom data from EKA',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Data Import',
        desc:'CSV data import for HR/ Finance data',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Direct A/C transfer',
        desc:'Direct A/C transfer to employee account for reimbursement or advances',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Duplicate Detection',
        desc:'Detecting duplicate expenses present in the system',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Dynamic booking policies',
        desc:'Dynamic amount policy and AI based inventory sorting',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Employee Accounting Management',
        desc:'Employee ledger and monthly statements',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Employee level app modularity',
        desc:'App modularity to employee level',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Employee Management',
        desc:'Organization set-up and employee management',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Employee travel booking app',
        desc:'In-app flight, hotel, bus and cab booking',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'ERP Integration',
        desc:'Integrating with ERP via API/ Plugins',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Exception management',
        desc:'Managing exception use cases and workflow according to desired policies',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Expense Claims',
        desc:'Claiming expenses from mobile app or email',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Expense Tracking',
        desc:'Traceability of all expenses from spend to reconciliation',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Fleet expense management',
        desc:'Managing spends for delivery fleets',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Flight cancellation assistance',
        desc:'Cancelling flight and assisting re-booking. In EKA its fully automated',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Fraud Detection',
        desc:'Detecting fraud with spends. EKA has in-built fraud detection whereas concur has AppZen (to be purchased as separate module)',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Gamification',
        desc:'Gamifying spends to improve experience and ensure compliance',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Generating master itineraries',
        desc:'Generate master itineraries for trips',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Individual schedule management',
        desc:'Creating schedules for trips',
        first_available:'no',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
  
    {
        type:'nonTitle',
        name:'Instant Messaging',
        desc:'Chat and notification channel between admins dashboard and employee app',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Invoice Management',
        desc:'Managing invoices for employee spends',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Mobile Alerts',
        desc:'Alerts and notification to automate and update employee on app',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Monitoring',
        desc:'Monitoring employee locations for duty of care during outstation visits',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Multi-Country',
        desc:'Multi-country set-up and expenses. EKA is in process to build multi-country updates',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Multi-Currency',
        desc:'Accepting amount input in multiple currencies and converting them to base currency',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Multi-Language',
        desc:'Languages other then English',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Multi-Location',
        desc:'Enabling multi-location/ offices set-up for employee expense management',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Offline Access',
        desc:"Submitting expenses online - Because of OCR both systems don't have an offline access",
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'P2P Cab booking',
        desc:'Directly booking cabs like OLA, right within employee app',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Payment gateway',
        desc:'Payment gateway at Employee app',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Performance Reports',
        desc:'Regular travel and spend program performance report',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Permission Management',
        desc:'Complete permission management for managers and reportees',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Planning Tools',
        desc:'Multi-city multi mode travel planning and budgeting tool',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Policy Management',
        desc:'Complete expense and travel policy management',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Project Accounting',
        desc:'Create projects and manage employee expenses for projects',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Projections',
        desc:'Create projections for spend and travel based on previous data',
        first_available:'no',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Purchase Order Management',
        desc:'PO issuing, tracking and mapping with invoices',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'QR Pay',
        desc:'Direct QR payments using employee app (similar to corporate cards)',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Real Time Analytics',
        desc:'Real-time spend analytics',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Real Time Notifications',
        desc:'Notification for approvals, transfer and updates',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Real Time Reporting',
        desc:'Reporting of spends and travel updates',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Receipt Management',
        desc:'Digital receipt management for all employee spends',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Reimbursement Management',
        desc:'Managing reimbursement flow and transfers',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Reporting & Statistics',
        desc:'In mobile app reporting data and team expense statistics',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Rewards for saving employer money',
        desc:'Rewards for cheaper booking and saving company money ',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Route Management',
        desc:'GPS tracking for self-drive and receiptless local travel',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Salary benefits',
        desc:'Employee salary tax benefits filing, processing and complying',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Schedule coordination among team members',
        desc:'Coordinating bookings and expenses among members on trip',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Scheduled Reporting',
        desc:'Scheduling custom generated reports at various levels for various use cases',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Search Functionality',
        desc:'Search functionality in dashboard for quick data access. EKA has elastic search',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Service provider integration',
        desc:'Be-spoke service provider integration',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Single Sign On',
        desc:'Enabling single sign on for direct employee login',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Spend Analysis',
        desc:'Spend analysis for employees (in app) and composite spend analysis for admins',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Spend Control at transaction time',
        desc:'Controlling spend  based on policies at time of transaction',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Store Cash Management',
        desc:'Card program for managing store expenses',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Tagging',
        desc:'Tagging other employees with expense and automated policy/ workflow management',
        first_available:'no',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Tax payment and reporting',
        desc:'Tax compliance for all spends and travel booking',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Third Party Integration',
        desc:'Integrating third party HRMS, accounting, CRM or document management system',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },{
        type:'nonTitle',
        name:'Travel document storage',
        desc:'Storing travel documents like passports ',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Travel expense reporting and tracking',
        desc:'Automatically tagging expenses in trip',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Travel-plan calendar sync',
        desc:'Syncing travel booking with calendar. Concur has tripit and EKA is building Calendar Sync feature',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Traveler safety and security management',
        desc:'Updating traveler location, notifying admins in case of disruptions',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'Trip alerts',
        desc:'Alerts during trip to automate complete travel experience. EKA has in-built trip alerts and actions whereas concur has tripit for travel automation (purchased as separate module)',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    
    {
        type:'nonTitle',
        name:'Weather forecast updates',
        desc:'Regular weather forecast updates during trip for duty of care',
        first_available:'yes',
        second_available:'no',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
    {
        type:'nonTitle',
        name:'What If Scenarios',
        desc:'Spend processing with policies and probable scenarios',
        first_available:'yes',
        second_available:'yes',
        mobile_first:'Concur',
        mobile_second:'Eka'
    },
   



]

export { features,platform };