import React from 'react';
import './index.css';
import { TypeForm } from '../Common/TypeForm';
import Arrow from './arrow.png';
import axios from 'axios';
import approve from './approve.png';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../Toast/ToastCallback';


const companySize = ["Below 100", "Between 100 to 250", "Below 500", "Between 500-1000", "Greater than 1000"]

class Demo extends React.Component {

    state = {
        active: 1,
        ans1: '',
        ans2: '',
        ans3: '',
        ans4: '',
        ans5: '',
        ans6: '',
        ans7: '',
        rightcursor: false,
        leftcursor: false
    }

    handleNextQuestion = () => {
        let active = this.state.active;
        if (this.state.active === 7) {
            this.setState({
                active: 8
            })
            this.checkForm();
        }
        if (active < 7) {
            this.setState({
                active: active + 1,
            })
        }
    }

    checkForm = () => {

        if (!this.state.ans1) {
            alert("Field are required");
            this.setState({
                active: 1
            })
            return
        }
        if (!this.state.ans2) {
            alert("Field are required");
            this.setState({
                active: 2
            })
            return
        }
        if (!this.state.ans3) {
            alert("Field are required");
            this.setState({
                active: 3
            })
            return;
        }

        if (!this.state.ans4) {
            alert("Field are required");
            this.setState({
                active: 4
            })
            return;
        }

        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const auth = regexp.test(this.state.ans4);
        if (!auth || this.state.ans4.length < 13) {
            showToast({
                type: 'error',
                message: 'Please enter a vald email'
            })
            this.setState({
                active: 4
            })
            return;
        }
        if (!this.state.ans5) {
            alert("Field are required");
            this.setState({
                active: 5
            })
            return
        }
        let mobile = this.state.ans6;
        if (mobile.length !== 10 || mobile.charAt(0) === '0' || mobile.charAt(0) === '1' || mobile.charAt(0) === '2' || mobile.charAt(0) === '3' || mobile.charAt(0) === '4' || mobile.charAt(0) === '5') {
            showToast({
                type: 'error',
                message: 'Please enter 10 ten valid mobile number'
            })
            this.setState({
                active: 6
            })
            return;
        }
        if (!this.state.ans6) {
            alert("Field are required");
            this.setState({
                active: 6
            })
            return
        }
        if (!this.state.ans7) {
            alert("Field are required");
            this.setState({
                active: 7
            })
            return
        }




    }


    submitForm = (e) => {

        let id = localStorage.getItem('_hjid');
        let hotjarId = id.split('-');
        axios({
            method: 'post',
            url: `https://heimdall.eka.io/triggers/contact`,
            data: {
                to: 'lakshay@antrepriz.com',
                name: `${this.state.ans1}`,
                email: `${this.state.ans4}`,
                company: this.state.ans2,
                designation: `${this.state.ans3} hotjarId: ${hotjarId}`,
                size: this.state.ans5,
                industry: this.state.ans7,
                phone: this.state.ans6
            }
        })
            .then(res => {
                this.setState({
                    ...this.state,
                    active: 9
                })
            })
    }

    render() {
        return (
            <div style={{ position: 'relative', height: '100%' }} id="demo">
                <div onClick={() => this.props.history.goBack()} className="demo-back-button flex-v-center flex-h-center">
                    <img src={Arrow} alt="" style={{ height: 18, width: 18, transform: 'rotate(180deg)' }} />
                </div>
                <div className="demo-single flex-v-center">
                    <div className={`demo-item 
                    ${this.state.active === 1 ? "demo-animation" : "demo-inactive-animation"}
                        `}>
                        <TypeForm onText={text => {
                            this.setState({
                                ans1: text
                            })
                        }}
                            number="1"
                            type="text"
                            quest="What is your full name?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}
                        />
                    </div>
                    <div className={`demo-item ${this.state.active === 2 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={text => {
                            this.setState({
                                ans2: text
                            })
                        }}
                            number="2"
                            type="text"
                            quest="What is the name of your company?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}

                        />
                    </div>
                    <div className={`demo-item ${this.state.active === 3 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={text => {
                            this.setState({
                                ans3: text
                            })
                        }}
                            number="3"
                            type="text"
                            quest="what is your position in your company ?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}

                        />
                    </div>
                    <div className={`demo-item ${this.state.active === 4 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={text => {

                            this.setState({
                                ans4: text
                            })
                        }}
                            number="4"
                            type="email"
                            quest="What is your work email address ?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}

                        />
                    </div>
                    <div className={`demo-item ${this.state.active === 5 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={mobile => {

                            this.setState({
                                ans5: mobile
                            })
                        }}
                            number="5"
                            type="number"
                            quest="How many employees work in your company ?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}
                            qtype="option"
                            companySize={companySize}
                            ans={this.state.ans5}
                        />
                    </div>
                    <div className={`demo-item ${this.state.active === 6 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={text => {
                            this.setState({
                                ans6: text
                            })
                        }}
                            number="6"
                            type="number"
                            quest="What is your mobile number ?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}

                        />
                    </div>

                    <div className={`demo-item ${this.state.active === 7 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <TypeForm onText={text => {
                            this.setState({
                                ans7: text
                            })
                        }}
                            number="7"
                            type="text"
                            quest="What is your industry?"
                            nextQuestion={this.handleNextQuestion}
                            active={this.state.active}

                        />
                    </div>

                    <div className={`demo-item ${this.state.active === 8 ? "demo-animation" : "demo-inactive-animation"}`}>
                        <p className="form-end">You are reach at the end of form please submit it by click on it.</p>
                        <div onClick={() => this.submitForm()} className="navigation submit-button">
                            Submit
                        </div>
                    </div>

                    <div className={`form-submit ${this.state.active === 9 ? "form-submit-show" : null}`}>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <img src={approve} alt="" />
                            </div>
                            <h1>Your Form submit successfully</h1>
                            <div style={{ textAlign: 'center' }}>
                                <Link to="/" className="btn">Go Back to Home</Link>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    this.state.active !== 9 ?

                        <div className="demo-navigation flex-v-center">
                            {this.state.active > 1 ?
                                <div onClick={() => {
                                    let active = this.state.active;
                                    if (active > 1) {
                                        this.setState({
                                            active: active - 1,
                                        })
                                    }
                                }} className="navigation">{'<'}</div> : null}
                            {/* <div onClick = {()=>{
                    let active = this.state.active;

                    if(active<=7) {
                        this.setState({
                            active:active+1,
                        },()=>{
                            if(this.state.active === 8) {
                                console.log("inside checkform");
                                this.checkForm();
                            }
                        })
                    }
                }} className = "navigation">></div> */}
                        </div> : null
                }
            </div>
        );
    }
}

export default Demo;
