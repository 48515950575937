import React from 'react'

class index extends React.Component {

	state = {
		timer : 10
	}

	initTimer = () => {
		setTimeout(()=>{
			let timer = this.state.timer;
			if(timer===0){
				window.location = "//dice.tech"
			}else {
				this.setState({
					...this.state,
					timer:timer-1
				})
				this.initTimer()
			}
		},1000)
	}

	componentDidMount() {
		this.initTimer()
	}

	render() {
		return (
			<div className="clpF">
				<div className="dotted" style={{
					minHeight:'100vh',
					width:'100vw'
				}}>
					<div style={{
						width:'100%',
						padding:'22px 10px',
						display:'flex',
						alignItems:'center',
						background:'rgb(101, 27, 218)',
						justifyContent:'center',
						fontSize:13,
						letterSpacing:1,
						color:'white'
					}}>
						<svg className="bi bi-megaphone-fill" xmlns="http://www.w3.org/2000/svg" width="1em"
							 height="1em" fill="currentColor" viewBox="0 0 16 16"
							 style={
								 {
									 fontSize:'1.2rem',
									 marginRight:15
								 }
							 }>
							<path
								d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"></path>
						</svg>
						<span>Things are changing ! Your favourite <b>EKA</b> is now <b>Dice.tech</b></span>
						<div onClick={()=>{
							window.location = "//dice.tech"
						}} style={{
							borderRadius:18,
							border:'1px solid white',
							color:"white",
							padding:'6px 15px',
							marginLeft:18,
							fontSize:12,
							textTransform:'uppercase',
							cursor:'pointer'
						}}>
							View More
						</div>
					</div>
					<div style={{
						background:`linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3)) center center / cover no-repeat, url(${require('./back.jpeg')}) center center / cover no-repeat`,
						height:"calc(100vh - 14rem)",
						width:'calc(100% - 10rem)',
						margin:'5rem',
						overflow:'hidden',
						borderRadius:60
					}}>
						<div style={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							height:'100%',
							width:'100%',
							flexDirection:'column',
							textAlign:'center'
						}}>
							<h1 style={{
								fontSize:'4rem',
								fontWeight:800,
								color:'white',
								letterSpacing:1
							}}>
								Experience Revolution <br/> <font style={{fontSize:'3.75rem'}}>of corporate spending.</font>
							</h1>
							<p style={{
								margin:'60px 0',
								fontSize:'2rem',
								fontWeight:400,
								color:'white',
								letterSpacing:.5
							}}>
								Your favourite <b>EKA</b> is now <b>Dice.Tech</b>
							</p>
							<p style={{
								background:'white',
								borderRadius:25,
								fontWeight:600,
								padding:'15px 50px',
								fontSize:17,
								color:"#333",
								textAlign:'center'
							}}>
								Redirecting to <b>Dice.tech</b> in <b>{this.state.timer}</b>
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default index
