const allDetails = [
    [
        {
            type: 'title',
            text: 'What personal information do we collect from the people that visit our blog, website or app?'
        },
        {
            type: 'para',
            text: 'When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, location or other details to help you with your experience.'
        }
    ],
    [
        {
            type: 'title',
            text: 'When do we collect information?'
        },
        {
            type: 'lineitem',
            text: [
                "We collect information from you when you register on our site, place an order, subscribe to a newsletter, fill out a form or enter information on our site.",
                "Provide us with feedback on our products or services"
            ]
        }
    ],
    [
        {
            type: 'title',
            text: 'When do we collect information?'
        },
        {
            type: 'para',
            text: 'We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:'
        },
        {
            type: 'lineitem',
            text: [
                "To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested",
                "To improve our website in order to better serve you.",
                "To allow us to better service you in responding to your customer service requests.",
                "To administer a contest, promotion, survey or other site feature.",
                "To ask for ratings and reviews of services or products",
                "To follow up with them after correspondence (live chat, email or phone inquiries)"

            ]
        }

    ],
    [
        {
            type: 'title',
            text: 'How do we protect your information?'
        },
        {
            type: 'lineitem',
            text: [
                "We do not use vulnerability scanning and/or scanning to PCI standards.",
                "We only provide articles and information. We never ask for credit card numbers.",
                "We use regular Malware Scanning."
            ]
        },
        {
            type: 'para',
            text: "Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information. For your convenience we may store your credit card information longer than 30 days in order to expedite future orders, and to automate the billing process."
        },
    ],
    [
        {
            type: 'title',
            text: 'Do we use cookies?'
        },
        {
            type: 'para',
            text: "Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future."
        },
        {
            type: 'lineitem',
            text: [
                "Understand and save user's preferences for future visits.",
                "Keep track of advertisements.",
                "Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future.",
                "We may also use trusted third-party services that track this information on our behalf."
            ]
        },
        {
            type: 'para',
            text: "You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies. If you turn cookies off, some features will be disabled."
        },
        {
            type: 'para',
            text: "It won't affect the user's experience that make your site experience more efficient and may not function properly."
        }

    ],
    [
        {
            type: 'title',
            text: 'Third-party disclosure'
        },
        {
            type: 'para',
            text: "We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety."
        },
        {
            type: 'para',
            text: 'However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses'
        }
    ],
    [
        {
            type: 'title',
            text: 'Third-party links'
        },
        {
            type: 'para',
            text: 'We do not include or offer third-party products or services on our website.'
        }
    ],
    [
        {
            type: 'title',
            text: 'Contacting Us'
        },
        {
            type: 'para',
            text: 'If there are any questions regarding this privacy policy, you may contact us using the form here.'
        },
        {
            type: 'para',
            text: "You can be sure that safeguarding your privacy & your information is our top priority."
        }
    ]

]

export default allDetails;