import React from "react";
import { calander, tag } from "../images";
import Moment from "moment";
import ArticleItem from "./ArticleItem";
import "./style.css";
import { Helmet } from "react-helmet";
import {Header} from "../../../../../Common/Header";

class View extends React.Component {
  constructor(props) {
    super(props);
    this.presentor = props.presentor;
    this.presentor.setView(this);
  }

  state = {
    title: "",
    items: [],
    data: {},
    description: "",
    image: "",
    Categories: [],
    allblogs: [],
    createdAt: Moment(),
    tags: [],
    tagShow: false
  };

  componentDidMount() {
    this.presentor.getBlog();
    this.presentor.getCategories();
    this.presentor.getBlogs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.presentor.getBlog();
    }
  }

  getCategoriesSuccessfully(res) {
    let newResult = res.filter(cur => {
      if (
        cur.title === "Corporate Solutions" ||
        cur.title === "test" ||
        cur.title === "done"
      ) {
        return false;
      }
      return true;
    });
    this.setState({
      Categories: newResult
    });
  }

  successfullyGetValue(res = []) {
    this.setState({
      allblogs: res.filter(item => {
        return item.published;
      })
    });
  }

  filterCheck(each, filter) {
    switch (filter) {
      case "recentPost":
        let blog = each.title;
        blog = blog
          .trim()
          .split(" ")
          .join("-");
        this.props.history.push(`/blog/page/${each.blogId}/${blog}`);
        break;
      case "CATEGORIES":
        this.props.history.push(`/blog?categoryId=${each.title}`);
        break;
      default:
    }
  }

  getBlogSuccessFully(res) {
    let tagShow = false;
    res.tags.map(item => {
      if (item) {
        tagShow = true;
      }
      return null;
    });
    this.setState({
      ...res,
      items: res.items,
      tags: res.tags,
      tagShow
    });
  }

  getId() {
    return JSON.parse(this.props.match.params.id);
  }

  getTags(tagShow) {
    if (tagShow) {
      return (
        <>
          {this.state.tags.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <span style={{ fontWeight: 600 }}>{item}</span>,
              </React.Fragment>
            );
          })}
        </>
      );
    } else {
      return "No Tag";
    }
  }

  render() {
    //let CATEGORIES = this.state.Categories;
    return (
      <div id="product-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.title}</title>
          <meta name="description" content={this.state.description} />
        </Helmet>
        <Header />
        <div
          style={{ borderBottom: "2px dashed var(--primary-color)" }}
          className="ptb-70 ptb-40 dotted"
        >
          <div className="container-size">
            <div>
              <div>
                <h1 className="integration-title" style={{ fontSize: 25 }}>
                  {this.state.title}
                </h1>
                <p
                  className="integration-desc"
                  style={{ fontSize: 14, marginTop: 20 }}
                >
                  {this.state.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{ background: "var(--background-color)" }}
            className="ptb-70 ptb-40"
          >
            `{" "}
            <div className="container-size">
              <div className="flex-wrap">
                <div className="blogs_big_container">
                  <div className="blog_full_container1">
                    {this.state.image ? (
                      <img
                        alt="Blog"
                        src={this.state.image}
                        className="inner_blog_image"
                      />
                    ) : null}

                    <div className="blog_header" style={{ marginTop: 10 }}>
                      <h1 className="blog_title">{this.state.title}</h1>
                    </div>
                    <div className="middle_segment">
                      <div className="middle_segment">
                        <img src={calander} alt="" height="16" width="16" />
                        <h3 className="blog_date">
                          {Moment(this.state.createdAt).format("MMM DD YYYY")}
                        </h3>
                      </div>
                    </div>
                    <div className="blogTopContainer">
                      {this.state.items.map((item, index) => (
                        <ArticleItem item={item} index={index} key={index} />
                      ))}
                    </div>
                    <div
                      className="footer blogs_big_container"
                      style={{
                        backgroundColor: "white",
                        width: "80%",
                        marginLeft: "10%",
                        flexDirection: "column",
                        display: "flex"
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <hr className="borderTag" />
                      </div>
                      <div className="tagContainer">
                        {this.state.tagShow ? (
                          <div className="tagInnerContainer">
                            <img src={tag} className="rotateTag" alt="" />
                            {this.getTags(this.state.tagShow)}
                          </div>
                        ) : (
                          <div style={{ width: "50%" }}>
                            <img src={tag} className="tags" alt="" />
                            {this.getTags(this.state.tagShow)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*<div className="filterChange">
                    <Filter
                      data={{ recentPost: this.state.allblogs, CATEGORIES }}
                      {...this.props}
                      filterCheck={(each, title) =>
                        this.filterCheck(each, title)
                      }
                    />
                  </div>
                    */}
                </div>
              </div>
            </div>
            `
          </div>
        </div>
      </div>
    );
  }
}

export default View;
