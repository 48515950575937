import React from 'react'
import Invoices from "./Invoice";
//import Extended from './extended';

class index extends React.Component {

	render() {
		let { data, id } = this.props;
		if (data.type === "invoicing") {
			return (<Invoices id={id} {...this.props} />)
		} 
		// if (data.type === "extended-invoicing") {
		// 	return (<Extended id={id} {...this.props} />)
		// }
		return (
			<div>

			</div>
		)
	}

}

export default index
