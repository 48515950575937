import React from 'react'
import India from './India'
import Invoice from './Invoice';
import Usa from './Usa';

class index extends React.Component {

	render() {
		let {data} = this.props;
		if(data.renderer==="INDIA"){
			return (<India {...this.props}/>)
		} else if(data.renderer === 'USA') {
			return (<Usa {...this.props} />)
		}
		return (<Invoice {...this.props}/>)
	}

}

export default index
