import React from 'react';
import Lottie from 'react-lottie';
import { get } from '../../Model/Network/Config/Axios';


class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items: []
        }
    }


    componentDidMount() {
        this.getToken()
    }


    getToken = () => {
        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const token = params.get('token');
        if (!token)
            this.setState({
                ...this.state,
                loading: false,
                error: "Invalid url"
            })
        else {
            get('/po/fetch.vendor', (e, r) => {
                if (r) {
                    this.setState({
                        ...r,
                        loading: false
                    })
                } else {
                    this.setState({
                        error: e.response.data.error,
                        loading: false
                    })
                }
            }, { token })
        }
    }


    render() {

        if (this.state.loading) {
            return (
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: require('../Tasks/Survey/Fields/assets/loading'),
                    }}
                        height={250}
                        width={250}
                    />
                </div>
            )
        }

        let items = this.state.items;
        let base = 0;
        let gst = 0;
        items.forEach(i => {
            base = base + i.amount;
            let tax = i.gst / 100 * i.amount;
            gst = gst + tax;
        })
        let total = base + gst;
        return (
            <div id="product-page">
                <div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
                    <div className="container-size">
                        <div>
                            <h1 style={{ marginTop: 20 }} className="integration-title">Your  request accepted</h1>
                            <p className="integration-desc">{""}</p>
                        </div>
                    </div>
                </div>
                <div style={{ background: '#fff', minHeight: '90vh' }} className="ptb-70 ptb-40">

                    <div style={{
                        padding: 18,
                        paddingTop: 80,
                    }}>
                        <p style={{
                            color: "#454545",
                            fontSize: 17,
                            fontWeight: 600,
                            marginBottom: 12,
                            marginTop: 20
                        }}>
                            Line Items:
						</p>
                        <div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
                            <div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                Details
							</div>
                            <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                Max Qtty
							</div>
                            <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                Unit Price
							</div>
                            <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                Qtty
							</div>
                            <div style={{ flex: 1, fontSize: 13, padding: 12 }}>
                                Amount
							</div>
                        </div>
                        {
                            this.state.items.map(item => {
                                return (
                                    <div key={item.productId} style={{ display: 'flex', background: "#f6f6f6", border: '1px solid #dedede' }}>
                                        <div style={{ flex: 3, padding: 12, borderRight: '1px solid #dedede' }}>
                                            <p style={{
                                                fontSize: 15,
                                                color: "#454545"
                                            }}>
                                                {item.title}
                                            </p>
                                            <p style={{
                                                fontSize: 10,
                                                color: "#787878"
                                            }}>
                                                HSN : {item.hsn}
                                            </p>
                                        </div>
                                        <div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
                                            {item.max}
                                        </div>
                                        <div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
                                            <input type="number" onChange={e => {
                                                let value = e.target.value;
                                                value = parseFloat(value);
                                                let items = this.state.items.map(x => {
                                                    if (item.productId === x.productId) {
                                                        return {
                                                            ...x,
                                                            price: value,
                                                            amount: value * item.qtty
                                                        }
                                                    } else {
                                                        return x;
                                                    }
                                                })
                                                this.setState({
                                                    ...this.state,
                                                    items
                                                })
                                            }} value={item.price} style={{
                                                border: 'none',
                                                fontSize: 17,
                                                fontWeight: 600,
                                                color: "#454545",
                                                background: "#f6f6f6",
                                                width: 90
                                            }} />
                                        </div>
                                        <div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
                                            <input type="number" onChange={e => {
                                                let value = e.target.value;
                                                if (value > item.max)
                                                    value = item.max;
                                                value = parseInt(value, 10);
                                                let items = this.state.items.map(x => {
                                                    if (item.productId === x.productId) {
                                                        return {
                                                            ...x,
                                                            qtty: value,
                                                            amount: value * item.price
                                                        }
                                                    } else {
                                                        return x;
                                                    }
                                                })
                                                this.setState({
                                                    ...this.state,
                                                    items
                                                })
                                            }} value={item.qtty} style={{
                                                border: 'none',
                                                fontSize: 17,
                                                fontWeight: 600,
                                                color: "#454545",
                                                background: "#f6f6f6",
                                                width: 90
                                            }} />
                                        </div>
                                        <div style={{ flex: 1, padding: 12 }}>
                                            {item.amount}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div style={{ maxWidth: 300, marginLeft: 500 }}>
                            <p style={{
                                color: "#454545",
                                fontSize: 17,
                                fontWeight: 600,
                                marginTop: 33,
                                marginBottom: 12
                            }}>
                                Order Details:
							</p>
                            <div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
                                <div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    Base Amount
								</div>
                                <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    {base}
                                </div>
                            </div>
                            <div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
                                <div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    Tax Amount
								</div>
                                <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    {gst}
                                </div>
                            </div>
                            <div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
                                <div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    Total
								</div>
                                <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
                                    {total}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
