import React from "react";


class CustomFields extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			change: undefined
		}
	}
	camelize = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	renderKey = value => {
		value = value.replace(".", " ");
		return (
			<div key={value} style={{
				fontWeight: 500,
				padding: 12,
				flex: 1,
				border: '1px solid #dedede'
			}}>{this.camelize(value)}</div>
		)
	}

	renderValue = (item, value) => {
		if (value.includes('jpeg') || value.includes('png') || value.includes('pdf')) {
			return (
				<div style={{
					fontWeight: 500,
					padding: 12,
					flex: 1,
					border: '1px solid #dedede'
				}}>
					<a href={value} target="_blank" rel="noopener noreferrer">View Attachment</a>
				</div>
			)
		}
		return (
			<div key={value} style={{
				fontWeight: 500,
				padding: 12,
				flex: 1,
				border: '1px solid #dedede',
				display: 'flex',
				alignItems: 'center'
			}}>

				{value}
				<div onClick={(e) => {
					e.stopPropagation();
					this.setState({
						change: {
							key: item,
							value
						}
					})
				}} className="penciledit"
					style={{ marginLeft: '10px', cursor: 'pointer', position: 'relative', zIndex: 3, height: 12, width: 12 }} />
			</div>
		)
	}

	render() {
		let { tags } = this.props;

		return (
			<div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					fontWeight: 600,
					fontSize: 11,
					padding: 8,
					background: "#f2f4f8",
					border: '1px solid #dedede',
					color: 'var(--light-color)'
				}}>
					Insights
				</div>
				{
					Object.keys(tags).map((item, index) => {
						if (item === 'map') {
							item = JSON.parse(tags[item])
							return (
								<div key={index}>
									{
										Object.keys(item).map((i, index1) => {
											return (
												<div className="border-box" key={index1} style={{
													display: 'flex'
												}}>
													<p style={{
														fontWeight: 600,
													}}>{i} </p>

													<p style={{
														fontWeight: 500,
													}}>{item[i]}</p>
												</div>
											)
										})
									}

								</div>
							)
						}
						return (
							<div className="border-box" key={index} style={{
								display: 'flex'
							}}>
								{this.renderKey(item)}
								{this.renderValue(item, tags[item])}
							</div>
						)
					})
				}

			
			</div>
		)
	}
}


export default CustomFields;
