import React from 'react';
import Arrow from './arrow.png';
import './index.css';
import tick from './tick.png';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';


class TypeForm extends React.Component {


    state = {
        text:'',
        active:false
    }

    submitNext = () => {


        let text = this.state.text;

        if(text.length > 0) {
            this.props.onText(text);
            this.setState({
                active:true
            })
        } else {
            this.setState({
                active:false
            })
        }
        
    }

    handleEnter = (e) => {

    
        if(e.key === "Enter") {
            if(this.state.text.length === 0) {
               alert("Field are required");
                return;
            }

            this.props.nextQuestion();
        }
    }

    mapItem = (item,index) => {
        let active = this.props.ans === item ? true :false;
        return(
            <div style = {{
                ...active? {
                    background:'rgba(120, 220, 255, 0.4)'
                }: null
            }} onClick = {()=>{
                this.props.onText(item)
                this.props.nextQuestion();
            }} key = {index} className = "typeform-option">
                {item}
            </div>
        )
    }

    render() {
        let number = this.props.number;
        if(this.props.qtype === "option") {
            return(
                <div style = {{color:'#FFF'}} id = "typeform">
                <div className = "flex-v-center">
                    <p style = {{
                        color:'#78DCFF',
                        fontSize:18,
                        width:10
                    }}>{number}</p>
                    <img src = {Arrow} alt = ""  style = {{
                        height:15,
                        width:15,
                        objectFit:'contain',
                        marginLeft:2,
                        marginRight:10
                    }} />
                    <p style = {{color:'#fff',fontSize:22,fontWeight:500,letterSpacing:1.5}}>{this.props.quest}</p>
                </div>
                <div className = "typeform-input-layout">
                    {this.props.companySize.map(this.mapItem)}
                </div>
                <div className = {`input-enter-hide ${this.state.active ? "input-enter" : null}`}>
                    <div className = "flex-v-center">
                        <div onClick ={this.props.nextQuestion} className = "flex-v-center input-enter-button">
                            <p style = {{color:'#000',fontWeight:600}}>Ok</p>
                            <img src = {tick} alt = "" style = {{
                                height:15,
                                width:15,
                                objectFit:'contain',
                                marginLeft:8
                            }} />
                        </div>
                        <div style = {{marginLeft:8,color:'#fff'}} className = "flex-v-center">   
                            <span  style = {{fontSize:14,marginRight:5}}>press</span> ENTER
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        return (
            <div style = {{color:'#FFF'}} id = "typeform">
                <div className = "flex-v-center">
                    <p style = {{
                        color:'#78DCFF',
                        fontSize:18,
                        width:10
                    }}>{number}</p>
                    <img src = {Arrow} alt = ""  style = {{
                        height:15,
                        width:15,
                        objectFit:'contain',
                        marginLeft:2,
                        marginRight:10
                    }} />
                    <p style = {{color:'#fff',fontSize:22,fontWeight:500,letterSpacing:1.5}}>{this.props.quest}</p>
                </div>
                <div className = "typeform-input-layout">
                    <input type = {this.props.type} onKeyPress = {(e)=>this.handleEnter(e)}  onChange = {e=>this.setState({
                        text:e.target.value
                    },()=>this.submitNext())} placeholder = "Type your answer here" />
                </div>
                <div className = {`input-enter-hide ${this.state.active ? "input-enter" : null}`}>
                    <div className = "flex-v-center">
                        <div onClick ={this.props.nextQuestion} className = "flex-v-center input-enter-button">
                            <p style = {{color:'#fff',fontWeight:600}}>Ok</p>
                            <img src = {tick} alt = "" style = {{
                                height:15,
                                width:15,
                                objectFit:'contain',
                                marginLeft:8
                            }} />
                        </div>
                        <div style = {{marginLeft:8,color:'#fff'}} className = "flex-v-center">   
                            <span  style = {{fontSize:14,marginRight:5}}>press</span> ENTER
                        </div>
                    </div>
                    <ToastContainer />

                </div>
            </div>
        );
    }

}

export { TypeForm}
