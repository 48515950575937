const footerData = [
	[{ key: 'title', value: 'Products' },
	{ key: 'nontitle', value: 'Product', path: '/product' },
	{ key: 'nontitle', value: 'Integration', path: '/integration' },
	{ key: 'nontitle', value: 'Schedule Demo', path: '/demo' }
	],
	[
		{ key: 'title', value: 'About us' },
		{ key: 'nontitle', value: 'Team', path: '/team' },
		{ key: 'nontitle', value: 'Contact us', path: '/contact' },


	],
	[
		{ key: 'title', value: 'Comparison' },
		{ key: 'nontitle', value: 'With Concur', path: '/comparison' }
	],
	[
		{ key: 'title', value: 'Others' },
		{ key: 'nontitle', value: 'Privacy Policy', path: '/privacy' },

	]
]

const socialData = [
	{ cname: 'linkedin', url: 'https://www.linkedin.com/company/antrepriz/' },
	{ cname: 'twitter', url: 'https://twitter.com/antrepriz' },
	{ cname: 'facebook', url: 'https://www.facebook.com/antrepriz/' }

]

export { footerData, socialData };
