class Presentor {

	constructor(Model) {
		this.Model = Model;
	}

	setView(View) {
		this.View = View;
	}

	getBlog(){
		this.Model.getBlog(this.View.getId(),(err,res)=>{
			if(res){
				this.View.getBlogSuccessFully(res)
			}
		})
	}
	getCategories =()=>{
		this.Model.getCategories((err,res)=>{
			if(res){
				this.View.getCategoriesSuccessfully(res.blogCategories)
			}
		})
	}

	getBlogs = ()=>{
		this.Model.getBlogs((err,res)=>{
			if(res){
				this.View.successfullyGetValue(res.blogs)
			}
		})

	}
	

}


export default Presentor
