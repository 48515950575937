import React from 'react';
import close from './close.png';


class Index extends React.Component {

    render() {
        let item = this.props.item;
        return(
            <div style= {{
                position:'fixed',
                inset:0,
                backgroundColor:'rgba(0,0,0,0.2)',
                height:'100vh',
                zIndex:10000,
            }}>
                <div style ={{
                    left:550,
                    backgroundColor:'#fff',
                    right:0,
                    top:0,
                    bottom:0,
                    position:'fixed',
                    height:'100vh'
                }}>

                    <div style ={{
                        height:55,
                        borderBottom:'1px solid #dedede',
                        display:'flex',
                        alignItems:'center',
                        padding:"0px 20px"
                    }}>
                        <img src={close} onClick={this.props.close} alt="" style ={{height:15,width:15,cursor:'pointer'}} />

                        <div style={{marginLeft:20}}>
                            <p style={{fontSize:20,fontWeight:600}}>Submission</p>
                        </div>
                    </div>

                    <div style ={{
                        marginTop:20,
                        padding:25
                    }}>
                            <p style={{
									color: "#454545",
									fontSize: 17,
									fontWeight: 600,
									marginBottom: 12,
									marginTop: 20
								}}>
									Line Items:
								</p>
								<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
									<div style={{ flex: 3, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Qtty
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Price
									</div>
									
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Tax Percentage
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Required
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Tax
									</div>
									<div style={{ flex: 1, fontSize: 13, padding: 12 }}>
										base
									</div>
                                    <div style={{ flex: 1, fontSize: 13, padding: 12, borderRight: '1px solid #dedede' }}>
										Total
									</div>
								</div>
								{
									item.items.map(item => {
										return (
											<div key={item.productId} style={{
												display: 'flex',
												background: "#f6f6f6",
												border: '1px solid #dedede'
											}}>
												<div style={{ flex: 3, padding: 12, borderRight: '1px solid #dedede' }}>
													<p style={{
														fontSize: 15,
														color: "#454545"
													}}>
														{item.qtty}
													</p>
													
												</div>
												<div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
													{item.price}
												</div>
												<div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
													{item.taxPct}
												</div>
												<div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
													{item.required}
												</div>
												<div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
													{item.tax}
												</div>
												<div style={{ flex: 1, padding: 12, borderRight: '1px solid #dedede' }}>
													{item.base}
												</div>
												<div style={{ flex: 1, padding: 12 }}>
													{item.total}
												</div>
											</div>
										)
									})
								}
                    </div>


								<div style={{ maxWidth: 400, marginLeft: "calc(100% - 400px)" }}>
									<p style={{
										color: "#454545",
										fontSize: 17,
										fontWeight: 600,
										marginTop: 33,
										marginBottom: 12
									}}>
										Total Charges:
									</p>
									<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Base Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{item.base}
										</div>
									</div>
									<div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Tax Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{item.tax}
										</div>
									</div>

                                    <div style={{ display: 'flex', background: "#efefef", border: '1px solid #dedede' }}>
										<div style={{
											flex: 3,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											Total Amount
										</div>
										<div style={{
											flex: 1,
											fontSize: 13,
											padding: 12,
											borderRight: '1px solid #dedede'
										}}>
											{item.total}
										</div>
									</div>

								</div>

                </div>
            </div>
        )
    }
}

export default Index;