import React from 'react';
import './index.css';
import integrationList from './data';
import {Footer} from '../Common/Footer';
import {Header} from "../Common/Header/index";
import {Helmet} from 'react-helmet';
import {Images} from '../Images/Image';


class Integration extends React.Component {

	state = {
		list: integrationList,
		search: ''
	}


    mapItem = (item,index) => {
        return(
            <div key = {index} className = "col-lg-3">
                <div className = "single-integration">
                    <div>
                        <img src = {item.imgSrc} alt = {`Antrepriz with ${item.name}`} style = {{height:70,width:70,objectFit:'contain'}} />
                    </div>
                    <h2>{item.name}</h2>
                    <p className = "font-600">{item.desc}</p>
                </div>
            </div>
        )
    }


	handleSearch = () => {
		let name = this.state.search;
		let result = this.state.list.filter(val => val.name.toLowerCase().includes(name.toLowerCase()));
		if (name.length > 0) {
			this.setState({
				list: result
			})
		} else {
			this.setState({
				list: integrationList
			})
		}
	}

	render() {
		return (
			<div id="integration-page">
				<Helmet>
                    <meta charSet = "utf-8"/>
                    <title>Business Expense Management Solutions - Antrepriz</title>
					<link rel="canonical" href="https://www.antrepriz.com/integration"/>
					<meta name="keywords" content="travel management software,travel expense software,travel expense management,travel and expense management software,travel and expense management,travel and expense software,travel expense management software,travel & expense management,travel and expense management system,travel and expense management solution,travel & expense software,travel and expense management solutions,travel expense report software,travel expense management system,travel expense management solutions,travel expenses software,travel expense card,travel management system,employee travel management software,travel expense management solution,travel management solution" />
                    <meta name = "description" content="Intelligent business travel and expense management solutions and software, employee and expense tracking, dynamic and powerful policy engine, fully cutomizable expense reports, employee self flight, Hotel, Bus and cab booking tool, Admin travel booking tool, end to end work flow management, faster approvals, cheaper flights, quicker bookings, 100% policy compliance business travel, on-demand travel insurance, international travel insurance, 24 hour reimbursement cycle, employee flexi benefits, automated payments, direct account transfer, advance cash settlement, account payable automation, vendor payments. Best business travel, expense and payment solution world. Ensure employee experience with automation, visibility, control and cost saving. Save upto 40% on corporate travel." />

            	</Helmet>
                <Header/>
				<div style = {{borderBottom:'2px dashed var(--primary-color)'}} className="ptb-70 ptb-40 dotted">
					<div className="container-size">
						<div>
							<div>
								<h1 className="integration-title">Integration</h1>
								<p className="integration-desc">Import your data from anywhere</p>
								<div className="integration-input flex-v-center">
									<img src={Images.search} alt="" style={{
										height: 25,
										width: 25,
										marginLeft: 10
									}}/>
									<input onChange={(e) => {
										this.setState({
											search: e.target.value
										}, () => this.handleSearch())
									}} value={this.state.search} placeholder="Search for Integration" type="text"
										   style={{
											   height: '80%',
											   width: '90%',
											   marginLeft: 'auto',
											   fontSize: 16,
											   border: 'none',
											   fontWeight: 500
										   }}/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{background: 'var(--background-color)'}} className="ptb-70 ptb-40">
					<div className="container-size">
						<div className="flex-wrap">
							{this.state.list.map(this.mapItem)}
						</div>
					</div>
				</div>
				<Footer/>
			</div>
		);
	}
}


export default Integration;
