


const points = [
    {   cname:'product-first',
        cname2:'product-first-image',
        name:'Mileage Tracker',
        backColor:'#7142f8',
        desc:'Mileage tracker that will calculate your total distance travelled with 95%+ accuracy in all mobile devices. Your fuel claim will be automatically submitted.'
    },
    {
        cname:'product-second',
        cname2:'product-second-image',
        name:'Manager View',
        backColor:'#5cbd2c',
        desc:'We are disrupting travel insurance by giving one of its kind unique on demand GEO location or booking based insurance that is automatically applied when you travel'
    },
    {
        cname:'product-third',
        cname2:'product-third-image',
        name:'Smart Receipts',
        backColor:'#f38650',
        desc:'6 second expense with EKA. We are fastest and most accurate receipt scanner systems out there. With us all it takes is 6 seconds to file an expense.'
    },
    {

        cname:'product-four',
        cname2:'product-four-image',
        name:'Workflow',
        backColor:'#ebcf24',
        desc:'Our fully customizable workflow management system lets you create your own workflow and elimiate redundant mail and wasteful time for approvals and planning'
    },
    {
        cname:'product-five',
        cname2:'product-five-image',
        name:'Budgeting',
        backColor:'#fa4c9a',
        desc:'EKA admin can create budgeting centers for your travel and vendor payments. Every budgeting center can associate themselves with separate offices travel policies'
    },
    {
        cname:'product-six',
        cname2:'product-six-image',
        name:'Direct A/C Transfers',
        backColor:'#35bfe7',
        desc:'We have tied up with banks and using bank APIs we provide option to directly transfer reimbursements to employee account. No more waiting or paying physically in cash'
    },
    {
        cname:'product-seven',
        cname2:'product-seven-image',
        name:'Cash Advances',
        backColor:'#F08066',
        desc:'Advance payments have never been so easy. On one click, employees can generate request for petty cash & companies can transfer money to employee account'
    },
    {
        cname:'product-eight',
        cname2:'product-eight-image',
        name:'Trips',
        backColor:'#8D4FC8',
        desc:'Experience business trips like never before. EKA trips are fully automated with planning, booking and alerts system in place to keep you relaxed on trip.'
    },
    {
        cname:'product-nine',
        cname2:'product-nine-image',
        name:'Official Spends',
        backColor:'#2EACC0',
        desc:'It has always been difficult for employees to claim for official spends when policies are not well defined. Our panel offers exclusive payment options for official spends'
    },


]

export default points;