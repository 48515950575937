import React from 'react';
import { Input } from './Input';
import { Select } from './Select';
import { Calendar } from './Calendar';
import { File as FileUpload } from './FileUpload';
import { showToast } from '../../../Website/Toast/ToastCallback';
import { get } from '../../../../Model/Network/Config/Axios';


export default class SurveyForm extends React.Component {

    state = {
        forms: []
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    getForm = (cb) => {
        let data = {};
        let questionList = []
        for (let i = 0; i < this.state.forms.length; i++) {
            let item = this.state.forms[i];
            let value = this[`input_${item.id}`].getValue();
            if (value) {
                data[`${item.field}`] = {
                    id: item.id,
                    field: item.field,
                    value,
                    name: item.name
                };
                questionList.push({ name: item.name, value })
            } else if (item.required) {
                showToast({
                    type: 'error',
                    message: `${item.name} is marked required`
                })
                return;
            }
        }
        cb(data, questionList);
    }

    fetchAdditionalForm = (token, id) => {
        get(`survey.form`, (e, r) => {
            if (r) {
                this.setState({
                    ...r
                })
            }
        }, {
            token,
            id
        })
    }


    mapItem = (item, index) => {

        if (item.type === 'SELECT') {
            return (
                <Select
                    key={index}
                    label={item.name}
                    items={item.values}
                    handleRef={ref => this[`input_${item.id}`] = ref}
                />
            )
        } else if (item.type === 'DATE') {
            return (
                <Calendar
                    key={index}
                    label={item.name}
                    title={item.name}
                    handleRef={ref => this[`input_${item.id}`] = ref}
                />
            )
        }

        else if (item.type === 'FILE') {
            return (
                <FileUpload
                    key={index}
                    title={item.name}
                    placeholder={item.placeholder}
                    handleRef={ref => this[`input_${item.id}`] = ref}
                />
            )
        }

        else if (item.type === 'EMAIL' || item.type === 'NUMBER' || item.type === 'TEXT') {
            return (
                <Input
                    key={index}
                    type={item.type.toLowerCase()}
                    label={item.name}
                    handleRef={ref => this[`input_${item.id}`] = ref}
                    placeholder={item.placeholder}
                />
            )
        }

    }

    render() {

        if (this.state.forms.length === 0) {
            return <React.Fragment />
        }

        return (
            <div>
                <div style={{ marginTop: 20 }}>
                    <h2 style={{
                        fontSize: 25, color: "#454545", fontWeight: 700
                    }}>{this.props.title}</h2>
                    <p style={{
                        marginBottom: 20, fontSize: 13, color: "#898989", marginTop: 4
                    }}>{this.props.des}</p>
                    {this.state.forms.map(this.mapItem)}
                </div>
            </div>
        )
    }
}
