import React from 'react';
import {Header} from '../../Common/Header';
import { Footer } from '../../Common/Footer';
import Alldetails from './data';
import Title from '../Common/Title';
import Para from '../Common/Para';
import LineItems from '../Common/LineItems';
import './index.css';


class Index extends React.Component {




    mapLineItem = (item, index) => {

        if (item.type === 'title') {
            return (
                <Title text={item.text} key={index} />
            )
        } else if (item.type === 'para') {
            return (
                <Para text={item.text} key={index} />
            )
        } else if (item.type === 'lineitem') {
            return (
                <LineItems text={item.text} key={index} />
            )
        }

    }


    mapItem = (item, index) => {
        return (
            <div key={index} style={{
                marginBottom: 40
            }}>
                {item.map(this.mapLineItem)}
            </div>
        )
    }

    render() {
        return (
            <div id="privacy-policy">
                <Header />
                <div >
                    <div style={{ borderBottom: '2px dashed var(--primary-color)' }} className="ptb-70 ptb-40 dotted">
                        <div className="container-size">
                            <div style={{
                                textAlign: 'center',
                                height: 200,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>
                                <h1 className="privacy-title">Privacy Policy</h1>
                                <p className="privacy-desc">Eka</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ptb-70 ptb-40">
                    <div className="container-size">
                        <p className="privacy-common-para" style={{
                            marginBottom: 30
                        }}>
                            This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                        </p>
                        {Alldetails.map(this.mapItem)}
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}


export default Index;
