import React from 'react';
import './index.css';


class Title extends React.Component {


    render() {
        return (
            <div className="privacy-common-title" >
                {this.props.text}
            </div>
        )
    }
}

export default Title;