import React from 'react'
import Close from './cancel.png'

class Toast extends React.Component {

	componentDidMount() {
		this.mMounted = true;
		setTimeout(() => {
			if (this.mMounted) {
				let data = this.props.data;
				this.props.close(data.id);
			}
		}, 2500)
	}

	componentWillUnmount() {
		this.mMounted = false;
	}

	render() {
		let data = this.props.data;
		if (data.type === "success")
			return (
				<div style={{
					width:600,
					background:'white',
					margin:'0 auto',
					marginBottom:12,
					boxShadow:'11px 11px 7px rgba(1,1,1,.015)',
					border:'1px solid #dedede',
					display:'flex',
					alignItems:'center',
					justifyContent:'center',
					flexDirection:'row'
				}}>
					<img alt="src" src={require('./success.png')} style={{
						width:40,
						zIndex:11111,
						height:40,
						padding:12
					}}/>
					<p style={{
						flex:1,
						fontSize:15,
						padding:12,
						paddingLeft:0
					}}>{data.message}</p>
					<div style={{
						width:50,
						borderLeft:'1px solid #dedede'
					}}>
						<img style={{
							width:50,
							height:50,
							zIndex:11111,
							cursor:'pointer',
							padding:15
						}} onClick={() => {
							this.props.close(data.id)
						}} src={Close} alt="close"/>
					</div>
				</div>
			)
		else
			return (
				<div style={{
					width:600,
					background:'white',
					margin:'0 auto',
					marginBottom:12,
					boxShadow:'11px 11px 7px rgba(1,1,1,.015)',
					border:'1px solid #dedede',
					display:'flex',
					alignItems:'center',
					justifyContent:'center',
					flexDirection:'row'
				}}>
					<img alt="src" src={require('./error.png')} style={{
						width:40,
						zIndex:11111,
						height:40,
						padding:12
					}}/>
					<p style={{
						flex:1,
						fontSize:15,
						padding:12,
						paddingLeft:0
					}}>{data.message}</p>
					<div style={{
						width:50,
						borderLeft:'1px solid #dedede'
					}}>
						<img style={{
							width:50,
							height:50,
							zIndex:11111,
							cursor:'pointer',
							padding:15
						}} onClick={() => {
							this.props.close(data.id)
						}} src={Close} alt="close"/>
					</div>
				</div>
			)
	}

}

export default Toast
