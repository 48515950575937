import React from 'react'
import LandingPage from './LandingPage'
import {Switch} from 'react-router'

class index extends React.Component {

	render() {
		return (
			<Switch>
				<LandingPage/>
			</Switch>
		)
	}

}

export default index
