import { Images } from "../Images/Image";
import BlackBuck from './blackbuck.png';
import shuttl from './shuttl.png';
import licious from './Licious.png';
import bizongo from './bizongo.png';

const companies = [
    {
        imageUrl:Images.dtdc,
        alt:'Antrepriz with DTDC'
    },
    {
        imageUrl:BlackBuck,
        alt:'Antrepriz with Blackbuck'

    },
    {
        imageUrl:shuttl,
        alt:'Antrepriz with shuttl'

    },
    // {
    //     imageUrl:Images.ofcspc,
    //     alt:'ofcpsc'

    // },
    {
        imageUrl:licious,
        alt:'Antrepriz with Licious'
    },
    {
        imageUrl:bizongo,
        alt:'Antrepriz with Bizongo',
        heightRestriction:true

    },
    // {
    //     imageUrl:Images.oneculture,
    //     alt:'oneculture',
    //     heightRestriction:true

    // },
    {
        imageUrl:Images.awign,
        alt:'one company',
        heightRestriction:true

    },
]

export default companies;